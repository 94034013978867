<template>
  <v-row justify="center">
    <v-col cols="auto">
      <v-btn
        class="font-weight-bold text-body-1"
        tile
        color="#92B126"
        large
        @click="$emit('checkedItemEditButtonClicked')"
        elevation="4"
      >
        <span class="white--text">
          <v-icon left>mdi-file-edit</v-icon>チェックした商品を一括設定
        </span>
      </v-btn>
    </v-col>
    <v-col cols="auto">
      <v-btn
        class="font-weight-bold text-body-1"
        tile
        color="#92B126"
        large
        elevation="4"
        :disabled="is_full_auto_on"
        @click="$emit('allItemEditButtonClicked')"
      >
        <span class="white--text">
          <v-icon left>mdi-file-edit</v-icon>全商品を一括設定
        </span>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "EditButton",
  props: ["is_full_auto_on"],
};
</script>

<style></style>
