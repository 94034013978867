<template>
  <v-container v-if="user">
    <v-row justify="center">
      <v-col cols="auto">
        <div class="mx-auto" style="max-width: 667px">
          <span>▼スライド画像による設定方法の説明を記載しています。</span>
          <v-carousel
            id="rakuten_api_access_permit_carousel"
            :show-arrows="true"
            v-model="procedure_carousel_num"
            show-arrows-on-hover
            hide-delimiters
            width="667"
            height="420"
            style="max-width: 667px"
            class="mx-auto"
            @change="$emit('carousel_changed', $event)"
          >
            <v-carousel-item
              v-for="(item, i) in procedure_images"
              :key="i"
              :src="item.src"
            ></v-carousel-item>
          </v-carousel>
        </div>
      </v-col>
    </v-row>

    <v-form ref="form" v-model="valid_license_key_secret" class="mt-5">
      <v-row justify="center" align-content="center" class="px-5">
        <v-col cols="6" v-if="get_secret">
          <v-text-field
            v-model="rakuten_service_secret"
            label="serviceSecret(サービスシークレット)"
            outlined
            :rules="[rules.service_secret]"
            class="mb-3"
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <v-text-field
            v-model="rakuten_license_key"
            label="licenseKey(ライセンスキー)"
            outlined
            block
            :rules="[rules.license_key]"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-form>

    <v-row align="end" class="mb-6" v-if="allow_expired_at_date_null">
      <v-col cols="6" class="ml-auto">
        <v-switch
          :label="
            is_display_license_key_on_rms
              ? '購入商品情報を利用できる'
              : '購入商品情報を利用できない'
          "
          hide-details
          v-model="is_display_license_key_on_rms"
          style="margin-left: auto; margin-top: 0; margin-right: 10px"
          inset
        ></v-switch>
        <div class="mt-2">
          <a
            class="text-caption text-decoration-underline"
            @click="order_description_modal = !order_description_modal"
            >購入商品情報を外部提供できないショップさまへ</a
          >
        </div>
        <ecup-modal
          v-if="order_description_modal"
          @close_this_modal="order_description_modal = false"
          title="購入商品情報を外部提供できないショップさまへ"
        >
          <v-card flat>
            <v-card-text>
              <p>
                EC-UPでは、原則全てのショップさまに購入商品APIのご提供をお願いしております。
              </p>

              <p>
                しかしながら、情報セキュリティや社内規定等の理由で売上を含む購入商品情報を外部提供できない場合、
                スイッチをOFFにしていただく事で、購入商品APIを解除されていてもEC-UPをご利用いただけます。<br />
                その場合、「同時購入システム」は購入商品情報が必要となりますので、ご提供できませんが、他システムは通常通りご利用可能です。
              </p>

              <p>
                なお、後日「同時購入システム」を利用する場合には、購入商品APIの利用設定をし、スイッチをONにしていただく事で利用可能となります。
              </p>
            </v-card-text>
          </v-card>
        </ecup-modal>
      </v-col>
    </v-row>

    <v-row v-if="license_key_invalid_error">
      <v-col cols="12" v-if="access_failed_endpoints">
        <v-alert type="error">
          <p>以下の項目が不足していませんか？ご確認ください！</p>
          <ul>
            <li v-for="endpoint in access_failed_endpoints" :key="endpoint">
              {{ endpoint }}
            </li>
          </ul>
        </v-alert>
      </v-col>
      <v-col cols="12">
        <v-alert text type="error">
          APIの一部項目が不足している、<br />もしくはライセンスキーが有効ではない可能性がございます。<br />
          ※ライセンスキーの反映には10分～30分かかる場合があります。<br />
          時間経過後でもこのエラーが表示される場合には、<br />
          <span class="font-weight-bold text-decoration-underline">
            説明スライドを再度ご確認いただき、手順をやり直してください。
          </span>

          <div class="mt-3">それでもエラーが出る場合は、</div>
          <ul>
            <li>「CSV商品一括編集機能」にお申し込み済みであること</li>
            <li>「WEB API」をご利用済みであること</li>
          </ul>
          をご確認の上、
          <a
            :href="config('HREF_FEEDBACK')"
            target="_blank"
            class="text-decoration-underline"
            >こちら</a
          >
          からお問い合わせください。
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-else-if="license_key_submit_error_str">
      <v-col cols="12">
        <v-alert style="white-space: normal" type="error">{{
          license_key_submit_error_str
        }}</v-alert>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="auto">
        <v-btn
          color="primary"
          large
          @click="rakuten_api_license_key_submit"
          depressed
          vertical-align="center"
          :loading="loading"
          :disabled="loading || !valid_license_key_secret"
          align-center
          >送信</v-btn
        >
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="auto" v-if="loading">
        <div class="mx-auto">
          30秒程度かかります。リロードせずにお待ちください。
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import timeUtilsMixin from "../../packs/mixins/time_utils";
import EcupModal from "./EcupModal.vue";
import config from "../../const";

export default {
  name: "SendRmsSecret",
  props: {
    get_secret: {
      type: Boolean,
    },
    allow_expired_at_date_null: {
      type: Boolean,
    },
    selectShop: {
      type: Object,
    },
    procedure_images: {
      type: Array,
      default: () => [
        {
          src: "/images/components/send_rms_secret/01.gif",
        },
        {
          src: "/images/components/send_rms_secret/02.png",
        },
        {
          src: "/images/components/send_rms_secret/03.png",
        },
        {
          src: "/images/components/send_rms_secret/04.png",
        },
        {
          src: "/images/components/send_rms_secret/05.png",
        },
        {
          src: "/images/components/send_rms_secret/06.png",
        },
        {
          src: "/images/components/send_rms_secret/07.png",
        },
        {
          src: "/images/components/send_rms_secret/08.gif",
        },
        {
          src: "/images/components/send_rms_secret/09.png",
        },
        {
          src: "/images/components/send_rms_secret/10.gif",
        },
        {
          src: "/images/components/send_rms_secret/11.png",
        },
        {
          src: "/images/components/send_rms_secret/12.png",
        },
        {
          src: "/images/components/send_rms_secret/13.png",
        },
        {
          src: "/images/components/send_rms_secret/14.png",
        },
      ],
    },
  },

  mixins: [timeUtilsMixin],
  components: { EcupModal },
  created() {
    this.is_display_license_key_on_rms = this.user.is_able_to_fetch_order;
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    filtered_rakuten_license_key() {
      if (this.rakuten_license_key) {
        return this.rakuten_license_key.replace(/\s/g, "");
      }
    },
    filtered_rakuten_service_secret() {
      if (this.rakuten_service_secret) {
        return this.rakuten_service_secret.replace(/\s/g, "");
      }
    },
  },
  data() {
    return {
      procedure_carousel_dialog: false,
      rakuten_service_secret: null,
      rakuten_license_key: null,
      license_key_invalid_error: false,
      access_failed_endpoints: [],
      license_key_submit_error_str: null,
      is_display_license_key_on_rms: true,
      valid_license_key_secret: false,
      procedure_carousel_num: 0,
      loader: null,
      loading: false,
      order_description_modal: false,
      rules: {
        license_key: (value) => {
          let shop_id = null;
          if (this.selectShop !== undefined) {
            shop_id = this.selectShop.shop_id;
          } else {
            shop_id = this.user.shop_id;
          }

          const pattern = new RegExp("^SL" + shop_id + "_", "g");
          return (
            pattern.test(value) ||
            "ライセンスキーはSL + 店舗IDで始まる文字列です。"
          );
        },
        service_secret: (value) => {
          let shop_id = null;
          if (this.selectShop !== undefined) {
            shop_id = this.selectShop.shop_id;
          } else {
            shop_id = this.user.shop_id;
          }

          const pattern = new RegExp("^SP" + shop_id + "_", "g");
          return (
            pattern.test(value) ||
            "サービスシークレットはSP + 店舗IDで始まる文字列です。"
          );
        },
      },
    };
  },
  methods: {
    async rakuten_api_license_key_submit() {
      if (
        (this.get_secret && this.filtered_rakuten_service_secret === "") ||
        this.filtered_rakuten_license_key === ""
      ) {
        this.license_key_submit_error_str = "不正な値です";
        return;
      }
      this.loading = true;

      let rms_webapi_params = {
        license_key: this.filtered_rakuten_license_key,
        get_order: this.is_display_license_key_on_rms,
      };

      if (this.get_secret) {
        rms_webapi_params.service_secret = this.filtered_rakuten_service_secret;
      }

      let post_url = "";
      if (this.selectShop !== undefined) {
        post_url = `/api/v1/shops/admin_shop_license_key`;
        rms_webapi_params.shop_code = this.selectShop.shop_code;
      } else {
        post_url = `/api/v1/shops/my_license_key`;
      }
      let license_key_response = await axios
        .post(post_url, rms_webapi_params)
        .catch((e) => {
          let response = e.response;

          if (response.status == 406) {
            let data = response.data;

            if (data.error_endpoints) {
              this.license_key_invalid_error = true;
              this.access_failed_endpoints = data.error_endpoints;
              this.license_key_submit_error_str = null;
              return;
            } else if (data.error_message) {
              this.license_key_invalid_error = false;
              this.access_failed_endpoints = null;
              this.license_key_submit_error_str = data.error_message;
              return;
            }
          } else if (response.status == 504) {
            this.license_key_invalid_error = false;
            this.license_key_submit_error_str =
              "ライセンスキーのチェックに失敗しました。時間を開けて再度お試しください。それでも同様のエラーメッセージが出る場合は、右上メニューよりお問い合わせください。";
            return;
          }
          this.loading = false;
        });

      if (license_key_response) {
        alert("ライセンスキーの送信が成功しました！");
        this.$emit("lisence_key_update_succeed");
      }
      this.loading = false;
    },

    config(value) {
      return config[value];
    },
  },
};
</script>
