<template>
  <v-container fluid class="pa-6" v-if="user">
    <v-row>
      <v-col class="display-1">
        <v-icon left large color="success"> mdi-palette-outline </v-icon>
        表示・デザイン
      </v-col>
    </v-row>
    <v-row>
      <v-col class="body-2 pt-0">
        表示する商品やデザインの設定ができます。変更後の設定は次回更新時から反映されます。
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <HideProduct
          :config="config"
          :loading="loading"
          @updateConfig="updateConfig()"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card class="pa-5">
          <v-row justify="space-between" align="center">
            <v-col cols="auto">
              <v-card-title class="black--text headline">
                <span>商品名設定</span>
              </v-card-title>
            </v-col>
            <v-col cols="auto" class="text-right">
              <v-btn
                color="primary"
                @click="updateConfig()"
                :loading="loading"
                :disabled="loading"
              >
                保存する
              </v-btn>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-card-subtitle class="black--text title">
            商品名表示設定
          </v-card-subtitle>

          <v-card-text class="black--text">
            システムに商品名を表示するかどうかを選択します。<br />
            システムにより、説明文内に他ブランドの商品が表示されることがあります。
            <br />
            それにより検索結果が意図しないものになってしまう場合、商品名を非表示にすることで回避できます。
            <br />
            特に問題ない場合は表示することを推奨します。

            <v-radio-group row v-model="config.item_name.is_display">
              <v-radio :value="true" :disabled="loading">
                <template v-slot:label>
                  <div class="black--text">表示する（推奨）</div>
                </template>
              </v-radio>
              <v-radio :value="false" :disabled="loading">
                <template v-slot:label>
                  <div class="black--text">表示しない</div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-card-text>

          <div v-if="config.item_name.is_display">
            <v-divider></v-divider>

            <v-card-subtitle class="black--text title">
              商品名カッコ削除設定
            </v-card-subtitle>

            <v-card-text class="black--text">
              システムが表示する商品名から、カッコを削除するかどうか、選択します。
              <br />
              【翌営業日発送】など、多くの商品名に共通のカッコテキストが入っている場合、
              <br />
              ユーザーに適切に商品を案内するためにONにすることを推奨します。
              <br />
              削除対象：()（）【】《》[]〔〕<>「」

              <v-radio-group row v-model="is_deleting_brackets_on_item_name">
                <v-radio :value="true" :disabled="loading">
                  <template v-slot:label>
                    <div class="black--text">
                      カッコとカッコ内を削除して表示（推奨）
                    </div>
                  </template>
                </v-radio>
                <v-radio :value="false" :disabled="loading">
                  <template v-slot:label>
                    <div class="black--text">商品名をそのまま表示</div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card class="pa-5">
          <v-row>
            <v-col cols="10">
              <v-card-title class="black--text headline">
                <span>帯設定</span>
              </v-card-title>
            </v-col>
            <v-col cols="2" class="text-right">
              <v-btn
                color="primary"
                @click="validate() && updateConfig()"
                :loading="loading"
                :disabled="loading || !valid"
              >
                保存する
              </v-btn>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-card-subtitle class="black--text title">
            帯タイトル設定
          </v-card-subtitle>

          <v-card-text>
            システムが表示する帯のテキストを編集します。PC/スマホ共通の設定です。
          </v-card-text>

          <v-container fluid>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row cols="8">
                <v-col cols="2">
                  <v-subheader class="black--text">新着：</v-subheader>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="config.tools.new.title_text"
                    outlined
                    dense
                    :disabled="loading"
                    :rules="rules"
                    counter="30"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row cols="8">
                <v-col cols="2">
                  <v-subheader class="black--text">再販：</v-subheader>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="config.tools.resale.title_text"
                    outlined
                    dense
                    :disabled="loading"
                    :rules="rules"
                    counter="30"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row cols="8">
                <v-col cols="2">
                  <v-subheader class="black--text">同時購入：</v-subheader>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="config.tools.same_time.title_text"
                    outlined
                    dense
                    :disabled="loading"
                    :rules="rules"
                    counter="30"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row cols="8">
                <v-col cols="2">
                  <v-subheader class="black--text">類似商品：</v-subheader>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="config.tools.similar.title_text"
                    outlined
                    dense
                    :disabled="loading"
                    :rules="rules"
                    counter="30"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>

          <v-divider class="mx-4"></v-divider>

          <v-card-subtitle class="black--text title">
            PC帯デザイン設定
          </v-card-subtitle>
          <p class="px-4">
            帯の色、文字色などデザインを設定します。適用はPCのみで、スマホは変更できません。
          </p>

          <div class="px-1 py-10">
            <v-row>
              <v-col cols="6" class="py-0 px-6">
                <p>現在の設定</p>
                <div
                  class="ecup_t mb-6"
                  :style="
                    'font-size:' +
                    config.band.font_size +
                    'px;color:' +
                    config.band.font_color +
                    ';text-align:' +
                    config.band.text_align +
                    ';padding:' +
                    config.band.size +
                    'px;background:' +
                    config.band.color
                  "
                >
                  ○○○○○○○○○○○○
                </div>
                <img
                  class="px-4"
                  src="/images/display_settings_items.png"
                  style="width: 100%"
                />
              </v-col>

              <v-col
                cols="6"
                class="py-0 px-6"
                style="border-left: solid 1px rgba(0, 0, 0, 0.12)"
              >
                <p>文字サイズ</p>
                <v-slider
                  v-model="config.band.font_size"
                  thumb-color="red"
                  thumb-label="always"
                  max="24"
                  min="10"
                  class="mt-7"
                ></v-slider>

                <p>文字カラー</p>
                <div class="mb-4">
                  <v-color-picker
                    v-model="config.band.font_color"
                    dot-size="16"
                    mode="hexa"
                    width="250"
                    canvas-height="130"
                    class="inline"
                  ></v-color-picker>
                </div>

                <p>文字表示箇所</p>
                <v-radio-group v-model="config.band.text_align" row>
                  <v-radio label="左寄せ" value="left"></v-radio>
                  <v-radio label="中央寄せ" value="center"></v-radio>
                </v-radio-group>

                <p>帯サイズ</p>
                <v-slider
                  v-model="config.band.size"
                  thumb-color="red"
                  thumb-label="always"
                  max="30"
                  min="10"
                  class="mt-7"
                ></v-slider>

                <p>帯背景カラー</p>
                <div class="mb-4">
                  <v-color-picker
                    v-model="config.band.color"
                    dot-size="16"
                    mode="hexa"
                    width="250"
                    canvas-height="130"
                    class="inline"
                  ></v-color-picker>
                </div>
                <div class="text-right">
                  <v-btn
                    small
                    outlined
                    color="grey darken-3"
                    @click="setDefault()"
                  >
                    デフォルト設定に戻す
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <max-byte-size
          :max_byte_size="config.max_byte_size"
          :max_number_of_images="config.max_number_of_images"
          :default_max_byte_size="default_values.max_byte_size"
          :default_max_number_of_images="default_values.max_number_of_images"
          :loading="loading"
          @updateConfig="updateConfig()"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <review
          :config="config.tools.review"
          :default_values="default_values.tools.review"
          :loading="loading"
          @updateConfig="updateConfig()"
        />
      </v-col>
    </v-row>

    <floating-notification-alert
      :open="finished_alert"
      :ms="2000"
      @close="finished_alert = false"
    >
      <v-icon color="success">mdi-checkbox-marked</v-icon>
      <span color="success">設定を保存しました。</span>
    </floating-notification-alert>
  </v-container>
</template>

<script>
import axios from "axios";
import deepmerge from "deepmerge";
import userUtilsMixin from "../../packs/mixins/user_utils";
import FloatingNotificationAlert from "../components/alert/FloatingNotificationAlert.vue";
import HideProduct from "./components/display_settings/HideProduct.vue";
import MaxByteSize from "./components/display_settings/MaxByteSize.vue";
import Review from "./components/display_settings/Review.vue";

export default {
  data() {
    return {
      loading: false,
      is_deleting_brackets_on_item_name: false,
      finished_alert: false,
      config: {
        ignoring_item_names: [],
        ignoring_item_codes: [],
        tools: {
          new: {
            title_text: "",
          },
          resale: {
            title_text: "",
          },
          same_time: {
            title_text: "",
          },
          similar: {
            title_text: "",
          },
          review: {
            min_review_average_display: null,
          },
        },
        band: {
          font_size: "",
          font_color: "",
          text_align: "",
          size: "",
          color: "",
        },
        max_byte_size: {
          for_smartphone: null,
          by_sales_method: null,
          for_pc: null,
        },
        max_number_of_images: {
          for_smartphone: null,
        },
        item_name: {
          is_display: null,
        },
      },
      default_values: {
        tools: {
          new: {
            title_text: "新着商品はこちら",
          },
          resale: {
            title_text: "再販商品はこちら",
          },
          same_time: {
            title_text: "よく一緒に購入されている商品",
          },
          similar: {
            title_text: "類似商品はこちら",
          },
          review: {
            min_review_average_display: 4.5,
          },
        },
        band: {
          font_size: 16,
          font_color: "#222222",
          text_align: "left",
          size: 20,
          color: "#dddddd",
        },
        max_byte_size: {
          for_smartphone: 10_240,
          by_sales_method: 10_240,
          for_pc: 10_240,
        },
        max_number_of_images: {
          for_smartphone: 20,
        },
        item_name: {
          is_display: true,
        },
      },
      valid: true,
      rules: [(v) => v.length <= 30 || "最大30文字"],
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    isAvailablePremium() {
      return this.user["is_available_premium?"];
    },
  },

  components: {
    FloatingNotificationAlert,
    HideProduct,
    MaxByteSize,
    Review,
  },

  mixins: [userUtilsMixin],

  watch: {
    user: {
      handler(val) {
        const config = val?.config;
        // 非表示商品設定
        this.config.ignoring_item_names = config?.ignoring_item_names;
        this.config.ignoring_item_codes = config?.ignoring_item_codes;
        // 帯タイトル設定
        const tools = config?.tools;
        this.config.tools.new.title_text =
          tools?.new?.title_text || this.default_values.tools.new.title_text;
        this.config.tools.resale.title_text =
          tools?.resale?.title_text ||
          this.default_values.tools.resale.title_text;
        this.config.tools.same_time.title_text =
          tools?.same_time?.title_text ||
          this.default_values.tools.same_time.title_text;
        this.config.tools.similar.title_text =
          tools?.similar?.title_text ||
          this.default_values.tools.similar.title_text;
        // 商品レビュー設定
        this.config.tools.review.min_review_average_display =
          tools?.review?.min_review_average_display ||
          this.default_values.tools.review.min_review_average_display;
        // 帯スタイル設定
        const band = config?.band;
        this.config.band.font_size =
          band?.font_size || this.default_values.band.font_size;
        this.config.band.font_color =
          band?.font_color || this.default_values.band.font_color;
        this.config.band.text_align =
          band?.text_align || this.default_values.band.text_align;
        this.config.band.size = band?.size || this.default_values.band.size;
        this.config.band.color = band?.color || this.default_values.band.color;
        // 商品名カッコ削除設定
        this.is_deleting_brackets_on_item_name =
          val?.is_deleting_brackets_on_item_name;
        // 利用可能画像数・バイト数設定
        const max_byte_size = config?.max_byte_size;
        this.config.max_byte_size.for_smartphone =
          max_byte_size?.for_smartphone ||
          this.default_values.max_byte_size.for_smartphone;
        this.config.max_byte_size.by_sales_method =
          max_byte_size?.by_sales_method ||
          this.default_values.max_byte_size.by_sales_method;
        this.config.max_byte_size.for_pc =
          max_byte_size?.for_pc || this.default_values.max_byte_size.for_pc;
        const max_number_of_images = config?.max_number_of_images;
        this.config.max_number_of_images.for_smartphone =
          max_number_of_images?.for_smartphone ||
          this.default_values.max_number_of_images.for_smartphone;
        // 商品名設定
        const item_name = config?.item_name;
        this.config.item_name.is_display =
          item_name === undefined
            ? this.default_values.item_name.is_display
            : item_name.is_display;
      },
    },
  },

  async created() {
    this.fetch_user();
  },

  methods: {
    updateConfig() {
      this.$nextTick(() => {
        // このページにないconfigが消えてしまうためマージする
        const config = deepmerge(this.user.config, this.config);
        // マージにより配列が重複するので
        config.ignoring_item_names = this.config.ignoring_item_names;
        config.ignoring_item_codes = this.config.ignoring_item_codes;

        try {
          this.loading = true;
          axios.post("/api/v1/shops/me", {
            shop: {
              config: config,
              is_deleting_brackets_on_item_name:
                this.is_deleting_brackets_on_item_name,
            },
          });
        } catch (e) {
          console.error(e);
        } finally {
          this.loading = false;
          this.finished_alert = true;
        }
      });
    },

    validate() {
      return this.$refs.form.validate();
    },
    setDefault() {
      this.config.band = JSON.parse(JSON.stringify(this.default_values.band));
    },
  },
};
</script>
<style lang="scss">
.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: inherit;
}
.ecup_t {
  background: #ddd;
  padding: 1em;
  text-align: left;
  font-weight: bold;
}
.v-color-picker {
  &.inline {
    display: flex;
    width: 100%;
    max-width: none !important;
    .v-color-picker__canvas {
      width: 50%;
      border-radius: 4px;
    }
    .v-color-picker__controls {
      width: 50%;
    }
  }
}
</style>
