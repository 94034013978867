<template>
  <v-container v-if="user" fluid>
    <v-row justify="space-between">
      <v-col cols="8" class="display-1">
        <span>{{ user.name }}×EC-UP</span>
        <span class="ml-2">ショップ一覧</span>
      </v-col>
    </v-row>
    <div class="justify-center d-flex my-8">
      <div v-if="isAmbassador" class="mx-2">
        アンバサダーキー：紹介コード「{{ user.shop_code }}」
      </div>
      <div v-if="isAgency" class="mx-2">
        販売店キー：登録メアド　@{{ user.agency_domain }}
      </div>
    </div>
    <v-card
      v-if="isAmbassador"
      class="rounded-lg my-8 px-4 py-6"
      :style="{ background: '#DEEFFF' }"
      elevation="4"
    >
      <v-row justify="center" align="center">
        <v-col cols="2" class="font-weight-bold">アンバサダー</v-col>
        <v-col cols="2">合計ショップ数</v-col>
        <v-col cols="2">
          <v-text-field
            v-model="ambassadorShopCount"
            hide-details
            outlined
            dense
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="1">SHOP</v-col>
        <v-col cols="2">キックバック金額</v-col>
        <v-col cols="2">
          <v-text-field
            :value="ambassadorKickbackPriceAmount.toLocaleString()"
            hide-details
            outlined
            dense
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="1">円</v-col>
      </v-row>
    </v-card>
    <v-card
      v-if="isAgency"
      class="rounded-lg my-8 px-4 py-6"
      :style="{ background: '#FFFCDE' }"
      elevation="4"
    >
      <v-row justify="center" align="center">
        <v-col cols="2" class="font-weight-bold">合計ショップ数</v-col>
        <v-col cols="2">販売店</v-col>
        <v-col cols="2">
          <v-text-field
            v-model="agencyShopTotalCount"
            hide-details
            outlined
            dense
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="1">SHOP</v-col>
        <v-col cols="2">料金</v-col>
        <v-col cols="2">
          <v-text-field
            :value="agencyShopTotalPriceAmount.toLocaleString()"
            hide-details
            outlined
            dense
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="1">円</v-col>
      </v-row>
      <v-row class="mt-2" v-if="agencyShopTotalInitialCosts.length">
        <v-col cols="10" offset="2" class="pt-0 text-right text-body-2">
          （プラチナプラン初期費用
          <span
            v-for="(item, index) in agencyShopTotalInitialCosts"
            :key="index"
            class="pl-1"
          >
            {{ item.month }}月:{{ item.cost.toLocaleString() }}円
          </span>
          ）
        </v-col>
      </v-row>

      <v-row justify="center" align="center">
        <v-col cols="2"></v-col>
        <v-col cols="2">ショップ単位</v-col>
        <v-col cols="2">
          <v-text-field
            v-model="agencyShopShopCount"
            hide-details
            outlined
            dense
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="1">SHOP</v-col>
        <v-col cols="2">料金</v-col>
        <v-col cols="2">
          <v-text-field
            :value="agencyShopShopPriceAmount.toLocaleString()"
            hide-details
            outlined
            dense
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="1">円</v-col>
      </v-row>
      <v-row class="mt-2" v-if="agencyShopShopInitialCosts.length">
        <v-col cols="10" offset="2" class="pt-0 text-right text-body-2">
          （プラチナプラン初期費用
          <span
            v-for="(item, index) in agencyShopShopInitialCosts"
            :key="index"
            class="pl-1"
          >
            {{ item.month }}月:{{ item.cost.toLocaleString() }}円
          </span>
          ）
        </v-col>
      </v-row>

      <v-row justify="center" align="center">
        <v-col cols="2"></v-col>
        <v-col cols="2">商品単位数</v-col>
        <v-col cols="2">
          <v-text-field
            v-model="agencyItemShopCount"
            hide-details
            outlined
            dense
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="1">SHOP</v-col>
        <v-col cols="2">料金</v-col>
        <v-col cols="2">
          <v-text-field
            :value="agencyItemShopPriceAmount.toLocaleString()"
            hide-details
            outlined
            dense
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="1">円</v-col>
      </v-row>
      <div class="text-right mt-4">
        <div>({{ agencyItemTotalCount }}商品)</div>
      </div>
    </v-card>
    <v-card
      class="rounded-lg my-8 px-4 py-6"
      :style="{ background: '#F5F5F5' }"
    >
      <v-card-text class="text-center black--text">
        <span class="font-weight-bold title">
          {{ getTransferOrBilling() }}
        </span>
        <span class="font-weight-bold display-1 ml-2">
          {{ transferOrBillingPrice.toLocaleString() }}
        </span>
        <span class="title ml-2">円</span>
        <span class="title ml-2">
          {{ getTransferOrBillingMessage() }}
        </span>
      </v-card-text>

      <v-card-text class="text-center black--text">
        <span
          >キックバック金額＞販売金額　の場合　差額を指定銀行口座へ振り込みます
        </span>
        <br />
        <span
          >販売金額＞キックバック金額　の場合　差額をstripeから課金します</span
        >
        <br />
        <span
          >キックバック金額＝販売金額　の場合　振込や課金は発生しません</span
        >
      </v-card-text>

      <v-card-text class="text-center black--text">
        {{ currentDateTime() }}
      </v-card-text>
    </v-card>
    <div class="my-8 text-center">
      <v-btn class="rounded-xl" outlined color="black" @click="addShop"
        >新規ショップ追加</v-btn
      >
      <v-btn class="rounded-xl" outlined color="black" @click="login"
        >ログアウト（ログイン画面に遷移します）</v-btn
      >
    </div>
    <ShopList
      :shops="shops"
      @change-plan="changePlan"
      @change-lisence="changeLisence"
      @edit-memo="editMemo"
      @change-options="changeOptions($event)"
      @editRLoginPassword="editRLoginPassword($event)"
    />

    <ecup-modal
      v-if="showEditMemoModal"
      @close_this_modal="closeEditMemoModal()"
    >
      <template>
        <v-card flat>
          <v-card-title class="justify-center"
            >{{ selectShop.shop_name }} メモ</v-card-title
          >
          <v-card-text>
            <v-textarea outlined v-model="remarksForAgency"> </v-textarea>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              width="30%"
              :loading="loading"
              :disabled="loading"
              @click="updateRemarksForAgency()"
            >
              更新
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ecup-modal>

    <ecup-modal
      v-if="sendRmsSecretModal"
      title="ライセンスキーの変更"
      modal_width="775px"
      @close_this_modal="sendRmsSecretModal = false"
    >
      <send-rms-secret
        :get_secret="!selectShop.lisence_past_days"
        :allow_expired_at_date_null="false"
        @lisence_key_update_succeed="
          initialize();
          sendRmsSecretModal = false;
        "
        :selectShop="selectShop"
      >
      </send-rms-secret>
    </ecup-modal>

    <option-index-modal
      v-if="showOptionIndexModal"
      :isAdminShop="true"
      :user="selectShop"
      @close_this_modal="showOptionIndexModal = false"
      @fetchUser="fetchUser($event)"
      @setUser="setUser($event)"
    />

    <rms-password-update
      v-if="rms_pw_update_modal"
      :isAdminShop="true"
      :user="selectShop"
      @close_this_modal="
        rms_pw_update_modal = false;
        fetchUser($event);
      "
    />
  </v-container>
</template>

<script>
import { getAuth, signOut } from "firebase/auth";
import axios from "axios";
import timeUtilsMixin from "../../packs/mixins/time_utils";
import userUtilsMixin from "../../packs/mixins/user_utils";
import ShopList from "./components/ShopList.vue";
import EcupModal from "../components/EcupModal.vue";
import SendRmsSecret from "../components/SendRmsSecret.vue";
import OptionIndexModal from "../option/OptionIndexModal.vue";
import RmsPasswordUpdate from "../components/RmsPasswordUpdate.vue";

export default {
  name: "ShopAdmin",

  components: {
    ShopList,
    EcupModal,
    SendRmsSecret,
    OptionIndexModal,
    RmsPasswordUpdate,
  },

  mixins: [timeUtilsMixin, userUtilsMixin],

  data() {
    return {
      shops: [],
      agencyEmail: "",
      ambassadorShopCount: 0,
      agencyItemShopCount: 0,
      agencyShopShopCount: 0,
      agencyShopTotalCount: 0,
      ambassadorKickbackPriceAmount: 0,
      agencyItemShopPriceAmount: 0,
      agencyShopShopPriceAmount: 0,
      agencyShopTotalPriceAmount: 0,
      agencyItemTotalCount: 0,
      transferOrBilling: "",
      transferOrBillingPrice: 0,
      agencyShopTotalInitialCosts: [],
      agencyShopShopInitialCosts: [],
      showEditMemoModal: false,
      selectShop: {},
      remarksForAgency: "",
      loading: false,
      sendRmsSecretModal: false,
      showOptionIndexModal: false,
      rms_pw_update_modal: false,
    };
  },

  watch: {
    user(newVal) {
      if (newVal) {
        this.initialize();
      }
    },
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    isAgency() {
      return this.user.is_agency;
    },
    isAmbassador() {
      return this.user.is_ambassador;
    },
  },

  async created() {
    this.fetch_user();
  },

  methods: {
    async initialize() {
      await this.getShopList();
    },
    async getShopList() {
      const { data } = await axios.get(`/api/v1/shops/list`, {
        timeout: 30000,
      });
      this.shops = data?.shops || [];
      await this.updateShops();
      this.totalKickback = data?.total_kickback || 0;

      this.agencyEmail = data?.email || "";
      this.ambassadorShopCount = data?.ambassador_shop_count || 0;
      this.agencyItemShopCount = data?.agency_item_shop_count || 0;
      this.agencyShopShopCount = data?.agency_shop_shop_count || 0;
      this.agencyShopTotalCount = data?.agency_shop_total_count || 0;
      this.ambassadorKickbackPriceAmount =
        data?.ambassador_kickback_price_amount || 0;
      this.agencyItemShopPriceAmount = data?.agency_item_shop_price_amount || 0;
      this.agencyShopShopPriceAmount = data?.agency_shop_shop_price_amount || 0;
      this.agencyShopTotalPriceAmount =
        data?.agency_shop_total_price_amount || 0;
      this.agencyItemTotalCount = data?.agency_item_total_count || 0;
      this.transferOrBilling = data?.transfer_or_billing || "";
      this.transferOrBillingPrice = data?.transfer_or_billing_price || 0;
      this.agencyShopTotalInitialCosts =
        data?.agency_shop_total_initial_costs || [];
      this.agencyShopShopInitialCosts =
        data?.agency_shop_shop_initial_costs || [];
    },
    updateShops() {
      this.shops.forEach((shop) => {
        let text = shop.plan_name;
        if (shop.plan_name === "トライアル" && shop.trial_past_days) {
          text += `(${shop.trial_past_days.toString().padStart(2, "0")})`;
        }
        shop.planNameAndTrialPastDays = text;
      });
    },
    getTransferOrBilling() {
      if (this.transferOrBilling === "transfer") {
        return "振込金額";
      } else if (this.transferOrBilling === "billing") {
        return "支払金額";
      }
    },
    getTransferOrBillingMessage() {
      if (this.transferOrBilling === "transfer") {
        return "を振り込みます";
      } else if (this.transferOrBilling === "billing") {
        return "を課金します";
      }
    },
    login() {
      const auth = getAuth();
      signOut(auth).then(() => {
        this.fetch_user();
        this.$router.push({ name: "SignIn" });
      });
    },
    addShop() {
      const auth = getAuth();
      signOut(auth).then(() => {
        this.fetch_user();
        this.$router.push({ name: "SignUp" });
      });
    },
    changeLisence(shop) {
      this.selectShop = shop;
      this.sendRmsSecretModal = true;
    },
    editMemo(shop) {
      this.showEditMemoModal = true;
      this.selectShop = shop;
      this.remarksForAgency = shop.remarks_for_agency;
    },
    changeOptions(shopCode) {
      axios
        .get("/api/v1/shops/get_shop_data", {
          params: { shop_code: shopCode },
        })
        .then((res) => {
          this.selectShop = res.data;
          this.showOptionIndexModal = true;
        });
    },
    editRLoginPassword(shopCode) {
      axios
        .get("/api/v1/shops/get_shop_data", {
          params: { shop_code: shopCode },
        })
        .then((res) => {
          this.selectShop = res.data;
          this.rms_pw_update_modal = true;
        });
    },
    currentDateTime() {
      return this.time_now().format("YYYY/MM/DD HH:mm:ss");
    },
    closeEditMemoModal() {
      this.selectShop = {};
      this.showEditMemoModal = false;
      this.remarksForAgency = "";
      this.loading = false;
    },
    updateRemarksForAgency() {
      this.loading = true;
      const data = {
        shop_code: this.selectShop.shop_code,
        remarks_for_agency: this.remarksForAgency,
      };
      axios
        .post(`/api/v1/shops/remarks_for_agency`, data)
        .then((res) => {
          const selectShop = res.data.shop;
          const shopIndex = this.shops.findIndex(
            (shop) => shop.id === selectShop.id
          );
          this.shops[shopIndex].remarks_for_agency =
            selectShop.remarks_for_agency;
          this.loading = false;
          this.showEditMemoModal = false;
          this.remarksForAgency = "";
        })
        .catch(() => {
          this.loading = false;
          alert("メモの更新に失敗しました。");
          this.showEditMemoModal = false;
        });
    },
    changePlan() {
      this.initialize();
    },

    fetchUser(shopCode) {
      axios
        .get("/api/v1/shops/get_shop_data", {
          params: { shop_code: shopCode },
        })
        .then((res) => {
          this.selectShop = res.data;
        });
    },

    setUser(data) {
      this.selectShop = data;
    },
  },
};
</script>
