<template>
  <v-footer color="#EEEEEE">
    <v-card flat tile color="#EEEEEE" class="black--text text-center mx-auto">
      <v-card-text class="black--text" v-if="isFooterLinksVisible">
        <a href="https://www.ecdb.jp/" class="black--text">
          会社概要
        </a>
        ｜
        <a href="https://ec-up.jp/terms/" class="black--text">
          利用規約
        </a>
        ｜
        <a href="https://ec-up.jp/policy/" class="black--text">
          プライバシーポリシー
        </a>
        ｜
        <a href="https://ec-up.jp/law/" class="black--text">
          特定商取引法に基づく表記
        </a>
        <span v-if="user && !user.is_agency">
          ｜
          <a class="black--text" @click="$router.push({ name: 'Withdraw' })">
            退会について
          </a>
        </span>
      </v-card-text>

      <v-card-text class="black--text">
        © 2020 <strong>EC-UP</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.state.user;
    },
    isFooterLinksVisible() {
      return this.$store.state.isFooterLinksVisible;
    },
  },
};
</script>

<style scoped></style>
