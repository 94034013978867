<template>
  <div>
    <v-row justify="center">
      <v-col cols="4">
        <v-btn
          large
          block
          color="primary"
          @click="showModal = true"
          :disabled="!Boolean(option.note)"
        >
          設定スタート
        </v-btn>
      </v-col>
    </v-row>

    <ecup-modal v-if="showModal" @close_this_modal="showModal = false">
      <v-row justify="center">
        <v-col cols="auto">
          <v-carousel
            :show-arrows="true"
            show-arrows-on-hover
            height="420px"
            class="mx-auto bg-gray"
            hide-delimiters
          >
            <v-carousel-item
              v-for="(image, i) in procedure_images"
              :key="i"
              :src="image.src"
            ></v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="4">
          <v-btn
            large
            block
            color="primary"
            @click="checkAPI()"
            :loading="loading"
          >
            API動作チェック
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="errors.length" justify="center">
        <v-col class="auto">
          <v-alert type="error">
            <p>以下の項目が不足していませんか？ご確認ください！</p>
            <ul>
              <li v-for="(error, i) in errors" :key="i">
                {{ error }}
              </li>
            </ul>
          </v-alert>
        </v-col>
      </v-row>
    </ecup-modal>
  </div>
</template>

<script>
import axios from "axios";
import EcupModal from "../../../components/EcupModal.vue";

export default {
  components: { EcupModal },

  props: {
    user: {
      type: Object,
      required: true,
    },
    option: {
      type: Object,
      required: true,
    },
    isAdminShop: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showModal: false,
      loading: false,
      procedure_images: [
        { src: "/images/options/shop_top/api/01.gif" },
        { src: "/images/options/shop_top/api/02.png" },
        { src: "/images/options/shop_top/api/03.png" },
        { src: "/images/options/shop_top/api/04.png" },
        { src: "/images/options/shop_top/api/05.png" },
        { src: "/images/options/shop_top/api/06.png" },
        { src: "/images/options/shop_top/api/07.png" },
        { src: "/images/options/shop_top/api/08.png" },
        { src: "/images/options/shop_top/api/09.png" },
        { src: "/images/options/shop_top/api/10.png" },
        { src: "/images/options/shop_top/api/11.png" },
        { src: "/images/options/shop_top/api/12.gif" },
        { src: "/images/options/shop_top/api/13.gif" },
      ],
      errors: [],
    };
  },

  methods: {
    checkAPI() {
      let url = this.isAdminShop
        ? "/api/v1/shops/admin_check_shop_top_api"
        : "/api/v1/shops/check_shop_top_api";

      this.loading = true;
      this.errors = [];

      axios
        .post(url, {
          shop_code: this.user.shop_code,
        })
        .then((response) => {
          // OKだったらモーダルが消えます
          this.loading = false;
          this.showModal = false;
          this.$emit("success", response.data);
        })
        .catch((e) => {
          // NGだったらエラーを表示します
          console.error(e.response.data);

          this.loading = false;
          this.errors = e.response.data.errors;
        });
    },
  },
};
</script>
