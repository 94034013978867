<template>
  <v-row>
    <v-col cols="11" offset="1">
      <v-card-title> デザイン </v-card-title>
      <v-card-text>
        おまかせ機能のラクイチアピールで使用するデザインを設定します。<br />
        ※下記画像はデイリー用のサンプル画像です。リアルタイムにて１位を獲得した場合には、リアルタイムの１位画像が表示されます。<br />
        ※各履歴付のデザインを選択した場合には、デイリーが表示されます。
        <span v-if="!isAvailablePremium()">
          <br />※グレーアウトされたデザインは、ゴールド以上のプランでご利用可能です。
        </span>
        <v-radio-group v-model="full_auto_rakuichi_layout_id">
          <v-row>
            <v-col
              cols="3"
              v-for="layout in layouts_rakuichi"
              :key="layout.id"
              class="v-card"
            >
              <v-overlay
                absolute
                opacity="0.25"
                v-if="!isAvailable(layout)"
              ></v-overlay>
              <v-radio
                dense
                :label="layout.name"
                :value="layout.id"
                :disabled="!isAvailable(layout)"
                @click="update()"
              ></v-radio>
              <v-img :src="layout.preview_image_url"></v-img>
            </v-col>
          </v-row>
        </v-radio-group>
      </v-card-text>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  props: ["user"],

  data() {
    return {
      full_auto_rakuichi_layout_id: null,
      layouts_rakuichi: [],
    };
  },

  watch: {
    user: {
      handler(val) {
        this.full_auto_rakuichi_layout_id = val.full_auto_rakuichi_layout_id;
      },
      immediate: true,
    },
  },

  async created() {
    await axios
      .get("/api/v1/tools/find_by", {
        params: { name_en: "rakuichi", target: "descriptionForSmartPhone" },
      })
      .then(async (res) => {
        this.layouts_rakuichi = res.data.tool.layouts;
      });
  },

  methods: {
    isAvailablePremium() {
      return this.user["is_available_premium?"];
    },

    isAvailable(layout) {
      return this.isAvailablePremium() || !layout.is_premium;
    },

    async update() {
      await axios
        .post("/api/v1/shops/me", {
          full_auto_rakuichi_layout_id: this.full_auto_rakuichi_layout_id,
        })
        .then(async (res) => {
          this.$emit("updated", res);
        });
    },
  },
};
</script>
