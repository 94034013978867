<template>
  <header id="ec-ai-header" v-if="user">
    <v-app-bar id="ec_up_header_bar" height="70px">
      <div class="d-flex align-center" v-if="user.completed_first_update_items">
        <div
          :style="{ backgroundColor: userPlanColor }"
          class="py-5 mr-2"
          style="padding-right: 5px"
        ></div>
        <div class="mr-4 border">
          <span class="text-h4 grey--text text--darken-2 font-weight-bold">{{
            planNameToDisplay
          }}</span>
        </div>
        <div class="mr-4" v-if="userPlanCode === 'trial'">
          <span class="grey--text text--darken-2 font-weight-bold">あと</span
          ><span class="text-h4 grey--text text--darken-2 font-weight-bold">{{
            remainingTrialValidDate
          }}</span
          ><span class="grey--text text--darken-2 font-weight-bold">日</span>
        </div>
        <div class="mr-4" v-else-if="userPlanCode === 'gold_trial'">
          <span class="grey--text text--darken-2 font-weight-bold">あと</span
          ><span class="text-h4 grey--text text--darken-2 font-weight-bold">{{
            remainingGoldTrialValidDate
          }}</span
          ><span class="grey--text text--darken-2 font-weight-bold">日</span>
        </div>

        <v-btn
          v-if="isDisplayPlanChangeButton"
          color="primary"
          @click="showPlanIndexModal = true"
          >プラン変更
        </v-btn>
        <span>&nbsp;</span>
        <v-btn
          v-if="user.is_admin"
          color="primary"
          @click="impersonationPageToLink"
          >なりすまし
        </v-btn>
      </div>
      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="showInstructions()">
            <v-icon>$vuetify.icons.beginner</v-icon>
          </v-btn>
        </template>
        <span>初心者ガイド</span>
      </v-tooltip>

      <v-tooltip v-if="!user['is_agency_child?']" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="InvitationPageToLink()">
            <v-icon> mdi-bullhorn-outline </v-icon>
          </v-btn>
        </template>
        <span>紹介ページ</span>
      </v-tooltip>

      <Notice />

      <div class="subtitle-2 mx-3">
        {{ user.name }}<br />
        店舗ID：{{ user.shop_id }}
        <span v-if="!user['is_agency_child?']" style="padding-left: 10px">
          紹介コード：{{ user.shop_code }}
        </span>
      </div>

      <v-menu
        v-model="menu"
        bottom
        offset-y
        :close-on-content-click="false"
        transition="slide-y-transition"
      >
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                class="hover_color_disabled_btn px-0 header_shop_logo_btn"
                height="55"
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
                color="white"
                style="
                  text-transform: none;
                  box-shadow: none;
                  border: solid 2px #eeeeee !important;
                  width: 80px;
                "
              >
                <v-img :src="shop_image_url" width="38"></v-img>
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <span>メニュー</span>
          </v-tooltip>
        </template>

        <v-card style="text-align: center" max-width="365">
          <v-list style="padding-top: 0px">
            <v-list-item>
              <v-spacer></v-spacer>
              <v-btn text @click="menu = false">
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-img
                  style="
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 20px;
                  "
                  max-height="150"
                  max-width="150"
                  position="center"
                  :src="shop_image_url"
                />

                <v-list-item-title>{{ user.name }}</v-list-item-title>
                <a :href="shop_url" target="_blank">
                  <v-list-item-subtitle style="margin-top: 10px">
                    {{ shop_url }}
                  </v-list-item-subtitle>
                </a>
                <v-list-item-subtitle style="margin-top: 10px">
                  店舗ID：{{ user.shop_id }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  紹介コード：{{ user.shop_code }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list dense nav>
            <v-list-item @click="move_to_faq">
              <v-list-item-icon>
                <v-icon>mdi-help</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="header_clicked_util_links_text"
                >よくある質問</v-list-item-title
              >
            </v-list-item>

            <v-list-item @click="move_to_toiawase">
              <v-list-item-icon>
                <v-icon>mdi-comment-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="header_clicked_util_links_text"
                >お問い合わせ</v-list-item-title
              >
            </v-list-item>

            <v-list-item
              v-if="canChangeUserSettings"
              @click="$router.push({ name: 'EmailChange' })"
            >
              <v-list-item-icon>
                <v-icon>mdi-email-edit-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="header_clicked_util_links_text"
                >メールアドレス変更</v-list-item-title
              >
            </v-list-item>

            <v-list-item
              v-if="canChangeUserSettings"
              @click="$router.push({ name: 'PasswordChange' })"
            >
              <v-list-item-icon>
                <v-icon>mdi-form-textbox-lock</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="header_clicked_util_links_text"
                >パスワード変更</v-list-item-title
              >
            </v-list-item>

            <v-list-item
              v-if="canChangePlan"
              @click="showPlanIndexModal = true"
            >
              <v-list-item-icon>
                <v-icon>mdi-clipboard-edit-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="header_clicked_util_links_text">
                プラン変更
              </v-list-item-title>
            </v-list-item>

            <v-list-item @click="showOptionIndexModal = true">
              <v-list-item-icon>
                <v-icon>mdi-puzzle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="header_clicked_util_links_text">
                オプション機能 追加/変更
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="user.use_stripe && user.plan['paid?']"
              @click="showPaymentMethodModal = true"
            >
              <v-list-item-icon>
                <v-icon>mdi-credit-card-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="header_clicked_util_links_text">
                支払い方法変更
              </v-list-item-title>
            </v-list-item>

            <v-list-item href="https://app.ec-up.jp/delete/" target="_blank">
              <v-list-item-icon>
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="header_clicked_util_links_text"
                >タグ削除</v-list-item-title
              >
            </v-list-item>

            <v-list-item @click="logOut">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="header_clicked_util_links_text"
                >ログアウト</v-list-item-title
              >
            </v-list-item>
          </v-list>

          <v-card-actions> </v-card-actions>
        </v-card>
      </v-menu>
    </v-app-bar>

    <option-index-modal
      v-if="showOptionIndexModal"
      @close_this_modal="showOptionIndexModal = false"
      :user="user"
    />

    <plan-index-modal
      v-if="showPlanIndexModal"
      @close_this_modal="showPlanIndexModal = false"
    />

    <payment-method-modal
      v-if="showPaymentMethodModal"
      @close_this_modal="showPaymentMethodModal = false"
    />
  </header>
</template>

<script>
import { getAuth, signOut } from "firebase/auth";
import axios from "axios";
import { mapMutations } from "vuex";
import { SET_RAKUTEN_ITEM_STATS } from "store/mutation-types";
import userUtilsMixin from "../../packs/mixins/user_utils";
import OptionIndexModal from "../option/OptionIndexModal.vue";
import PlanIndexModal from "../plan/PlanIndexModal.vue";
import PaymentMethodModal from "../plan/PaymentMethodModal.vue";
import Notice from "./header/Notice.vue";
import timeUtilsMixin from "../../packs/mixins/time_utils";
import config from "../../const";

export default {
  name: "EcAiHeader",
  mixins: [userUtilsMixin, timeUtilsMixin],
  components: {
    OptionIndexModal,
    PlanIndexModal,
    PaymentMethodModal,
    Notice,
  },
  watch: {
    user: function (newVal, oldVal) {
      if (newVal && !this.$store.state.rakuten_item_stats) {
        axios.get(`/api/v1/items/rakuten_status_stats`).then(async (res) => {
          this.SET_RAKUTEN_ITEM_STATS(res.data);
        });
      }
    },
  },

  data: () => ({
    menu: false,
    showOptionIndexModal: false,
    showPlanIndexModal: false,
    showPaymentMethodModal: false,
  }),

  created() {
    this.checkParams();
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    userPlanCode() {
      return this.user.plan.code;
    },
    userPlanColor() {
      switch (this.userPlanCode) {
        case "free":
          return "#83A943";
        case "silver":
          return "#8C8A84";
        case "gold":
        case "gold_trial":
          return "#CCA83F";
        case "trial":
          return "#67A8A6";
        case "platinum":
          return "#4688A4";
      }
    },
    remainingTrialValidDate() {
      return this.date_from_today(this.user.trial_ends_at);
    },
    remainingGoldTrialValidDate() {
      return this.date_from_today(this.user.gold_trial_ends_at);
    },
    shop_url() {
      return "https://www.rakuten.co.jp/" + this.user.shop_code;
    },
    shop_image_url() {
      return (
        "https://thumbnail.image.rakuten.co.jp/@0_mall/" +
        this.user.shop_code +
        "/logo/logo1.jpg?_ex=300x300"
      );
    },
    canChangePlan() {
      return this.user.use_stripe && this.user["trial_started?"];
    },
    canChangeUserSettings() {
      return !this.user["is_agency_child?"];
    },
    isDisplayPlanChangeButton() {
      return (
        this.canChangePlan &&
        (this.userPlanCode === "free" || this.userPlanCode === "trial")
      );
    },

    planNameToDisplay() {
      if (this.userPlanCode === "gold_trial") {
        return "おためしGold";
      } else {
        return this.capitalizingFirstLetter(this.userPlanCode);
      }
    },
  },

  methods: {
    checkParams() {
      if (this.$route.query.show_plan_modal === "true") {
        this.showPlanIndexModal = true;
      }
    },

    capitalizingFirstLetter(value) {
      return value.slice(0, 1).toUpperCase() + value.slice(1);
    },
    move_to_faq() {
      window.open(config.HREF_FAQ);
    },
    move_to_toiawase() {
      window.open(config.HREF_FEEDBACK);
    },
    logOut() {
      const auth = getAuth();
      signOut(auth).then(() => {
        this.fetch_user();
        this.$router.push({ name: "SignIn" });
      });
    },
    impersonationPageToLink() {
      this.$router.push({ name: "Impersonation" });
    },
    showInstructions() {
      window.open(
        this.$router.resolve({ name: "Instructions" }).href,
        "_blank",
        "width=775,height=485"
      );
    },
    InvitationPageToLink() {
      window.open(
        this.$router.resolve({
          name: "InvitationPage",
          params: { shop_code: this.user.shop_code },
        }).href,
        "_blank"
      );
    },

    ...mapMutations({
      SET_RAKUTEN_ITEM_STATS,
    }),
  },
};
</script>

<style scoped>
#ec_up_header_bar {
  background-color: white;
}
.header_clicked_util_links_text {
  margin-left: 70px;
  text-align: left;
}
.hover_color_disabled_btn::before {
  background-color: white;
}

.v-menu__content.theme--light.menuable__content__active {
  top: 5px !important;
}
</style>
