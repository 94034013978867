<template>
  <div>
    <Instructions v-if="showInstructions" @close="fetch_user()" />
    <TrialWillEnd
      v-if="showTrialWillEnd"
      @close="confirm_trial_will_end_modal()"
    />
    <TrialEndedAndChangedToFree
      v-if="showTrialEndedAndChangedToFree"
      @close="fetch_user()"
    />
    <PaymentFailed v-if="showPaymentFailed" />
    <PaymentFailedAndChangedToFree
      v-if="showPaymentFailedAndChangedToFree"
      @close="fetch_user()"
    />
    <ExceededItemLimit v-if="showExceededItemLimit" />
  </div>
</template>

<script>
import timeUtilsMixin from "../../packs/mixins/time_utils";
import userUtilsMixin from "../../packs/mixins/user_utils";
import Instructions from "./Instructions.vue";
import TrialWillEnd from "./TrialWillEnd.vue";
import TrialEndedAndChangedToFree from "./TrialEndedAndChangedToFree.vue";
import PaymentFailed from "./PaymentFailed.vue";
import PaymentFailedAndChangedToFree from "./PaymentFailedAndChangedToFree.vue";
import ExceededItemLimit from "./ExceededItemLimit.vue";

export default {
  components: {
    Instructions,
    TrialWillEnd,
    TrialEndedAndChangedToFree,
    PaymentFailed,
    PaymentFailedAndChangedToFree,
    ExceededItemLimit,
  },

  mixins: [timeUtilsMixin, userUtilsMixin],

  data: () => ({
    isrenderingPagePublic: false,
    isrenderingPageChangePlan: false,
    isrenderingPageWithdraw: false,
    max_num_of_items_of_free: 20000,
  }),

  computed: {
    user() {
      return this.$store.state.user;
    },

    showInstructions() {
      return (
        this.user &&
        this.user.completed_first_update_items &&
        !this.user.completed_guidance
      );
    },

    showTrialWillEnd() {
      return (
        !this.isrenderingPagePublic &&
        !this.isrenderingPageChangePlan &&
        !this.isrenderingPageWithdraw &&
        this.user &&
        this.user.plan.code === "trial" &&
        !this.user["is_agency_child?"] &&
        0 < this.date_from_today(this.user.trial_ends_at) &&
        this.date_from_today(this.user.trial_ends_at) < 4 &&
        (localStorage.confirmed_trial_will_end_modal_at == null ||
          this.time_add(localStorage.confirmed_trial_will_end_modal_at, 1) <
            this.time_now())
      );
    },

    showTrialEndedAndChangedToFree() {
      return (
        this.user &&
        this.user["trial_ended?"] &&
        !this.user.confirmed_trial_end &&
        this.user.plan.code === "free"
      );
    },

    showPaymentFailed() {
      return (
        !this.isrenderingPageChangePlan &&
        !this.isrenderingPageWithdraw &&
        this.user &&
        this.user["payment_failing?"]
      );
    },

    showPaymentFailedAndChangedToFree() {
      return this.user && this.user.payment_failed;
    },

    showExceededItemLimit() {
      return (
        !this.isrenderingPageChangePlan &&
        !this.isrenderingPageWithdraw &&
        this.user &&
        this.user.is_exceeding_item_limit
      );
    },
  },

  watch: {
    $route() {
      this.isrenderingPagePublic = this.isPublic();
      this.isrenderingPageChangePlan = this.isChangePlan();
      this.isrenderingPageWithdraw = this.isWithdraw();
    },
  },

  methods: {
    isPublic() {
      const currentRoute = this.$router.currentRoute;
      return currentRoute.meta.isPublic === true;
    },

    isChangePlan() {
      const currentRoute = this.$router.currentRoute;
      return currentRoute.meta.isChangePlan === true;
    },

    isWithdraw() {
      const currentRoute = this.$router.currentRoute;
      return currentRoute.meta.isWithdraw === true;
    },

    confirm_trial_will_end_modal() {
      localStorage.confirmed_trial_will_end_modal_at = this.time_now();
      location.reload();
    },
  },
};
</script>
