<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="auto">
        <v-img
          width="85px"
          :src="plan.icon"
          :style="{
            'margin-top': '-65px',
          }"
        />
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col
        cols="auto"
        class="py-0 black--text"
        v-if="plan.code === 'gold_trial'"
      >
        おためし<br />
        1ヶ月限定
      </v-col>

      <v-col cols="auto" class="text-h4 black--text">
        {{ TotalAmount.toLocaleString() }}
        <span class="text-h6">円/月</span>
      </v-col>
    </v-row>

    <v-row justify="center" v-if="TotalAmount">
      <v-col cols="auto" class="pt-0 text-body-2 secondary--text">
        1日あたり約{{ Math.floor(TotalAmount / 30).toLocaleString() }}円
      </v-col>
    </v-row>

    <v-row justify="center" v-if="TotalAmount && percentOff">
      <v-col cols="auto" class="pt-0 text-caption error--text">
        <b>{{ percentOff }}%OFFクーポン適用</b>
      </v-col>
    </v-row>

    <v-row
      justify="center"
      style="min-height: 50px"
      v-if="selectedOptionCodes.length > 0"
    >
      <v-col cols="auto" class="pt-0">
        <v-checkbox
          v-for="plans_option in optionsToDisplay"
          :key="plans_option.option.code"
          v-model="modifiedSelectedOptionCodes"
          :label="`${
            plans_option.option.name
          } +${plans_option.amount.toLocaleString()}円`"
          :value="plans_option.option.code"
          dense
          :disabled="
            user.plan.code == plan.code ||
            plans_option.option.configuration_needed
          "
          hide-details
        >
        </v-checkbox>
      </v-col>
    </v-row>

    <slot name="btn" />

    <v-row justify="center" class="feature" :class="[customClass, plan.code]">
      <v-col cols="auto" class="mb-2">
        <v-row justify="center" v-for="(feature, i) in plan.features" :key="i">
          <v-col cols="auto" class="text-center pb-0">
            <span class="text-caption"> {{ Object.keys(feature)[0] }} </span>
            <br />
            <span class="text-body-1 font-weight-bold">
              {{ Object.values(feature)[0] }}
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <slot name="bottom_contents" />
  </v-container>
</template>

<script>
export default {
  props: {
    user: Object,
    plan: Object,
    percentOff: {
      type: Number,
      default: 0,
    },
    selectedOptionCodes: Array,
    customClass: String,
  },

  computed: {
    modifiedSelectedOptionCodes: {
      get() {
        return this.selectedOptionCodes;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    discountedPrice() {
      return this.plan.amount * ((100 - this.percentOff) / 100);
    },

    optionTotalAmount() {
      let price = 0;

      this.optionsToDisplay.forEach((plans_option) => {
        if (
          this.modifiedSelectedOptionCodes.includes(plans_option.option.code)
        ) {
          price += plans_option.amount;
        }
      });

      return price;
    },

    TotalAmount() {
      return this.discountedPrice + this.optionTotalAmount;
    },

    optionsToDisplay() {
      return this.plan.plans_options.filter((plans_option) => {
        if (plans_option.is_available && plans_option.is_showing) {
          return true;
        }

        return false;
      });
    },
  },
};
</script>

<style scoped>
.feature {
  position: relative;
  align-items: center;
  color: dimgray;
}

.background-text {
  height: 315px;
}

.background-text.gold,
.background-text.platinum,
.background-text.gold_trial {
  background-color: #faf3de; /* 背景色を設定 */
}

.background-text.silver {
  background-color: #fafafa; /* 背景色を設定 */
}

.background-text.gold::before,
.background-text.platinum::before,
.background-text.gold_trial::before {
  content: "\F0E95"; /* 表示したい記号やテキスト */
  font-family: "Material Design Icons";
  font-size: 175px; /* テキストサイズを調整 */
  font-weight: bold;
  color: #f5dfa4; /* テキストの色と透明度を調整 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  pointer-events: none; /* 記号がクリックできないようにする */
}

.background-text.silver::before {
  content: "\F0766"; /* 表示したい記号やテキスト */
  font-family: "Material Design Icons";
  font-size: 175px; /* テキストサイズを調整 */
  font-weight: bold;
  color: #e0e0e0; /* シルバーの記号の色と透明度を調整 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  pointer-events: none; /* 記号がクリックできないようにする */
}

.background-text > * {
  position: relative;
  z-index: 1; /* コンテンツが記号の上に表示されるようにする */
}
</style>
