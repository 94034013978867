<template>
  <v-card class="pa-5">
    <v-row justify="space-between" align="center">
      <v-col cols="auto">
        <v-card-title class="black--text headline">
          <span>非表示商品設定</span>
        </v-card-title>
      </v-col>

      <v-col cols="auto" class="text-right">
        <v-btn
          color="primary"
          @click="updateConfig()"
          :loading="loading"
          :disabled="loading"
        >
          保存する
        </v-btn>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-card-text class="black--text">
      EC-UPシステムに表示しない商品の設定です。
      <br />
      「スーパーセール用商品で、普段はおすすめしたくない商品」
      <br />
      「コピーページで、他の商品の類似商品として出したくない商品」
      <br />
      などにご利用ください。
    </v-card-text>

    <v-card-subtitle class="black--text body-1">
      <span>商品名（部分一致）</span>
    </v-card-subtitle>

    <v-combobox
      class="force-display-palceholder"
      v-model="config.ignoring_item_names"
      multiple
      small-chips
      placeholder="スーパーセール"
      @input="ignoringItemNamesValidation"
      :error-messages="ignoringItemNamesErrorMessage"
      :disabled="loading"
      ref="ignoringItemNames"
    >
    </v-combobox>

    <v-card-subtitle class="black--text body-1">
      <span>商品管理番号（部分一致）</span>
    </v-card-subtitle>

    <v-combobox
      class="force-display-palceholder"
      v-model="config.ignoring_item_codes"
      multiple
      small-chips
      placeholder="ss"
      @input="ignoringItemCodesValidation"
      :error-messages="ignoringItemCodesErrorMessage"
      :disabled="loading"
      ref="ignoringItemCodes"
    ></v-combobox>
  </v-card>
</template>

<script>
export default {
  props: ["config", "loading"],

  data() {
    return {
      ignoringItemNamesErrorMessage: "",
      ignoringItemCodesErrorMessage: "",
    };
  },

  methods: {
    updateConfig() {
      this.$refs.ignoringItemNames.blur();
      this.$refs.ignoringItemCodes.blur();
      this.$emit("updateConfig");
    },
    ignoringItemNamesValidation(e) {
      const lastElement = e.slice(-1)[0];

      if (lastElement) {
        if (!/^[ぁ-んーァ-ンヴー0-9a-zA-Z★☆〇○◎△▽▲▼◇◆♪]*$/.test(lastElement)) {
          this.config.ignoring_item_names.pop();
          this.ignoringItemNamesErrorMessage =
            "半角英数字ひらがなカタカナ、一部記号（★☆〇○◎△▽▲▼◇◆♪）のみ使用できます。";
        } else if (lastElement.length > 255) {
          this.config.ignoring_item_names.pop();
          this.ignoringItemNamesErrorMessage = "最大255文字";
        } else {
          this.ignoringItemNamesErrorMessage = "";
        }
      } else {
        this.ignoringItemNamesErrorMessage = "";
      }
    },

    ignoringItemCodesValidation(e) {
      const lastElement = e.slice(-1)[0];

      if (lastElement) {
        if (!/^[0-9a-zA-Z\-_]*$/.test(lastElement)) {
          this.config.ignoring_item_codes.pop();
          this.ignoringItemCodesErrorMessage =
            "半角英数字、一部記号（-_）のみ使用できます。";
        } else if (lastElement.length > 255) {
          this.config.ignoring_item_codes.pop();
          this.ignoringItemCodesErrorMessage = "最大255文字";
        } else {
          this.ignoringItemCodesErrorMessage = "";
        }
      } else {
        this.ignoringItemNamesErrorMessage = "";
      }
    },
  },
};
</script>
