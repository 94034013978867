<template>
  <modal>
    <v-card class="modal-container">
      <v-card-title class="justify-center">
        お支払いにエラーが発生しました
      </v-card-title>

      <v-card-text class="text-center">
        <p>
          現在有料プランのお支払いにエラーが発生しております。<br />
          下記ボタンより、クレジットカード情報をご確認いただき、<br />
          修正いただく事で、サービスを再開させていただきます。
        </p>

        <p class="text-caption font-weight-bold error--text">
          ※7日間修正されない場合は、フリープランへ変更した事となり、<br />
          設定が削除されます。
        </p>

        <v-row justify="center">
          <v-col cols="6">
            <v-btn
              large
              block
              color="primary"
              @click="showPaymentMethodModal = true"
            >
              確認する
            </v-btn>
          </v-col>
        </v-row>

        <div class="notes mt-5">
          <ul class="caption">
            <li>
              フリープランでは、商品説明文の最後に「Powered by
              EC-UP」のクレジットが表示されます。
            </li>
          </ul>
          <v-row justify="end" class="mt-1">
            <v-col cols="auto">
              <a :href="config('HREF_FAQ')" target="_blank">
                よくある質問Q&A
              </a>
            </v-col>
            <v-col cols="auto">
              <router-link
                :to="{ name: 'Withdraw' }"
                @click.native="$emit('close')"
              >
                退会する
              </router-link>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>

    <payment-method-modal
      v-if="showPaymentMethodModal"
      @close_this_modal="showPaymentMethodModal = false"
    />
  </modal>
</template>

<script>
import Modal from "../components/Modal.vue";
import PaymentMethodModal from "../plan/PaymentMethodModal.vue";
import config from "../../const";

export default {
  components: { Modal, PaymentMethodModal },

  data() {
    return {
      showPaymentMethodModal: false,
    };
  },

  methods: {
    config(value) {
      return config[value];
    },
  },
};
</script>

<style scoped>
.modal-container {
  max-width: 600px;
}

.notes {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}
</style>
