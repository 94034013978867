<template>
  <v-card min-width="380">
    <v-card-text class="pa-5">
      <div class="subtitle-1 font-weight-bold">ログ</div>
      <div
        class="overflow-y-auto"
        v-scroll.self="onScroll"
        style="height: 480px; border: 3px solid #f2f2f2"
      >
        <v-list>
          <v-list-item v-for="(job, i) in jobs" :key="i" class="px-1 mt-3 mx-5">
            <v-list-item-title>
              <span class="subtitle-2 v-align-middle">
                {{ job.display_operation }}
              </span>
              <v-tooltip bottom v-if="job.status == 'finished'">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" class="v-align-middle"
                    >mdi-check
                  </v-icon>
                </template>
                <span>実行済み</span>
              </v-tooltip>

              <v-tooltip bottom v-else>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    class="item_sync_icon v-align-middle"
                    >mdi-autorenew
                  </v-icon>
                </template>
                <span>実行中</span>
              </v-tooltip>

              <div class="text--secondary pr-0 mr-0 text-right" style>
                <span>{{ time_mdt_format(job.created_at) }}</span>
                <span>〜</span>
                <span v-if="job.status == 'finished'">{{
                  time_mdt_format(job.updated_at)
                }}</span>
              </div>

              <table class="ml-auto mr-auto error_nums_table">
                <tr>
                  <td
                    align="right"
                    :class="error_color_class(job.number_of_error_item_csv)"
                  >
                    <v-icon
                      small
                      color="error"
                      v-if="job.number_of_error_item_csv > 0"
                    >
                      mdi-alert-octagon
                    </v-icon>
                    <span> ItemCSV更新中: </span>
                  </td>
                  <td :class="error_color_class(job.number_of_error_item_csv)">
                    <span>
                      {{ job.number_of_error_item_csv.toLocaleString() }}
                    </span>
                  </td>
                  <td
                    align="right"
                    :class="
                      error_color_class(
                        job.number_of_error_exceeding_bytesize_limit
                      )
                    "
                  >
                    <v-icon
                      small
                      color="error"
                      v-if="job.number_of_error_exceeding_bytesize_limit > 0"
                    >
                      mdi-alert-octagon
                    </v-icon>
                    <span> バイト数超過: </span>
                  </td>
                  <td
                    :class="
                      error_color_class(
                        job.number_of_error_exceeding_bytesize_limit
                      )
                    "
                  >
                    <span>
                      {{
                        job.number_of_error_exceeding_bytesize_limit.toLocaleString()
                      }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td
                    align="right"
                    :class="
                      error_color_class(
                        job.number_of_error_exceeding_image_limit
                      )
                    "
                  >
                    <v-icon
                      small
                      color="error"
                      v-if="job.number_of_error_exceeding_image_limit > 0"
                    >
                      mdi-alert-octagon
                    </v-icon>
                    <span> 画像数超過: </span>
                  </td>
                  <td
                    :class="
                      error_color_class(
                        job.number_of_error_exceeding_image_limit
                      )
                    "
                  >
                    {{
                      job.number_of_error_exceeding_image_limit.toLocaleString()
                    }}
                  </td>
                  <td
                    align="right"
                    :class="error_color_class(job.number_of_error_others)"
                  >
                    <v-icon
                      small
                      color="error"
                      v-if="job.number_of_error_others > 0"
                    >
                      mdi-alert-octagon
                    </v-icon>
                    <span> その他: </span>
                  </td>
                  <td :class="error_color_class(job.number_of_error_others)">
                    {{ job.number_of_error_others.toLocaleString() }}
                  </td>
                </tr>
              </table>

              <v-divider></v-divider>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import timeUtilsMixin from "../../../packs/mixins/time_utils";

export default {
  name: "LogCard",
  watch: {
    user: async function (newVal, oldVal) {
      if (newVal && this.jobs.length==0) {
        let error_jobs = await axios.get(`/api/v1/jobs/`);
        this.jobs = error_jobs.data.jobs;
      }
    },
  },
  mixins: [timeUtilsMixin],
  data() {
    return {
      jobs: [],
      scrollInvoked: 0,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  props: [
    "number_of_running_items",
    "number_of_not_running_items",
    "number_of_configuring_items",
    "number_of_error_items",
  ],
  methods: {
    onScroll() {
      this.scrollInvoked++;
    },
    error_color_class(num) {
      if (num == 0) {
        return "color_disabled";
      }
      return "";
    },
  },
};
</script>

<style scoped>
.error_nums_table td:nth-child(2n) {
  padding-right: 10px;
}

.error_nums_table td {
  font-size: 0.8rem;
}

.error_nums_table span {
  vertical-align: middle;
}

.error_nums_table .v-icon {
  vertical-align: middle;
}

.item_sync_icon {
  animation-name: spin;
  animation-duration: 10000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(3360deg);
  }
}

.color_disabled {
  color: #c0c0c0;
}
</style>
