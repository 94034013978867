<template>
  <plan-change
    :user="user"
    :plan="plan"
    :selectedOptionCodes="selectedOptionCodes"
    :googleFormUrl="googleFormUrl"
    @input="selectedOptionCodes = $event"
    v-if="plan"
  >
    <template #plan_confirmation>
      <paid-plan-confirmation
        :user="user"
        :plan="plan"
        :nextPlan="nextPlan"
        :selectedOptionCodes="selectedOptionCodes"
      >
      </paid-plan-confirmation>
    </template>
  </plan-change>
</template>

<script>
import Axios from "axios";
import deepmerge from "deepmerge";
import userUtilsMixin from "../../packs/mixins/user_utils";
import PlanChange from "./components/PlanChange.vue";
import PaidPlanConfirmation from "./components/plan_confirmation/PaidPlanConfirmation.vue";
import { goldPlan, goldTrialPlan } from "../../data/plans";

export default {
  mixins: [userUtilsMixin],
  components: { PlanChange, PaidPlanConfirmation },
  computed: {
    user() {
      return this.$store.state.user;
    },

    googleFormUrl() {
      if (!this.user) {
        return null;
      }

      return `https://docs.google.com/forms/d/e/1FAIpQLSeQrjeE-Z0cxPsoabJ_s-U3ou795M0uvEaa5NOKD1s6LHtbaw/viewform?usp=pp_url&entry.2060341977=${this.user.shop_code}`;
    },

    goldTrialAvailable() {
      return (
        this.user &&
        (this.user["gold_trial_available?"] ||
          this.user.plan.code === "gold_trial")
      );
    },
  },

  watch: {
    plansMaster() {
      this.plan = deepmerge(
        goldTrialPlan,
        this.plansMaster.find((x) => x.code === goldTrialPlan.code)
      );

      this.nextPlan = deepmerge(
        goldPlan,
        this.plansMaster.find((x) => x.code === goldPlan.code)
      );

      this.plan.plans_options.forEach((plans_option) => {
        if (plans_option.is_available && plans_option.is_showing) {
          this.selectedOptionCodes.push(plans_option.option.code);
        }
      });

      // モーダルから来たとき
      if (this.$route.query.selectedOptionCodes) {
        let query = [this.$route.query.selectedOptionCodes].flat();
        this.selectedOptionCodes = this.selectedOptionCodes.filter(
          (selectedOptionCode) => {
            return query.includes(selectedOptionCode);
          }
        );
      }
    },
  },

  data() {
    return {
      plan: null,
      nextPlan: null,
      plansMaster: null,
      selectedOptionCodes: [],
    };
  },

  async created() {
    this.fetch_user();

    if (!this.goldTrialAvailable) {
      this.$router.push({ name: "Root" });
    }

    Axios.get("/api/v1/plans/").then((res) => {
      this.plansMaster = res.data.plans;
    });
  },
};
</script>
