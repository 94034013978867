<template>
  <v-row>
    <v-col
      cols="12"
      md="3"
      sm="6"
      v-for="number_of_item_card in number_of_item_cards"
      :key="number_of_item_card.title"
      class="py-0 mb-7"
    >
      <router-link
        :to="{
          name: 'ItemIndex',
          query: { filter: number_of_item_card.item_index_filter },
        }"
        :class="{
          disabled: !number_of_item_card.item_index_filter,
        }"
      >
        <v-card>
          <v-card-text class="pa-5">
            <div class="subtitle-1 font-weight-bold">
              {{ number_of_item_card.title }}
            </div>
            <div class="d-flex">
              <v-icon
                :color="number_of_item_card.iconcolor"
                class="dashboard_top_status_icon"
                >{{ number_of_item_card.icon }}
              </v-icon>

              <div class="spacer"></div>
              <div style="align-items: flex-end; display: flex">
                <div
                  :class="number_of_item_card.numberclass"
                  style="text-align: right"
                >
                  {{ number_of_item_card.value.toLocaleString() }}
                </div>
                <div class="pl-1">商品</div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </router-link>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "NumberOfItemCards",
  created() {
    this.set_count_by_ecup_status();
  },
  watch: {
    number_of_running_items: function (newVal, oldVal) {
      let vue_instance = this;
      this.number_of_item_cards.forEach((card, i) => {
        if (card.title == "稼働中") {
          vue_instance.$set(
            this.number_of_item_cards[i],
            "value",
            this.number_of_running_items
          );
        }
      });
    },

    number_of_not_running_items: function (newVal, oldVal) {
      let vue_instance = this;
      this.number_of_item_cards.forEach((card, i) => {
        if (card.title == "未稼働") {
          vue_instance.$set(
            this.number_of_item_cards[i],
            "value",
            this.number_of_not_running_items
          );
        }
      });
    },

    number_of_configuring_items: function (newVal, oldVal) {
      let vue_instance = this;
      this.number_of_item_cards.forEach((card, i) => {
        if (card.title == "設定中") {
          vue_instance.$set(
            this.number_of_item_cards[i],
            "value",
            this.number_of_configuring_items
          );
        }
      });
    },

    number_of_error_items: function (newVal, oldVal) {
      let vue_instance = this;
      this.number_of_item_cards.forEach((card, i) => {
        if (card.title == "エラー") {
          vue_instance.$set(
            this.number_of_item_cards[i],
            "value",
            this.number_of_error_items
          );
        }
      });
    },
  },
  data() {
    return {
      number_of_item_cards: [
        {
          title: "稼働中",
          iconcolor: "success",
          numberclass: "display-1",
          icon: "mdi-checkbox-marked-circle",
          value: 0,
          item_index_filter: "running",
        },
        {
          title: "未稼働",
          iconcolor: "#A1A1A1",
          numberclass: "display-1",
          icon: "mdi-check-circle-outline",
          value: 0,
          item_index_filter: "not_running",
        },
        {
          title: "設定中",
          iconcolor: "primary",
          numberclass: "display-1",
          icon: "mdi-autorenew",
          value: 0,
          item_index_filter: null,
        },
        {
          title: "エラー",
          iconcolor: "error",
          numberclass: "display-1 error--text",
          icon: "mdi-alert-octagon",
          value: 0,
          item_index_filter: null,
        },
      ],
    };
  },
  props: [
    "number_of_running_items",
    "number_of_not_running_items",
    "number_of_configuring_items",
    "number_of_error_items",
  ],
  methods: {
    async set_count_by_ecup_status() {
      let vue_instance = this;
      this.number_of_item_cards.forEach((card, i) => {
        switch (card.title) {
          case "稼働中":
            vue_instance.$set(
              this.number_of_item_cards[i],
              "value",
              this.number_of_running_items
            );
            break;
          case "未稼働":
            card.value = this.number_of_not_running_items;
            break;
          case "設定中":
            card.value = this.number_of_configuring_items;
            break;
          case "エラー":
            card.value = this.number_of_error_items;
            break;
        }
      });
    },
  },
};
</script>


<style scoped>
a.disabled {
  pointer-events: none;
  cursor: default;
}
</style>