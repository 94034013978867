<template>
  <div>
    <v-row justify="center">
      <v-col cols="auto">
        <div class="balloon-bottom text-right">
          88%のショップが、おまかせ機能ONで運用中！<br />
          <span class="text-caption">
            ※2024年6月28日時点での集計結果です <br />
            ※後日OFFにすることもできます
          </span>
        </div>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="auto">
        <v-switch
          v-model="isFullAutoOn"
          dense
          inset
          @change="$emit('update:isFullAutoOn', $event)"
        >
          <template v-slot:label>
            <span class="font-weight-bold"> 「おまかせ機能」を利用する </span>
            <span>
              <help-for-full-auto>
                <template v-slot:link>
                  <v-icon class="v-align-middle" color="#B8B8B8">
                    mdi-help-circle
                  </v-icon>
                </template>

                <template v-slot:note>
                  <p>
                    「おまかせ機能」は、EC-UPの運用をAIにおまかせして、設定に必要となる<b>３つの手間をゼロ</b>にする機能です。
                  </p>
                  <br />

                  <p>具体的には、</p>
                  <br />

                  <p>
                    <span class="initial">3つのゼロ！</span><br />
                    <b>1. 新規に設定する手間をゼロ</b><br />
                    EC-UPが設定されていない新商品や、倉庫から出した商品を見つけ、自動的にシステムを設置します。
                  </p>
                  <br />

                  <p>
                    <b>2. 編集後に設定する手間をゼロ</b><br />
                    ショップ側で説明文を編集したとしても、各商品の残バイト、残画像枚数の状況を考慮してシステムを設置します。
                  </p>
                  <br />

                  <p>
                    <b>3. システムを選ぶ・並べる手間をゼロ</b><br />
                    EC-UPは全部で９個のシステムがありますが、システムの種類・並び順・デザイン・優先順位を決めておく事ができます。<br />
                    ※トライアル中・ゴールドプラン以上は変更が可能。
                  </p>
                  <br />

                  <p>
                    可能な限り、EC-UPの運用にかける手間を減らすには、まずおまかせ機能で利用する事を前程に、こだわる商品だけ、商品個別におまかせ機能をOFFにしていただく運用を推奨しています。
                  </p>
                </template>
              </help-for-full-auto>
            </span>
          </template>
        </v-switch>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import HelpForFullAuto from "../../../components/HelpForFullAuto.vue";

export default {
  name: "FullAutoSwitch",

  components: { HelpForFullAuto },

  props: {
    mode: {
      type: String,
      required: true,
      validator: function (value) {
        // プロパティの値は、必ずいずれかの文字列でなければならない
        return ["active", "not_yet", "fetching_items"].indexOf(value) !== -1;
      },
    },
    isFullAutoOn: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped>
.balloon-bottom {
  position: relative;
  padding: 10px 15px;
  color: white;
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  background-color: #ff5100;
  border-radius: 7px;
  top: 5px;
}

/* afterで三角を表現 */
.balloon-bottom::after {
  content: "";
  position: absolute;
  left: 165px;
  bottom: -15px;
  display: block;
  width: 0;
  height: 0;
  border-right: 30px solid transparent;
  border-top: 30px solid #ff5100;
  border-left: 30px solid transparent;
}

.initial {
  font-size: initial;
  font-weight: bold;
}
</style>
