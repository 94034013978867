<template>
  <plan-change
    :user="user"
    :plan="plan"
    :selectedOptionCodes="selectedOptionCodes"
    :googleFormUrl="googleFormUrl"
    @input="selectedOptionCodes = $event"
    v-if="plan"
  >
    <template #upsell_card>
      <div class="ma-5">
        <v-btn
          x-large
          block
          dark
          color="#CCA83F"
          height="110px"
          @click="$router.push({ name: 'ChangeToGoldPlan' })"
          v-if="availableGold"
        >
          <div>
            <span class="text-h5">
              スマホの売上を<br />
              最大化しましょう！
            </span>
            <br />
            <span class="text-body-2 text-decoration-underline">
              ゴールドプランを確認する
            </span>
          </div>
        </v-btn>
      </div>
    </template>

    <template #plan_confirmation>
      <paid-plan-confirmation
        :user="user"
        :plan="plan"
        :percentOff="percentOff"
        :selectedOptionCodes="selectedOptionCodes"
      >
      </paid-plan-confirmation>
    </template>
  </plan-change>
</template>

<script>
import Axios from "axios";
import deepmerge from "deepmerge";
import userUtilsMixin from "../../packs/mixins/user_utils";
import PlanChange from "./components/PlanChange.vue";
import PaidPlanConfirmation from "./components/plan_confirmation/PaidPlanConfirmation.vue";
import { silverPlan } from "../../data/plans";

export default {
  mixins: [userUtilsMixin],
  components: { PlanChange, PaidPlanConfirmation },
  computed: {
    user() {
      return this.$store.state.user;
    },

    availableGold() {
      return (
        this.user &&
        this.user.plan.code !== "gold" &&
        this.user.plan.code !== "gold_trial" &&
        this.user.items_count < this.maxNumOfItemsForGold
      );
    },

    percentOff() {
      if (!this.user) {
        return 0;
      }

      return 0 < this.user.my_available_off_50_coupons_count
        ? 50
        : 0 < this.user.my_available_off_10_coupons_count
        ? 10
        : 0;
    },

    googleFormUrl() {
      if (!this.user) {
        return null;
      }

      switch (this.user.plan.code) {
        case "trial":
        case "free":
          // 1
          return `https://docs.google.com/forms/d/e/1FAIpQLSfPcdRXKVb69RH0VH_e329MYwwS-1drbDF-jSh44YiOcfcv2A/viewform?usp=pp_url&entry.2060341977=${this.user.shop_code}`;
        case "gold":
        case "platinum":
          // 6
          return `https://docs.google.com/forms/d/e/1FAIpQLSc2l3mCngSBmfp-lZMfj1wfcZsjZttLNVsMqWB2Z8nKvTSF2A/viewform?usp=pp_url&entry.2060341977=${this.user.shop_code}`;
      }
    },
  },

  watch: {
    plansMaster() {
      this.plan = deepmerge(
        silverPlan,
        this.plansMaster.find((x) => x.code === silverPlan.code)
      );

      this.plan.plans_options.forEach((plans_option) => {
        if (plans_option.is_available && plans_option.is_showing) {
          this.selectedOptionCodes.push(plans_option.option.code);
        }
      });

      // モーダルから来たとき
      if (this.$route.query.selectedOptionCodes) {
        let query = [this.$route.query.selectedOptionCodes].flat();
        this.selectedOptionCodes = this.selectedOptionCodes.filter(
          (selectedOptionCode) => {
            return query.includes(selectedOptionCode);
          }
        );
      }
    },
  },

  data() {
    return {
      plan: null,
      plansMaster: null,
      maxNumOfItemsForGold: 20000,
      selectedOptionCodes: [],
    };
  },

  async created() {
    this.fetch_user();

    Axios.get("/api/v1/plans/").then((res) => {
      this.plansMaster = res.data.plans;
    });
  },
};
</script>
