<template>
  <v-app id="app" app-data="true" :style="{ background: '#EEEEEE' }">
    <Modals />
    <sidebar v-if="render_sub_navigations && user && user.use_dashboard"></sidebar>
    <v-main v-if="isIsolatedStyle">
      <router-view></router-view>
    </v-main>
    <v-main v-else>
      <ec-ai-header v-if="render_sub_navigations && user && user.use_dashboard"></ec-ai-header>
      <v-container fluid class="py-8 px-12" style="max-width: 1300px">
        <v-row v-if="render_alert && user" class="mb-6">
          <v-col>
            <v-alert outlined type="success" text v-if="(user.plan.code == 'free' || user.plan.code == 'trial') &&
              user.can_revive
              ">
              <div class="mb-2 font-weight-bold">
                {{ user.name }}様に特別なご案内を差し上げております。
              </div>
              <div class="body-2 black--text">
                {{
                  time_ymd_format(user.created_at)
                }}にEC-UPのご利用を開始していただきました。
              </div>
              <div class="body-2 black--text">
                その後、システムの追加や、EC-UPの設定作業すら不要になるおまかせ機能をリリースし、進化を続けております。
              </div>
              <div class="body-2 black--text">
                新しいEC-UPを是非ご体験いただきたいという願いから、再度30日間の無料トライアルのご利用を提案させていただきます。
              </div>
              <div class="body-2 black--text">
                ご希望の場合は、<a class="text-decoration-underline" target="_blank"
                  href="https://forms.gle/gSPzj3UvMDEwHgyg8">フォーム</a>の送信をお願いいたします。
                その後、弊社よりご登録いただいたメールアドレス宛にご案内させていただきます。
              </div>
            </v-alert>

            <ec-up-alert class="body-2 mb-4" v-if="!user.is_lisence_key_active" mode="error">
              <span>
                ライセンスキーの有効期限が切れ、EC-UPの更新が一時停止しています。ダッシュボードの"ライセンスキー"から更新をお願いします。
              </span>
            </ec-up-alert>

            <ec-up-alert class="body-2 mx-3" v-for="alert in alerts" :key="alert.id" :mode="alert.mode">
              <span v-html="alert.content_html"></span>
            </ec-up-alert>
          </v-col>
        </v-row>

        <router-view></router-view>

        <v-row v-if="render_footer" class="mt-10">
          <v-col class="pa-0">
            <ec-up-footer></ec-up-footer>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
import EcAiHeader from "./view/components/EcAiHeader.vue";
import Sidebar from "./view/components/Sidebar.vue";
import EcUpFooter from "./view/components/EcUpFooter.vue";
import Modals from "./view/modals/Index.vue";
import EcUpAlert from "./view/components/alert/EcUpAlert.vue";
import timeUtilsMixin from "./packs/mixins/time_utils";

export default {
  components: {
    EcAiHeader,
    Sidebar,
    EcUpFooter,
    Modals,
    EcUpAlert,
  },
  mixins: [timeUtilsMixin],

  watch: {
    user: async function (newVal, oldVal) {
      if (newVal) {
        let alert_responce = await axios.get(`/api/v1/alerts`);
        this.alerts = alert_responce.data.alerts;
      }
    },
    $route() {
      this.render_sub_navigations = !(
        this.isPublic() ||
        this.isChangePlan() ||
        this.isSubWindow()
      );
      this.render_alert = !(
        this.isPublic() ||
        this.isChangePlan() ||
        this.isSubWindow()
      );
      this.render_footer = !(this.isChangePlan() || this.isSubWindow());
    },
    userDetails: function(newVal, oldVal) {
      this.$store.commit('SET_USER_DETAILS', newVal);
      this.executeCodeForOnboardingTool();
    },
  },

  data() {
    return {
      alerts: [],
      render_sub_navigations: false,
      render_alert: false,
      render_footer: true,
      starts_at: null, // メンテナンス開始日時
      ends_at: null,   // メンテナンス終了日時
    };
  },

  created() {
    this.loadMaintenancePeriod();
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    userDetails() {
      return {
        uuid: this.user?.shop_code,
        companyAndUserName: this.user?.name,
        email: this.user?.email,
        planName: this.user?.plan?.name,
      }
    },
    isIsolatedStyle() { // デザイン面で影響を受けないページかを判定
      return this.$route.meta.isIsolatedStyle || false;
    },
  },

  methods: {
    isPublic() {
      const currentRoute = this.$router.currentRoute;
      return currentRoute.path === "/" || currentRoute.meta.isPublic === true;
    },

    isChangePlan() {
      const currentRoute = this.$router.currentRoute;
      return currentRoute.meta.isChangePlan === true;
    },

    isSubWindow() {
      const currentRoute = this.$router.currentRoute;
      return currentRoute.meta.isSubWindow === true;
    },

    // メンテナンス期間を読み込む
    async loadMaintenancePeriod() {
      try {
        const response = await axios.get("/api/v1/maintenance/setting_period");
        const data = response.data;

        // メンテナンス期間をセット
        this.starts_at = new Date(data.starts_at);
        this.ends_at = new Date(data.ends_at);

        // メンテナンス期間を確認
        this.checkMaintenancePeriod();
      } catch (error) {
        console.error("メンテナンス期間の取得に失敗しました", error);
      }
    },

    // メンテナンス期間を確認する
    checkMaintenancePeriod() {
      // 現在の日時を取得
      const currentTime = new Date();

      // 現在の日時がメンテナンス期間内かどうかを判定
      if (currentTime >= this.starts_at && currentTime <= this.ends_at) {
        // 現在のページが "Maintenance" でない場合にのみリダイレクト
        if (this.$route.name !== "Maintenance") {
          this.$router.push({ name: "Maintenance" });
        }
      }
    },

    executeCodeForOnboardingTool() {
      if(!(document.getElementById('fullstar'))){
        const fsScript = document.createElement('script');
        fsScript.id = "fullstar"; 
        fsScript.textContent = `
          var param_user_uuid = '${this.userDetails.uuid}'
          var param_user_name = '${this.userDetails.companyAndUserName}'
          var param_user_company = '${this.userDetails.companyAndUserName}'
          var param_user_email = '${this.userDetails.email}'
          var param_user_plan = '${this.userDetails.planName}'
            fs.clear();
            fs.show("43369013-cbe0-4e6b-ab92-fd820c42a234", param_user_uuid, {
              company_name: param_user_name,
              user_name: param_user_company,
              user_email: param_user_email,
              plan_name: param_user_plan
          });
          fs.tracking();
          fs.survey();
          fs.guide();
          fs.checklist();
          fs.tooltip();
        `;
        document.head.appendChild(fsScript);
      }
    },
  },
};
</script>

<style scoped>
p {
  font-size: 2em;
  text-align: center;
}
</style>

<style>
.v-align-middle {
  vertical-align: middle;
}

a {
  text-decoration: none;
}

.grey-background {
  background-color: #eeeeee;
}

.text-decoration-underline {
  text-decoration: underline;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.ecup_green_text {
  color: #86b82c;
}
</style>
