<template>
  <v-alert dense class="mb-1" :color="background_color">
    <v-icon class="mr-2" :color="icon_color">{{ icon }}</v-icon>
    <slot></slot>
  </v-alert>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      default: "error",
      validator: function (value) {
        return ['error', 'alert', 'notice', 'info'].indexOf(value) !== -1
      }
    },
  },
  computed: {
    icon() {
      switch (this.mode) {
        case "error":
          return "mdi-alert-octagon";
        case "alert":
          return "mdi-alert";
        case "notice":
          return "mdi-checkbox-marked";
        case "info":
          return "mdi-information";
      }
    },
    icon_color() {
      switch (this.mode) {
        case "error":
          return "error";
        case "alert":
          return "orange";
        case "notice":
          return "light-green";
        case "info":
          return "info";
      }
    },
    background_color() {
      switch (this.mode) {
        case "error":
          return "red lighten-3";
        case "alert":
          return "orange lighten-3";
        case "notice":
          return "#DAE2B9";
        case "info":
          return "light-blue lighten-4";
      }
    },
  },
};
</script>
