<template>
  <ecup-modal
    title="確認事項"
    title_center="true"
    modal_width="600px"
    @close_this_modal="$emit('close_this_modal')"
  >
    <div class="pt-5">
      <p>オプション機能「{{ option.name }}」の利用を解除します。</p>

      <div v-if="option.code === 'for_pc'">
        <p>
          <b>▼オプション機能「{{ option.name }}」の利用の解除をご希望の場合</b>
          <br />
          　→<v-btn depressed small readonly color="secondary">
            解除する
          </v-btn>
          をクリックしてください。<br />
        </p>

        <p>
          <b
            >▼おまかせ機能をOFFにし、商品ごとにEC-UPのシステムを設定したい場合</b
          >
          <br />
          　→<v-btn depressed small readonly color="primary">
            おまかせ機能設定
          </v-btn>
          をクリックしてください。
        </p>

        <p>
          おまかせ機能 ON / OFF 設定画面が開きます。<br />
          そちらの画面でおまかせ機能をOFFに設定し、商品毎にご希望のEC-UPのシステムを設定してください。<br />
          この操作ではオプション機能「{{
            option.name
          }}」の利用は解除されません。
        </p>
      </div>
    </div>

    <div class="text-center grey lighten-4 px-6 py-4 mx-n6 mb-n6">
      <v-btn
        color="primary"
        class="mx-2"
        large
        min-width="160px"
        @click="clickFullAutoConfig()"
        v-if="option.code === 'for_pc' && !isAdminShop"
      >
        おまかせ機能設定
      </v-btn>

      <v-btn
        color="secondary"
        class="mx-2"
        large
        :loading="loading"
        @click="
          loading = true;
          $emit('off');
        "
      >
        解除する
      </v-btn>
    </div>
  </ecup-modal>
</template>

<script>
import EcupModal from "../../components/EcupModal.vue";

export default {
  props: {
    option: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
    isAdminShop: {
      type: Boolean,
      default: false,
    },
  },
  components: { EcupModal },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    clickFullAutoConfig() {
      this.$emit("closeAllModal");
      this.$router.push({ name: "ConfigFullAuto" }).catch((err) => err);
    },
  },
};
</script>
