<template>
  <ecup-modal
    title="サマリーメール利用手順（後半）：EC-UP用利用者の権限設定"
    @close_this_modal="$emit('close_this_modal')"
  >
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1"> </v-stepper-step>

        <v-divider></v-divider>
        <v-stepper-step :complete="e1 > 2" step="2"> </v-stepper-step>

        <v-divider></v-divider>
        <v-stepper-step :complete="e1 > 3" step="3"> </v-stepper-step>

        <v-divider></v-divider>
        <v-stepper-step :complete="e1 > 4" step="4"> </v-stepper-step>

        <v-divider></v-divider>
        <v-stepper-step step="5"> </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <p>作成したEC-UP用利用者の権限の設定を行います。</p>

          <p>
            <b> <u>下記ボタン</u> よりR-Login </b>
            に
            <b> <u>管理者権限のあるアカウントでログイン</u> </b>
            し、設定を進めてください。
          </p>

          <v-row justify="center">
            <v-col cols="auto">
              <v-btn
                rounded
                height="100"
                color="#102040"
                class="white--text text-h4 font-weight-bold"
                href="https://glogin.rms.rakuten.co.jp"
                target="_blank"
                style="text-transform: none"
              >
                R-Loginを開く
              </v-btn>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="auto" class="error--text text-body-2">
              通常、編集作業等を行うRMSとは別になります。<br />利用スタッフ管理などを行うため、「R-Login」へのアクセスが必要です。
            </v-col>
          </v-row>

          <v-checkbox v-model="isRmsLogin" label="R-Loginにログインしました">
          </v-checkbox>

          <v-row justify="center">
            <v-col cols="auto">
              <v-btn
                color="primary"
                class="ma-2"
                @click="e1 = 2"
                :disabled="!isRmsLogin"
              >
                次へ
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="2">
          <p>
            「利用者申請の承認が要求されています」と表示されるので、クリックします。
          </p>

          <v-row justify="center">
            <v-col cols="auto">
              <v-img
                :src="`/images/options/summary_mail/approve_auth_ecup_staff/02.png`"
              ></v-img>
            </v-col>
          </v-row>

          <v-checkbox v-model="selectedUserManagement" label="クリックしました">
          </v-checkbox>

          <v-row justify="center" class="ma-1">
            <v-col cols="auto">
              <v-btn class="ma-2" @click="e1 = 1"> 戻る </v-btn>
              <v-btn
                color="primary"
                class="ma-2"
                @click="e1 = 3"
                :disabled="!selectedUserManagement"
              >
                次へ
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="3">
          <p>
            承認待ちとなっている「ECデータバンク 株式会社」をクリックします。
          </p>

          <v-row justify="center">
            <v-col cols="auto">
              <v-img
                :src="`/images/options/summary_mail/approve_auth_ecup_staff/03.png`"
              ></v-img>
            </v-col>
          </v-row>

          <v-checkbox v-model="selectedEcdb" label="クリックしました">
          </v-checkbox>

          <v-row justify="center" class="ma-1">
            <v-col cols="auto">
              <v-btn class="ma-2" @click="e1 = 2"> 戻る </v-btn>
              <v-btn
                color="primary"
                class="ma-2"
                @click="e1 = 4"
                :disabled="!selectedEcdb"
              >
                次へ
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="4">
          <p>
            画面のように権限設定をし、入力内容の確認 ＞ 変更する
            と進んでください。 <br />
            登録番号 / 所属は変更いただけます。<br />
            有効期限は短期間で切れなければいつまででもかまいません。
          </p>

          <v-row justify="center">
            <v-col cols="auto">
              <v-img
                :src="`/images/options/summary_mail/approve_auth_ecup_staff/04.gif`"
              ></v-img>
            </v-col>
          </v-row>

          <v-checkbox
            v-model="permissionSetting"
            label="入力内容の確認 ＞ 変更する として設定しました"
          >
          </v-checkbox>

          <v-row justify="center" class="ma-1">
            <v-col cols="auto">
              <v-btn class="ma-2" @click="e1 = 3"> 戻る </v-btn>
              <v-btn
                class="ma-2"
                color="primary"
                @click="e1 = 5"
                :disabled="!permissionSetting"
              >
                次へ
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="5">
          <div v-if="result">
            <v-row justify="center">
              <v-col cols="auto">
                <span class="success--text font-weight-bold">
                  ID・パスワードの認証に成功しました！
                </span>
              </v-col>
            </v-row>

            <v-row justify="center" v-if="result">
              <v-col cols="auto">
                <v-btn
                  large
                  color="primary"
                  class="ma-2"
                  @click="$emit('complete')"
                  :loading="completeLoading"
                >
                  サマリーメールをONにする
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="auto">
                <p>
                  本日もしくは翌日からサマリーメールが送信されますので、楽しみにお待ちください。<br />
                  R-Loginパスワードの有効期限が切れた場合には、ダッシュボード右上のR-Loginパスワードより変更をお願いします。<br />
                  メールでも有効期限についてご案内いたします。
                </p>
              </v-col>
            </v-row>
          </div>

          <div v-else>
            <p>
              利用者の権限設定が完了しました。
              R-Loginのウインドウを閉じてください。
            </p>

            <p>
              最後に、下のフォームよりRMSへログインする際に利用するショップのR-Login
              IDの認証（共通ID）を送信してください。<br />
              こちらで設定は完了となります。
            </p>

            <v-row>
              <v-col cols="auto">
                <v-img
                  :src="`/images/options/summary_mail/approve_auth_ecup_staff/05.png`"
                ></v-img>
              </v-col>
            </v-row>

            <v-row>
              <v-col offset="1" cols="4" align-self="center" class="text-h6">
                R-LoginID
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="rmsShopId"
                  label="R-Login IDを入力"
                  outlined
                  hide-details="auto"
                  filled
                  :disabled="result"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col offset="1" cols="4" align-self="center" class="text-h6">
                R-Loginパスワード
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="rmsShopPw"
                  label="パスワードを入力"
                  outlined
                  hide-details="auto"
                  filled
                  :disabled="result"
                  :type="show ? 'text' : 'password'"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show = !show"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="result === false">
              <v-col cols="auto">
                <p class="error--text">
                  連携に失敗しました。ID /
                  パスワードが間違っているか、連携設定に失敗している可能性があります。再度ご確認をお願いします。
                </p>
              </v-col>
            </v-row>

            <v-row justify="center">
              <v-col cols="auto">
                <v-btn class="ma-2" @click="e1 = 4"> 戻る </v-btn>
                <v-btn
                  color="primary"
                  class="ma-2"
                  @click="sendRmsLogin()"
                  :disabled="!(rmsShopId && rmsShopPw) || loading"
                  :loading="loading"
                >
                  送信
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <!-- ローディング画面 -->
    <template v-if="loading">
      <v-overlay>
        <div class="text-center text-h5">ID・パスワードを確認中です…</div>
        <div class="text-center mt-15">
          <v-progress-circular
            :size="70"
            :width="7"
            color="white"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-overlay>
    </template>
  </ecup-modal>
</template>

<script>
import axios from "axios";
import EcupModal from "../../../components/EcupModal.vue";

export default {
  name: "ApproveAuthEcup",

  components: { EcupModal },

  props: {
    user: {
      type: Object,
      default: {},
    },

    isAdminShop: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      e1: 1,
      isRmsLogin: false,
      selectedUserManagement: false,
      selectedEcdb: false,
      permissionSetting: false,
      rmsShopId: "",
      rmsShopPw: "",
      loading: false,
      completeLoading: false,
      result: null,
      show: false,
    };
  },

  methods: {
    async sendRmsLogin() {
      let url = this.isAdminShop ? "/api/v1/jobs/admin_create" : "/api/v1/jobs";

      this.loading = true;

      const data = {
        shop_code: this.user.shop_code,
        operation: "rms_login_check",
        rms_shop_id: this.rmsShopId,
        rms_shop_pw: this.rmsShopPw,
      };

      const res = await axios.post(url, data);

      if (res.status === 201) {
        console.log("created!");

        let count = 0;
        let status = "waiting";
        const id = res.data.id;

        url = this.isAdminShop ? "/api/v1/jobs/admin_show/" : "/api/v1/jobs/";

        while (count < 25 && ["waiting", "operating"].includes(status)) {
          console.log("waiting...");
          await new Promise((resolve) => setTimeout(resolve, 5000));

          let response = await axios.get(url + id, {
            params: { shop_code: this.user.shop_code },
          });
          status = response.data.status.status;
          count++;
        }

        this.loading = false;
        this.result = status === "finished" ? true : false;
      }
    },
  },
};
</script>
