import dayjs from "dayjs";

export default {
  methods: {
    time_ymd_format: function (value) {
      if (!value) { return null }
      return dayjs(value).format("YYYY/MM/DD");
    },
    time_mdt_format: function (value) {
      return dayjs(value).format("MM/DD HH:mm");
    },
    time_ymdt_format: function (value) {
      if (!value) { return null }
      return dayjs(value).format("YYYY/MM/DD HH:mm");
    },
    time_now: function () {
      return dayjs();
    },
    convert_to_time: function(time_str){
      return dayjs(time_str);
    },
    time_diff: function (date1, date2) {
      return date1.diff(date2, "day");
    },
    time_add: function (time, add_date) {
      return dayjs(time).add(add_date, "day");
    },
    month_add: function (time, add_month) {
      if (!time) { return null }
      return dayjs(time).add(add_month, "month");
    },
    date_from_today(target_date_str) {
      return this.time_diff(
        dayjs(target_date_str).startOf("day"),
        this.time_now().startOf("day")
      );
    },
  },
};
