<template>
  <div class="mx-auto" style="max-width: 660px">
    <v-row no-gutters v-if="isShow" align="center">
      <v-col cols="6">
        <apexchart
          type="donut"
          height="200px"
          :options="options"
          :series="series"
        ></apexchart>
      </v-col>
      <v-col cols="6" class="mt-n10">
        <div class="text-h6 grey--text text--darken-2 font-weight-bold pb-4">
          {{ effectType }}
        </div>
        <div class="black--text" v-html="discription" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
const effectType = "スマホ商品ページバイト数有効活用率";
export default {
  name: "SpBytesize",
  data() {
    return {
      isShow: false,
      effectType: "",
      discription: "",
      series: [],
      options: {
        labels: ["EC-UP", "商品情報", "残りbyte"],
        dataLabels: {
          enabled: false,
        },
        grid: {
          padding: {
            left: 0,
            right: 0,
          },
          borderColor: "rgba(0,0,0,0.1)",
        },
        plotOptions: {
          pie: {
            donut: {
              size: "82%",
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "18px",
                  color: "#99abb4",
                  offsetY: -10,
                },
                value: {
                  show: true,
                  color: "#99abb4",
                  formatter: function (val) {
                    return val + "%";
                  },
                },
                total: {
                  show: true,
                  label: "EC-UP",
                  color: "#99abb4",
                  formatter: function (val) {
                    return "%";
                  },
                },
              },
            },
          },
        },
        stroke: {
          width: 1,
          colors: "rgba(0,0,0,0.1)",
        },
        legend: {
          show: false,
        },
        colors: ["#92b125", "#2286C1", "#EEEEEE"],
        tooltip: {
          fillSeriesColor: false,
          enabled: false,
        },
      },
    };
  },
  created() {
    const val = this.$store.getters.analysis_data(effectType);
    if (val) {
      this.setData(val);
    }
  },
  watch: {
    analysisData(newVal) {
      if (newVal) {
        this.setData(newVal);
      }
    },
  },
  computed: {
    analysisData() {
      return this.$store.getters.analysis_data(effectType);
    },
  },
  methods: {
    setData(val) {
      const data = val.data;
      const totalBytes =
        data.bytesyze_of_description_sp_used_by_ecup +
        data.bytesyze_of_description_sp_used_by_shop +
        data.bytesyze_of_description_sp_remaining;
      const bytesizeOfDescriptionSpUsedByEcupPercentage =
        Math.round(
          (data.bytesyze_of_description_sp_used_by_ecup * 1000) / totalBytes
        ) / 10;

      const bytesizeOfDescriptionSpUsedByShopPercentage =
        Math.round(
          (data.bytesyze_of_description_sp_used_by_shop * 1000) / totalBytes
        ) / 10;

      const bytesizeOfDescriptionSpRemainingPercentage =
        Math.round(
          (data.bytesyze_of_description_sp_remaining * 1000) / totalBytes
        ) / 10;
      this.series = [
        bytesizeOfDescriptionSpUsedByEcupPercentage,
        bytesizeOfDescriptionSpUsedByShopPercentage,
        bytesizeOfDescriptionSpRemainingPercentage,
      ];

      this.options.plotOptions.pie.donut.labels.total.formatter = function (
        val
      ) {
        return (
          Math.round(
            (data.bytesyze_of_description_sp_used_by_ecup * 1000) / totalBytes
          ) /
            10 +
          "%"
        );
      };

      this.effectType = "スマホ用商品説明文バイト数有効活用率";
      this.discription = `EC-UP利用前の使用率が${bytesizeOfDescriptionSpUsedByShopPercentage}%でした。<br>EC-UPが${bytesizeOfDescriptionSpUsedByEcupPercentage}%有効活用しました。`;
      this.isShow = true;
    },
  },
};
</script>

<style scoped>
.v-window__container {
  padding-bottom: 70px;
}
</style>