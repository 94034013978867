<template>
  <ecup-modal modal_width="700px" :force="true">
    <v-card flat>
      <v-card-title class="justify-center text-center">
        商品数が{{ itemLimit }}万商品を超えたため<br />
        更新を停止しました
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <p>
              <span v-if="user.plan.code === 'trial'">トライアル期間</span
              ><span v-else>ご契約中の{{ user.plan.name }}プラン</span
              >における商品数の上限ですが【{{
                itemLimit
              }}万商品】となっております。<br />
              現在、ご登録いただきましたショップ内の商品数が{{
                itemLimit
              }}万商品を超えたため、<br />
              更新を停止させていただいている状況でございます。
            </p>

            <p class="font-weight-bold">
              <span class="error--text" v-if="user.plan['paid?']">
                更新が停止したまま課金を継続してしまいますので、
              </span>
              下記<span v-if="user.plan['paid?']">いずれか</span
              >の対応をお願いいたします。
            </p>
          </v-col>
        </v-row>

        <ChangeToGold class="mb-9" v-if="user.plan.code === 'silver'" :isUnderPartnersShop="isAgencyShopPresent" />
        <ChangeToPlatinum class="mb-9" v-else-if="user.plan.code === 'gold'" :isUnderPartnersShop="isAgencyShopPresent" />
        <ReduceItems class="mb-9" :itemLimit="itemLimit" />
        <InfoPaidPlans class="mb-9" v-if="!user.plan['paid?']" />

        <v-row justify="end">
          <v-col cols="auto">
            <router-link
              :to="{ name: 'Withdraw' }"
              @click.native="$emit('close')"
            >
              退会する
            </router-link>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </ecup-modal>
</template>

<script>
import Modal from "../components/Modal.vue";
import timeUtilsMixin from "../../packs/mixins/time_utils";
import EcupModal from "../components/EcupModal.vue";
import ChangeToGold from "./exceeded_item_limit/ChangeToGold.vue";
import ChangeToPlatinum from "./exceeded_item_limit/ChangeToPlatinum.vue";
import ReduceItems from "./exceeded_item_limit/ReduceItems.vue";
import InfoPaidPlans from "./exceeded_item_limit/InfoPaidPlans.vue";

export default {
  components: {
    Modal,
    EcupModal,
    ChangeToGold,
    ChangeToPlatinum,
    ReduceItems,
    InfoPaidPlans,
  },
  mixins: [timeUtilsMixin],

  computed: {
    user() {
      return this.$store.state.user;
    },

    itemLimit() {
      return this.user.plan.code === "silver" ? 1 : 2;
    },

    isAgencyShopPresent() {
      return !!this.user.agency_shop;
    }
  },

  methods: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
.v-card__title {
  font-size: 1.5em;
  font-weight: 700;
}

.v-card__text {
  margin-top: 12px;
}

>>> .header {
  background-color: #92b126;
}

>>> .body {
  border: solid 2px #92b126;
}
</style>
