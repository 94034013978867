<template>
  <v-row justify="center">
    <v-col cols="auto">
      <v-carousel show-arrows-on-hover hide-delimiters height="420">
        <v-carousel-item
          v-for="(item, i) in items"
          :key="i"
          :src="item.src"
        ></v-carousel-item>
      </v-carousel>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          src: "/images/items/procedure/multiple_items/01.gif",
        },
        {
          src: "/images/items/procedure/multiple_items/02.png",
        },
        {
          src: "/images/items/procedure/multiple_items/03.png",
        },
        {
          src: "/images/items/procedure/multiple_items/04.png",
        },
        {
          src: "/images/items/procedure/multiple_items/05.png",
        },
        {
          src: "/images/items/procedure/multiple_items/06.png",
        },
        {
          src: "/images/items/procedure/multiple_items/07.png",
        },
        {
          src: "/images/items/procedure/multiple_items/08.png",
        },
        {
          src: "/images/items/procedure/multiple_items/09.png",
        },
        {
          src: "/images/items/procedure/multiple_items/10.png",
        },
      ],
    };
  },
};
</script>
<style scoped></style>
