<template>
  <Main
    :descriptionKey="descriptionKey"
    :fullAutoColumnName="fullAutoColumnName"
  />
</template>

<script>
import Main from "./Main.vue";

export default {
  name: "BySalesMethod",
  components: { Main },
  data() {
    return {
      descriptionKey: "BySalesMethod",
      fullAutoColumnName: "is_full_auto_on_BySalesMethod",
    };
  },
};
</script>
