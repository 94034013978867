<template>
  <v-card
    class="pa-2 d-inline-block text-left d-flex"
    style="white-space: nowrap"
  >
    <div class="mt-n2 mr-2">
      <span style="font-size: 0.7rem">ライセンスキー</span>
      <v-img
        contain
        class="ml-1"
        color="success"
        max-width="18"
        src="/images/lisence_key.png"
      ></v-img>
    </div>
    <div v-if="this.lisence_key_expired_at">
      <div v-if="!expired" class="ml-n6 mr-4">
        <span class="caption">あと</span
        ><span class="text-h4">{{ remaining_license_key_valid_date }}</span
        ><span class="caption">日</span>
      </div>
      <div v-else class="mr-4 d-flex align-end ml-n6">
        <span class="body-1 red--text text--darken-1 font-weight-bold"
          >期限切れです</span
        >
      </div>
    </div>
    <div v-else>期限なし</div>
    <div class="d-flex align-center">
      <v-btn
        color="primary"
        class="ml-4"
        @click="send_rms_secret_modal = !send_rms_secret_modal"
        >変更
      </v-btn>
    </div>

    <!-- モーダル -->
    <ecup-modal
      v-if="send_rms_secret_modal"
      title="ライセンスキーの変更"
      modal_width="775px"
      @close_this_modal="send_rms_secret_modal = false"
    >
      <send-rms-secret
        :get_secret="false"
        :allow_expired_at_date_null="true"
        @lisence_key_update_succeed="
          fetch_user();
          send_rms_secret_modal = false;
        "
      >
      </send-rms-secret>
    </ecup-modal>
  </v-card>
</template>

<script>
import timeUtilsMixin from "../../../packs/mixins/time_utils";
import UserUtilsMixin from "../../../packs/mixins/user_utils";
import EcupModal from "../../components/EcupModal.vue";
import SendRmsSecret from "../../components/SendRmsSecret.vue";
import config from "../../../const";

export default {
  name: "LicenseKeyCard",
  props: ["lisence_key_expired_at"],
  mixins: [timeUtilsMixin, UserUtilsMixin],
  components: { EcupModal, SendRmsSecret },
  data() {
    return {
      send_rms_secret_modal: false,
      expired: false,
    };
  },
  created() {
    if (this.remaining_license_key_valid_date < 1) {
      this.expired = true;
    }
  },
  computed: {
    remaining_license_key_valid_date: function () {
      return this.date_from_today(this.lisence_key_expired_at);
    },
    user() {
      return this.$store.state.user;
    },
    lisence_key_nearly_expired() {
      if (
        0 <= this.remaining_license_key_valid_date &&
        this.remaining_license_key_valid_date < 10
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    config(value) {
      return config[value];
    },
  },
};
</script>
