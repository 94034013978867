<template>
  <ecup-modal
    :title="
      user.plan.code == 'trial'
        ? 'プラン変更：トライアル期間中はゴールドプランと同等の内容をご利用いただいておりました'
        : 'プラン変更'
    "
    @close_this_modal="$emit('close_this_modal')"
    modal_width="680px"
  >
    <v-container>
      <Plans
        v-if="plans"
        :plans="plans"
        :selectedOptionCodes="selectedOptionCodes"
        :isPlatinum="isPlatinum"
        class="mb-6"
      />
      <ChangeToFreeButton v-if="freeAvailable" />
      <DetailButton />
      <FAQButton />
    </v-container>
  </ecup-modal>
</template>

<script>
import Axios from "axios";
import deepmerge from "deepmerge";
import EcupModal from "../components/EcupModal.vue";
import Plans from "./PlanIndexModal/Plans.vue";
import ChangeToFreeButton from "./PlanIndexModal/ChangeToFreeButton.vue";
import DetailButton from "./PlanIndexModal/DetailButton.vue";
import FAQButton from "./PlanIndexModal/FAQButton.vue";
import {
  silverPlan,
  goldPlan,
  goldTrialPlan,
  platinumPlan,
} from "../../data/plans";

export default {
  name: "PlanIndexModal",

  components: {
    EcupModal,
    Plans,
    ChangeToFreeButton,
    DetailButton,
    FAQButton,
  },

  computed: {
    user() {
      return this.$store.state.user;
    },

    isPlatinum() {
      return this.max_num_of_items_of_free < this.user.items_count;
    },

    freeAvailable() {
      return (
        this.user.plan.code !== "free" &&
        this.max_num_of_items_of_free >= this.user.items_count
      );
    },

    goldTrialAvailable() {
      return (
        this.user &&
        (this.user["gold_trial_available?"] ||
          this.user.plan.code === "gold_trial")
      );
    },

    plansToDisplay() {
      if (this.isPlatinum) {
        // プラチナプランのみ
        return [platinumPlan];
      } else {
        let plans = [silverPlan];
        // ゴールドorおためしゴールド
        plans.push(this.goldTrialAvailable ? goldTrialPlan : goldPlan);
        return plans;
      }
    },
  },

  watch: {
    plansMaster() {
      this.plans = this.plansToDisplay.map((planToDisplay) => {
        return deepmerge(
          planToDisplay,
          this.plansMaster.find((x) => x.code === planToDisplay.code)
        );
      });
    },
  },

  data() {
    return {
      plans: null,
      plansMaster: null,
      max_num_of_items_of_free: 20000,
      selectedOptionCodes: [],
    };
  },

  created() {
    Axios.get("/api/v1/plans/").then((res) => {
      this.plansMaster = res.data.plans;
    });

    Axios.get("/api/v1/options/").then((res) => {
      res.data.options.forEach((option) => {
        let shopsOption = this.user.shops_options.find(
          (x) => x.option.code === option.code && x.is_enabled
        );

        if (shopsOption) {
          this.selectedOptionCodes.push(option.code);
        }
      });
    });
  },
};
</script>
