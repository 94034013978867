<template>
  <v-container v-if="user">
    <v-row justify="center">
      <v-col cols="auto">
        <v-card class="pa-8">
          <v-card-title>退会</v-card-title>
          <v-card-text>
            <div v-if="showSilver">
              <v-alert
                outlined
                color="secondary"
                icon="mdi-medal-outline"
                text
                v-if="canChangePlan()"
              >
                月額<b>9,800</b>円でご利用いただける
                <a @click="$router.push({ name: 'ChangeToSilverPlan' })">
                  シルバープラン
                </a>
                もございます。シルバープランへの移行もご検討ください。
              </v-alert>
              <v-alert
                outlined
                color="secondary"
                icon="mdi-medal-outline"
                text
                v-else
              >
                月額<b>9,800</b>円でご利用いただける
                シルバープランもございます。シルバープランへの移行もご検討ください。
              </v-alert>
            </div>

            <div v-if="showFree">
              <v-alert
                outlined
                color="success"
                icon="mdi-credit-card-off-outline"
                text
                v-if="canChangePlan()"
              >
                無料でご利用いただける
                <a @click="$router.push({ name: 'ChangeToFreePlan' })">
                  フリープラン
                </a>
                もございます。フリープランへの移行もご検討ください。
              </v-alert>
              <v-alert
                outlined
                color="success"
                icon="mdi-credit-card-off-outline"
                text
                v-else
              >
                無料でご利用いただけるフリープランもございます。フリープランへの移行もご検討ください。
              </v-alert>
            </div>

            <v-alert outlined type="info" text v-if="user['is_agency_child?']">
              パートナー企業さま経由でお申込みの場合には、パートナー企業さまへご連絡ください。
            </v-alert>

            <v-alert outlined color="warning" icon="mdi-email-alert" text>
              効果測定、できていますか？「
              <a @click="showOptionIndexModal = true">サマリーメール </a>
              」でショップの売上を確認しましょう
            </v-alert>

            <p class="font-weight-bold text-subtitle-1">
              退会する前に、下記のご案内事項5点を
              <span class="text-decoration-underline">
                必ずご確認ください。
              </span>
              <br />
              最下部「退会する」ボタンにて即時退会する事ができます。
            </p>

            <v-row>
              <v-col cols="auto">
                <ol>
                  <li>
                    <v-card-subtitle class="font-weight-bold text-body-1">
                      EC-UPにて設置したタグを削除します。
                    </v-card-subtitle>
                    最下部「退会する」ボタンがクリックされた後、EC-UP側で設置したタグの削除を行います。<br />
                    基本的にショップさま側でのタグ削除は必要ございませんが、それには「有効期限内のライセンスキーが設定されていること」が条件となります。<br />
                    ライセンスキーの有効期限切れやエラーが起きた場合、当社側でのタグ削除はできません。<br />
                    恐れ入りますがその際は、下記タグ削除ページにてご自身で削除をお願いいたします。<br />

                    <v-row justify="center" class="my-1">
                      <v-col cols="auto">
                        <v-btn
                          outlined
                          color="success"
                          href="https://app.ec-up.jp/delete"
                          target="_blank"
                        >
                          <v-icon left>mdi-trash-can-outline</v-icon>
                          EC-UPタグ削除ページ
                        </v-btn>
                      </v-col>
                    </v-row>

                    EC-UPで設置したタグや画像を残したままにしていただいても構いませんが、<br />
                    タグや画像を残したままにする事でトラブルが発生した場合でも、当社側では一切の責任を負いかねます。<br />
                    <br />
                    なお、ラクイチアピールの画像の削除は行いません。<br />
                    不要な場合にはキャビネットにあるec-upフォルダを削除くださいませ。
                  </li>
                  <li>
                    <v-card-subtitle class="font-weight-bold text-body-1">
                      EC-UPへの再登録は、6ヵ月（180日）経過後から可能です。
                    </v-card-subtitle>
                    理由の如何を問わず、退会後6ヵ月間の再入会はできません。<br />
                    ご不明な点等ございましたら、必ず退会前にお問い合わせくださいませ。<br />
                    6ヵ月後、再度無料トライアルからご利用いただけますが、現在の設定や取得済みクーポン等を引き継ぐ事はできません。<br />
                    なお、紹介により登録したショップさまは、退会したことについて、紹介元へ通知はされません。
                  </li>
                  <li>
                    <v-card-subtitle class="font-weight-bold text-body-1">
                      返金はお受けできません。
                    </v-card-subtitle>
                    既に決済された金額のご返金等はできません（日割りも含む）。<br />
                    また、ご利用状況によっては「退会後に決済が発生」する場合がございます。<br />
                    ※プランのアップグレード後、最初の課金日より前に退会したケースなど
                  </li>
                  <li>
                    <v-card-subtitle class="font-weight-bold text-body-1">
                      APIの利用停止や詳細項目の解除は御社にてお願いします。
                    </v-card-subtitle>
                    最下部「退会する」ボタンをクリック後、APIを利用しタグの削除を行いますが、その後当社ではAPI情報を利用する事は無く破棄します。<br />
                    EC-UPのみでAPIを利用していた場合、セキュリティの観点から利用を停止いただくことをおすすめいたします。<br />
                    EC-UP以外でもAPIを利用している場合、EC-UPのみで利用していた詳細項目の解除をおすすめします。<br />
                    詳しくは、「よくある質問」からご確認ください。
                  </li>
                  <li>
                    <v-card-subtitle class="font-weight-bold text-body-1">
                      退会後メールが送られる場合がございます。
                    </v-card-subtitle>
                    最下部「退会する」ボタンをクリック後、退会完了メールが送信されます。<br />
                    その後、タグの削除を行いますが、削除できずエラーになる場合にはエラーメールが送信される場合がございます。<br />
                    その他メールにつきましては自動的に解除されますので、今後の機能追加に関する当社からの案内も停止します。
                  </li>
                  <li>
                    <v-card-subtitle class="font-weight-bold text-body-1">
                      1～5の内容をご確認いただきました場合、下記退会理由の中から、いずれかにチェックをしてください。（複数回答可）<br />
                      ※回答内容は、EC-UPのサービス改善につなげさせていただきます
                    </v-card-subtitle>
                    <v-checkbox
                      v-for="(value, key) in reasonsForWithdrawal"
                      :label="value"
                      :value="key"
                      v-model="reasonsForWithdrawalIds"
                      hide-details
                      dense
                      class="mt-0"
                    >
                    </v-checkbox>
                  </li>
                </ol>
              </v-col>
            </v-row>

            <v-row justify="center">
              <v-col cols="4">
                <v-btn
                  large
                  block
                  color="primary"
                  @click="withdraw()"
                  :loading="loading"
                  :disabled="!reasonsForWithdrawalIds.length || loading"
                >
                  退会する
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <option-index-modal
      v-if="showOptionIndexModal"
      @close_this_modal="showOptionIndexModal = false"
      :user="user"
      :specifiedOptionCode="'summary_mail'"
    />
  </v-container>
</template>

<script>
import { getAuth, signOut } from "firebase/auth";
import axios from "axios";
import { mapMutations } from "vuex";
import { SET_USER } from "store/mutation-types";
import EcupModal from "../components/EcupModal.vue";
import config from "../../const";
import OptionIndexModal from "../option/OptionIndexModal.vue";

export default {
  components: { EcupModal, OptionIndexModal },

  async created() {
    await axios.get(`/api/v1/shops/me`).then(async (res) => {
      this.SET_USER(res.data);
    });
  },

  data() {
    return {
      maxNumOfItemsForSilver: 10000,
      maxNumOfItemsForFree: 20000,
      loading: false,
      reasonsForWithdrawal: {
        1: "料金が高いため",
        2: "EC-UP以外のサービスで代替するため",
        3: "測定したが、効果が出ていないため",
        4: "効果が出ているか不明のため",
        5: "画像数やバイト数制限の関係で、有効活用できないため",
        6: "イベント時など、定期的にEC-UPのタグ削除をする必要があり面倒なため",
        7: "EC-UPが表示している内容・精度に不満があるため",
        10: "回答しない",
      },
      reasonsForWithdrawalIds: [],
      showOptionIndexModal: false,
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    showSilver() {
      return (
        this.user.plan.code !== "silver" &&
        this.user.plan.code !== "free" &&
        this.user.items_count < this.maxNumOfItemsForSilver
      );
    },
    showFree() {
      return (
        this.user.plan.code !== "free" &&
        this.user.items_count < this.maxNumOfItemsForFree
      );
    },
  },

  methods: {
    canChangePlan() {
      return this.user.use_stripe;
    },

    async withdraw() {
      this.loading = true;
      await axios
        .delete("/api/v1/shops/me", {
          data: { reasons_for_withdrawal_ids: this.reasonsForWithdrawalIds },
        })
        .then(async (res) => {
          // firebaseからログアウト
          // アカウント削除は失敗する可能性があるので手で行う
          const auth = getAuth();
          signOut(auth).then(async (res) => {
            this.$router.push({ name: "WithdrawComplete" });
          });
        });
    },

    ...mapMutations({
      SET_USER,
    }),

    config(value) {
      return config[value];
    },
  },
};
</script>

<style scoped>
.v-card__title {
  font-size: 2em;
  font-weight: 600;
}

.v-card__text {
  font-size: 0.9em;
}
</style>
