<template>
  <v-card class="pa-5">
    <v-row>
      <v-col cols="10">
        <v-card-title class="black--text headline">
          <span>商品レビュー設定</span>
        </v-card-title>
      </v-col>
      <v-col cols="2" class="text-right">
        <v-btn
          color="primary"
          @click="$emit('updateConfig')"
          :loading="loading"
          :disabled="loading"
        >
          保存する
        </v-btn>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-card-subtitle class="black--text title"> 表示ルール </v-card-subtitle>

    <v-card-text class="black--text">
      <v-radio-group
        :value="min_item_review_average_display"
        @change="change($event)"
      >
        <v-radio :value="true" :disabled="loading">
          <template v-slot:label>
            <div class="black--text">
              総合評価{{
                default_values.min_review_average_display
              }}点以上＆レビュー数3件以上の商品
            </div>
          </template>
        </v-radio>
        <v-radio :value="false" :disabled="loading">
          <template v-slot:label>
            <div class="black--text">
              総合評価{{
                (default_values.min_review_average_display - 0.5).toFixed(1)
              }}点以上＆レビュー数3件以上の商品
            </div>
          </template>
        </v-radio>
      </v-radio-group>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["config", "default_values", "loading"],

  computed: {
    min_item_review_average_display() {
      return (
        this.config.min_review_average_display ==
        this.default_values.min_review_average_display
      );
    },
  },

  methods: {
    change(value) {
      if (value) {
        this.config.min_review_average_display =
          this.default_values.min_review_average_display;
      } else {
        this.config.min_review_average_display = (
          this.default_values.min_review_average_display - 0.5
        ).toFixed(1);
      }
    },
  },
};
</script>
