<template>
  <v-row justify="center">
    <v-col cols="12" class="header text-h6 white--text py-1">
      <v-icon color="white">mdi-circle-medium</v-icon>
      ゴールドプランへの変更
    </v-col>
    <v-col cols="12" class="body">
      <v-row>
        <v-col>
          ゴールドプランでは、2万商品までの更新が可能となっております。<br />
          ※料金は日割りにて自動計算されます。<br />
          <br />
          ゴールドプランへのご変更後、すぐに更新を再開いたします。
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="5" class="py-1" v-if="!isUnderPartnersShop">
          <v-btn
            large
            block
            :elevation="5"
            color="primary"
            height="60px"
            @click="$router.push({ name: 'ChangeToGoldPlan' })"
          >
            ゴールドプランに<br />変更する
          </v-btn>
        </v-col>
        <v-col v-else class="font-weight-bold">
          パートナー企業さま経由でお申込みの場合には、パートナー企業さまへご連絡ください。
        </v-col>
      </v-row> 

      <v-row justify="end">
        <v-col cols="auto" class="py-1">
          <a :href="config('HREF_FAQ')" target="_blank">よくある質問Q&A</a>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import config from "../../../const";

export default {
  props: {
    isUnderPartnersShop: Boolean,
  },
  methods: {
    config(value) {
      return config[value];
    },
  },
};
</script>

<style></style>
