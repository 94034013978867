<template>
  <v-row>
    <v-col>
      <v-divider></v-divider>

      <v-row>
        <v-col cols="1" align-self="center">
          <v-img :src="'/images/items/review.png'" max-width="50" />
        </v-col>
        <v-col cols="9">
          <v-card-title>商品レビュー ON/OFF</v-card-title>
          <v-card-text>
            【ON推奨】おまかせ機能で商品レビューを表示するかどうかを設定します。<br />
            独自に画像を用意されているショップさまもいらっしゃいますため、OFFに設定できるようにしています。
          </v-card-text>
        </v-col>

        <v-spacer></v-spacer>
        <v-col cols="2" class="align-self-center">
          <v-switch
            v-model="on"
            inset
            :label="on ? 'ON' : 'OFF'"
            :loading="loading"
            :disabled="loading"
          >
          </v-switch>
        </v-col>
      </v-row>

      <ReviewLayout
        v-if="on"
        :user="user"
        :full_auto_layout_id="full_auto_layout_id"
        @select="selectLayout"
      />
    </v-col>
  </v-row>
</template>

<script>
import ReviewLayout from "./ReviewLayout.vue";

export default {
  props: ["user", "config", "loading"],

  data() {
    return {
      full_auto_layout_id: null,
    };
  },

  computed: {
    on: {
      get() {
        if (this.config?.is_full_auto_on === undefined) {
          return true;
        } else {
          return this.config?.is_full_auto_on;
        }
      },
      set(val) {
        this.$emit("update", { is_full_auto_on: val });
      },
    },
  },

  watch: {
    config: {
      handler(val) {
        this.full_auto_layout_id = val?.full_auto_layout_id;
      },
      immediate: true,
    },
  },

  components: { ReviewLayout },

  methods: {
    selectLayout(val) {
      this.$emit("update", { full_auto_layout_id: val });
    },
  },
};
</script>
