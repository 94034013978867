<template>
  <div>
    <v-row justify="space-around">
      <v-col cols="auto">
        <activated-btn
          text="取り外す"
          icon="mdi-minus-circle"
          :activated="operation == 'bundle_delete'"
          @clicked="$emit('selected', 'bundle_delete')"
        />
      </v-col>
      <v-col cols="auto">
        <activated-btn
          icon="mdi-plus-circle"
          text="表示する"
          :activated="operation == 'bundle_create'"
          @clicked="$emit('selected', 'bundle_create')"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ActivatedBtn from "../SystemEditModal/ActivatedBtn.vue";
export default {
  name: "operation-selection",
  components: {
    ActivatedBtn,
  },
  props: ["operation"],
  computed: {},
  watch: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
