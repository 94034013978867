<template>
  <v-main>
    <v-container>
      <v-row justify="center">
        <v-col cols="auto">
          <v-card class="pa-8">
            <v-card-title>ご利用ありがとうございました。</v-card-title>
            <v-card-text>
              <p>
                EC-UPのご利用誠にありがとうございました。<br />
                退会手続きが完了しました。
              </p>
              <p>
                これより当社側で、EC-UPのタグ削除を行います。<br />
                タグ削除が完了するまで、2万商品未満のショップさまは約1日、2万商品以上のショップさまは数日お日にちがかかります。<br />
                タグの削除が完了するまで、ライセンスキーの停止は行わずにお待ちくださいませ。<br />
                ※タグ削除完了のメールは送信しておりません。
              </p>
              <p>
                ライセンスキーの有効期限切れやエラーとなった場合には、当社側でのタグ削除ができません。<br />
                恐れ入りますがその際は、御社にてタグの削除をお願いいたします。
              </p>
              <p>
                それでは、スタッフ一同またのご利用を心よりお待ちしております。
              </p>

              <v-row justify="center" class="mt-10">
                <v-col cols="auto">
                  <a href="https://ec-up.jp/">EC-UPトップ</a>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {};
</script>

<style scoped>
.v-card__title {
  font-size: 2em;
}

.v-card__text {
  margin-top: 40px;
  font-size: 0.9em;
}
</style>
