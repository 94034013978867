<template>
  <v-row>
    <v-col>
      <v-divider></v-divider>

      <v-row>
        <v-col cols="1" align-self="center">
          <v-img :src="'/images/items/rakuichi.png'" max-width="50" />
        </v-col>
        <v-col cols="9">
          <v-card-title>ラクイチアピール ON/OFF</v-card-title>
          <v-card-text>
            【ON推奨】おまかせ機能でラクイチアピールを表示するかどうかを設定します。<br />
            独自に画像を用意されているショップさまもいらっしゃいますため、OFFに設定できるようにしています。
          </v-card-text>
          <v-card-text v-if="false">
            <div class="pa-3 cyan lighten-5">
              トライアルプラン …
              ご利用日以降のランクイン情報をもとに表示します。<br />
              シルバープラン・ゴールドプラン・プラチナプラン …
              ご利用日開始日以降と、過去5年分のランクイン情報をもとに表示します。
            </div>
          </v-card-text>
        </v-col>

        <v-spacer></v-spacer>
        <v-col cols="2" class="align-self-center">
          <v-switch
            v-model="is_full_auto_rakuichi_on"
            inset
            :label="is_full_auto_rakuichi_on ? 'ON' : 'OFF'"
            @click="update()"
            :loading="loading"
            :disabled="loading"
          >
          </v-switch>
        </v-col>
      </v-row>

      <RakuichiLayout
        v-if="is_full_auto_rakuichi_on"
        :user="user"
        @updated="$emit('updated', $event)"
      />
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import RakuichiLayout from "./RakuichiLayout.vue";

export default {
  props: ["user"],

  data() {
    return {
      is_full_auto_rakuichi_on: null,
      loading: false,
    };
  },

  watch: {
    user: {
      handler(val) {
        this.is_full_auto_rakuichi_on = val.is_full_auto_rakuichi_on;
      },
      immediate: true,
    },
  },

  components: { RakuichiLayout },

  methods: {
    async update() {
      this.loading = true;
      await axios
        .post("/api/v1/shops/me", {
          is_full_auto_rakuichi_on: this.is_full_auto_rakuichi_on,
        })
        .then(async (res) => {
          this.loading = false;
          this.$emit("updated", res);
        });
    },
  },
};
</script>
