<template>
  <ecup-modal modal_width="600px" :force="true">
    <v-container class="pa-5">
      <v-row justify="center">
        <v-col cols="auto">
          ご利用にはオプション機能「PCページ自動更新」の追加が必要です。
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="auto">
          <v-btn
            large
            color="primary"
            @click="$emit('showOptionIndexModal')"
            v-if="canChangeOptions()"
          >
            <v-icon left>mdi-puzzle</v-icon>
            オプション機能 追加/変更
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn large @click="$router.push({ name: 'Dashboard' })">
            ダッシュボードに戻る
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </ecup-modal>
</template>

<script>
import EcupModal from "../../components/EcupModal.vue";

export default {
  components: { EcupModal },
  props: ["user"],
  methods: {
    canChangeOptions() {
      return !this.user["is_agency_child?"];
    },
  },
};
</script>

<style></style>
