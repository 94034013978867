<template>
  <Main
    :descriptionKey="descriptionKey"
    :fullAutoColumnName="fullAutoColumnName"
  />
</template>

<script>
import Main from "./Main.vue";

export default {
  name: "ForPC",
  components: { Main },
  data() {
    return {
      descriptionKey: "ForPC",
      fullAutoColumnName: "is_full_auto_on_ForPC",
    };
  },
};
</script>
