<template>
  <div class="wating_state_dashboard mx-3">
    <v-container fluid>
      <v-row>
        <v-col>
          <v-card class="pt-12 pb-14 mx-auto" id="user_registration_card">
            <div v-if="!isStarted">
              <v-card flat class="pt-12 pb-14">
                <v-card-text>
                  <v-row align="center" justify="center">
                    <v-col cols="auto">
                      <v-img
                        width="200"
                        src="/images/logo/ec-up_setLR_BIG_trans.gif"
                      ></v-img>
                    </v-col>
                    <v-col
                      cols="auto"
                      class="pl-0 py-2 grey--text text-h4 font-italic font-weight-bold"
                      >へようこそ！</v-col
                    >
                  </v-row>

                  <v-row
                    align="center"
                    justify="center"
                    class="text-h4 font-italic font-weight-bold"
                  >
                    <v-col cols="auto" class="py-2 grey--text"
                      >初期設定<span
                        class="mx-1 font-weight-bold text-h3"
                        style="color: #666666"
                        >3STEP</span
                      >を完了させて</v-col
                    >
                  </v-row>

                  <v-row
                    align="center"
                    justify="center"
                    class="text-h4 font-italic font-weight-bold"
                  >
                    <v-col cols="auto" class="py-2 grey--text"
                      >EC-UPを活用しましょう！</v-col
                    >
                  </v-row>

                  <v-row justify="center">
                    <v-col cols="auto" class="text-subtitle-1">
                      EC-UPの設定は楽天市場RMSの画面を開きながら行います
                    </v-col>
                  </v-row>

                  <v-row justify="center">
                    <v-col cols="6">
                      <v-btn
                        x-large
                        color="#BF0000"
                        dark
                        rounded
                        block
                        class="text-h5"
                        height="6rem"
                        href="https://glogin.rms.rakuten.co.jp/?sp_id=1"
                        target="_blank"
                        @click="isStarted = true"
                      >
                        楽天市場RMSを開いて<br />
                        EC-UPの設定スタート
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>

            <div v-if="isStarted" class="mx-auto" style="max-width: 800px">
              <v-row align="center" justify="center" class="mb-4">
                <v-col cols="auto">
                  <img width="150px" src="/images/logo.png" alt="EC-UP" />
                </v-col>
                <v-col cols="auto">
                  <p
                    class="text-h4 grey--text text--darken-2 font-weight-regular pt-2"
                  >
                    <template v-if="nowstep == 40 || nowstep == 50">
                      初期設定完了
                    </template>
                    <template v-else> 初期設定 3 STEP </template>
                  </p>
                </v-col>
              </v-row>

              <v-tabs
                id="installationStatusTabs"
                v-model="selectedTab"
                hide-slider
                height="80px"
                class="pb-8"
              >
                <!-- Tab-1 -->
                <v-tab
                  style="width: 175px"
                  href="#step-1"
                  class="pb-6"
                  :ripple="false"
                >
                  <span
                    class="v-tab__ico"
                    :class="{
                      checked: judge_mode(nowstep, 10) == 'finished',
                      selected: selectedTab === 'step-1',
                    }"
                    >1</span
                  >
                  <div
                    v-if="judge_mode(nowstep, 10) == 'finished'"
                    class="v-tab__label subtitle-2 grey--text text--darken-2"
                  >
                    CSV商品一括申込済
                  </div>
                </v-tab>

                <!-- Tab-2 -->
                <v-tab
                  style="width: 175px"
                  href="#step-2"
                  class="pb-6"
                  :ripple="false"
                  :disabled="!is_rakuten_bundle_edit_csv_enabled"
                >
                  <span
                    class="v-tab__ico"
                    :class="{
                      checked: judge_mode(nowstep, 20) == 'finished',
                      selected: selectedTab === 'step-2',
                      disabled: !is_rakuten_bundle_edit_csv_enabled,
                    }"
                    >2</span
                  >
                  <div
                    v-if="judge_mode(nowstep, 20) == 'finished'"
                    class="v-tab__label subtitle-2 grey--text text--darken-2"
                  >
                    WEBAPI申込済
                  </div>
                </v-tab>

                <!-- Tab-3 -->
                <v-tab
                  style="width: 175px"
                  href="#step-3"
                  class="pb-6"
                  :ripple="false"
                  :disabled="
                    !(
                      is_rakuten_bundle_edit_csv_enabled &&
                      is_rakuten_web_api_enabled
                    )
                  "
                >
                  <span
                    class="v-tab__ico"
                    :class="{
                      checked: judge_mode(nowstep, 30) == 'finished',
                      selected: selectedTab === 'step-3',
                      disabled: !(
                        is_rakuten_bundle_edit_csv_enabled &&
                        is_rakuten_web_api_enabled
                      ),
                    }"
                    >3</span
                  >
                  <div
                    v-if="judge_mode(nowstep, 30) == 'finished'"
                    class="v-tab__label subtitle-2 grey--text text--darken-2"
                  >
                    キー送信済
                  </div>
                </v-tab>

                <!-- Tab-complete -->
                <v-tab
                  v-if="nowstep == 40 || nowstep == 50"
                  style="width: 175px"
                  href="#complete"
                  class="pb-6"
                  :ripple="false"
                >
                  <span
                    class="v-tab__ico checked"
                    :class="{
                      selected: selectedTab === 'complete',
                    }"
                  />
                  <div
                    class="v-tab__label subtitle-2 grey--text text--darken-2"
                  >
                    完了
                  </div>
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="selectedTab">
                <!-- TabItem-1 -->
                <v-tab-item value="step-1">
                  <step-card
                    :mode="judge_mode(nowstep, 10)"
                    class="mt-5"
                    :step_number="1"
                    step_name="CSV商品一括編集機能に申込む"
                    :step_checkbox="true"
                  >
                    <v-checkbox
                      v-model="is_rakuten_bundle_edit_csv_enabled"
                      class="step_checkbox my-1"
                      @click="on_shop_ikkatsu_available_toggle"
                      hide-details
                    ></v-checkbox>
                    <div class="pa-4">
                      <div class="text-center">
                        <v-btn
                          href="https://rshop.rms.rakuten.co.jp/rms/mall/rsf/shop/vc?__event=RS71_000_000"
                          target="_blank"
                          class="font-weight-bold mb-8"
                          color="#BF0000"
                          dark
                          x-large
                        >
                          CSV商品一括編集機能申込ページを開く
                        </v-btn>
                      </div>
                      <p>
                        EC-UPでは、itemCSVを利用しませんが、商品API（itemAPI）を利用する為、楽天市場の仕様上「CSV商品一括編集機能」に申し込みいただく必用がございます。「CSV商品一括編集機能」は楽天市場の有料サービス（月額1万円）となります。
                      </p>
                      <p class="font-weight-bold mb-0">
                        ■ 申し込んでいるか確認するには
                      </p>
                      <p>
                        上記ボタンから開くRMSのページにて確認できます。ステータス<b>「利用中」</b>になっている場合は、申し込み済みですので、左上チェックボックスにチェックしてください。
                      </p>
                      <p class="font-weight-bold mb-0">
                        ■ 申し込んでいない場合
                      </p>
                      <p>
                        EC-UPをご利用いただけませんので、こちらの料金もあわせまして、費用対効果をトライアル期間中にご判断いただければと思います。申込が完了したらチェックをお願いします。
                      </p>
                    </div>
                  </step-card>
                </v-tab-item>

                <!-- TabItem-2 -->
                <v-tab-item value="step-2">
                  <step-card
                    :mode="judge_mode(nowstep, 20)"
                    class="mt-5"
                    :step_number="2"
                    step_name="楽天RMSでWEB APIに申し込む"
                    :step_checkbox="true"
                  >
                    <v-checkbox
                      v-model="is_rakuten_web_api_enabled"
                      class="step_checkbox my-1"
                      @click="on_enable_rms_web_api_toggle"
                      hide-details
                    ></v-checkbox>
                    <div class="pa-4">
                      <p>
                        楽天市場では、2015年よりWEB
                        APIが無料で利用できるようになりました。<br />
                        EC-UPではWEB APIを利用して更新を行います。<br />
                        お申し込みいただきましたら左上チェックボックスにチェックしてください。
                      </p>
                      <div style="max-width: 667px" class="mx-auto">
                        <p class="mb-1">
                          ▼申込手順スライド 画面右端をクリックすると進みます
                        </p>
                        <v-carousel
                          id="rakuten_api_access_permit_carousel"
                          :show-arrows="true"
                          v-model="procedure_web_api_carousel_num"
                          show-arrows-on-hover
                          hide-delimiters
                          width="667"
                          height="420"
                        >
                          <v-carousel-item
                            v-for="(
                              item, i
                            ) in rakuten_enable_webapi_procedures"
                            :key="i"
                            :src="item.src"
                          ></v-carousel-item>
                        </v-carousel>
                      </div>
                    </div>
                  </step-card>
                </v-tab-item>

                <!-- TabItem-3 -->
                <v-tab-item value="step-3">
                  <step-card
                    :mode="judge_mode(nowstep, 30)"
                    class="mt-5"
                    :step_number="3"
                    step_name="詳細APIを利用中にしライセンスキーを下部から送信する"
                    :is_last_step="true"
                  >
                    <div class="pa-4">
                      <div>
                        <p>
                          EC-UPでは、利用するWEB
                          APIの多くの項目の中から、30個を利用します。<br />
                          不足なく利用中にし、サービスシークレットとライセンスキーをお送りいただく事で、ページの更新を行う事ができるようになります。
                        </p>
                      </div>
                      <v-row justify="center" class="my-3">
                        <v-col min-width="667px" max-width="667px" class="pa-0">
                          <send-rms-secret
                            min-width="667px"
                            :procedure_images="
                              rakuten_api_license_key_procedures
                            "
                            :get_secret="true"
                            :allow_expired_at_date_null="true"
                            @lisence_key_update_succeed="
                              fetch_user();
                              nowstep = 40;
                              selectedTab = 'complete';
                              procedure_carousel_dialog = false;
                            "
                            @carousel_changed="
                              send_lisence_key_carousel_num = $event
                            "
                          >
                            <template #error_mesages>
                              お送りいただいたライセンスキーが有効ではない、もしくは取得できない情報がありました。<br />
                              ※ライセンスキーの反映には10分～30分かかる場合があります。<br />
                              時間経過後でもこのエラーが表示される場合には、
                              <span
                                class="font-weight-bold text-decoration-underline"
                              >
                                再度スライドの手順をやり直してください。
                              </span>
                            </template>
                          </send-rms-secret>
                        </v-col>
                        <v-col cols="auto" class="pa-0">
                          <a
                            href="https://navi-manual.faq.rakuten.net/service/000010329?l-id=ManualLeftNavi"
                            target="_blank"
                            class="text-decoration-underline"
                            v-if="send_lisence_key_carousel_num == 12"
                            >RMS店舗運営ナビ [WEB API] RMS WEB
                            SERVICEのライセンスキーの再発行
                          </a>
                        </v-col>
                      </v-row>
                    </div>
                  </step-card>
                </v-tab-item>

                <!-- TabItem-complete -->
                <v-tab-item
                  v-if="nowstep == 40 || nowstep == 50"
                  value="complete"
                >
                  <v-card flat>
                    <v-card-text>
                      <completed-card
                        :mode="completed_card_mode"
                        class="mt-5"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";
import { SET_USER } from "store/mutation-types";
import EcupModal from "../components/EcupModal.vue";
import UserUtilsMixin from "../../packs/mixins/user_utils";
import StepCard from "./components/StepCard.vue";
import CompletedCard from "./components/CompletedCard.vue";
import SendRmsSecret from "../components/SendRmsSecret.vue";

export default {
  name: "InstallationStatusPage",
  mixins: [UserUtilsMixin],
  components: {
    EcupModal,
    StepCard,
    CompletedCard,
    SendRmsSecret,
  },
  data() {
    return {
      is_rakuten_bundle_edit_csv_enabled: false,
      is_rakuten_web_api_enabled: false,
      figure_of_rakuten_api_permission_dialog: false,
      rakuten_license_key_submit_finished: false,
      nowstep: 1,
      procedure_carousel_dialog: false,
      send_lisence_key_carousel_num: 0,
      procedure_web_api_carousel_num: 0,
      isStarted: false,
      selectedTab: "step-1",
      rakuten_enable_webapi_procedures: [
        // STEP2
        {
          src: "/images/installation_status/rakuten_enable_webapi_procedures/01.gif",
        },
        {
          src: "/images/installation_status/rakuten_enable_webapi_procedures/02.png",
        },
        {
          src: "/images/installation_status/rakuten_enable_webapi_procedures/03.png",
        },
        {
          src: "/images/installation_status/rakuten_enable_webapi_procedures/04.png",
        },
        {
          src: "/images/installation_status/rakuten_enable_webapi_procedures/05.gif",
        },
        {
          src: "/images/installation_status/rakuten_enable_webapi_procedures/06.png",
        },
        {
          src: "/images/installation_status/rakuten_enable_webapi_procedures/07.png",
        },
        {
          src: "/images/installation_status/rakuten_enable_webapi_procedures/08.gif",
        },
      ],
      rakuten_api_license_key_procedures: [
        // STEP3
        {
          src: "/images/installation_status/send_rms_secret/01.gif",
        },
        {
          src: "/images/installation_status/send_rms_secret/02.png",
        },
        {
          src: "/images/installation_status/send_rms_secret/03.png",
        },
        {
          src: "/images/installation_status/send_rms_secret/04.png",
        },
        {
          src: "/images/installation_status/send_rms_secret/05.gif",
        },
        {
          src: "/images/installation_status/send_rms_secret/06.png",
        },
        {
          src: "/images/installation_status/send_rms_secret/07.png",
        },
        {
          src: "/images/installation_status/send_rms_secret/08.png",
        },
        {
          src: "/images/installation_status/send_rms_secret/09.png",
        },
        {
          src: "/images/installation_status/send_rms_secret/10.png",
        },
        {
          src: "/images/installation_status/send_rms_secret/11.png",
        },
        {
          src: "/images/installation_status/send_rms_secret/12.png",
        },
        {
          src: "/images/installation_status/send_rms_secret/13.png",
        },
        {
          src: "/images/installation_status/send_rms_secret/14.png",
        },
        {
          src: "/images/installation_status/send_rms_secret/15.png",
        },
        {
          src: "/images/installation_status/send_rms_secret/16.gif",
        },
        {
          src: "/images/installation_status/send_rms_secret/17.png",
        },
        {
          src: "/images/installation_status/send_rms_secret/18.png",
        },
        {
          src: "/images/installation_status/send_rms_secret/19.gif",
        },
        {
          src: "/images/installation_status/send_rms_secret/20.png",
        },
        {
          src: "/images/installation_status/send_rms_secret/21.png",
        },
      ],
    };
  },

  async created() {
    await axios.get(`/api/v1/shops/me`).then((res) => {
      this.SET_USER(res.data);
    });

    this.is_rakuten_bundle_edit_csv_enabled =
      this.user.is_rakuten_bundle_edit_csv_enabled;
    this.is_rakuten_web_api_enabled = this.user.is_rakuten_web_api_enabled;
    this.rakuten_license_key_submit_finished =
      this.user.is_license_key_once_submited;
    if (!this.is_rakuten_bundle_edit_csv_enabled) {
      this.nowstep = 10;
      this.selectedTab = "step-1";
    } else if (!this.user.is_rakuten_web_api_enabled) {
      this.isStarted = true;
      this.nowstep = 20;
      this.selectedTab = "step-2";
    } else if (!this.rakuten_license_key_submit_finished) {
      this.isStarted = true;
      this.nowstep = 30;
      this.selectedTab = "step-3";
    } else if (!this.user.completed_first_update_items) {
      this.isStarted = true;
      this.nowstep = 40;
      this.selectedTab = "complete";
    } else {
      this.isStarted = true;
      this.nowstep = 50;
      this.selectedTab = "complete";
    }
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    completed_card_mode() {
      if (this.nowstep < 40) {
        return "not_yet";
      } else if (this.nowstep == 40) {
        return "fetching_items";
      } else {
        return "active";
      }
    },
  },
  methods: {
    judge_mode(display_mode, step_mode) {
      if (display_mode > step_mode) {
        return "finished";
      } else if (display_mode == step_mode) {
        return "active";
      } else {
        return "not_yet";
      }
    },
    on_shop_ikkatsu_available_toggle() {
      axios
        .post(`/api/v1/shops/me`, {
          is_rakuten_bundle_edit_csv_enabled:
            this.is_rakuten_bundle_edit_csv_enabled,
        })
        .then((res) => {
          this.SET_USER(res.data);
        });

      if (this.is_rakuten_bundle_edit_csv_enabled) {
        this.nowstep = 20;
        this.selectedTab = "step-2";
      } else {
        this.nowstep = 10;
        this.selectedTab = "step-1";
      }
    },
    on_enable_rms_web_api_toggle() {
      axios
        .post(`/api/v1/shops/me`, {
          is_rakuten_web_api_enabled: this.is_rakuten_web_api_enabled,
        })
        .then((res) => {
          this.SET_USER(res.data);
        });
      if (this.is_rakuten_web_api_enabled) {
        this.nowstep = 30;
        this.selectedTab = "step-3";
      } else {
        this.nowstep = 20;
        this.selectedTab = "step-2";
      }
    },

    ...mapMutations({
      SET_USER,
    }),
  },
};
</script>

<style scoped>
.wating_state_dashboard {
  width: 100%;
}
</style>
<style>
/* step_checkbox */
#user_registration_card .step_checkbox {
  position: absolute;
  top: 8px;
  left: 18px;
  margin: 0;
}
/* v-tab */
#installationStatusTabs .v-slide-group__content {
  background-image: linear-gradient(0deg, #ebebeb, #ebebeb);
  background-size: 100% 2px;
  background-position: left top 27px;
}
#installationStatusTabs .v-slide-group__content .v-tab:first-child {
  background-image: linear-gradient(0deg, #fff, #fff);
  background-size: 50% 100%;
  background-position: left top;
}
#installationStatusTabs .v-slide-group__content .v-tab:last-child {
  background-image: linear-gradient(0deg, #fff, #fff);
  background-size: 50% 100%;
  background-position: right top;
}
#installationStatusTabs .v-tabs-bar__content {
  justify-content: space-between;
}
#installationStatusTabs .v-tab::before {
  background: transparent;
}
#installationStatusTabs .v-tab--disabled {
  opacity: 1;
}
#installationStatusTabs .v-tab__label {
  position: absolute;
  bottom: 0;
}
#installationStatusTabs .v-tab__ico {
  position: relative;
  color: #fff;
  background: #92b125;
  height: 28px;
  width: 28px;
  font-size: 16px;
  font-weight: bold;
  line-height: 28px;
  text-align: center;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}
#installationStatusTabs .v-tab__ico::before {
  font-size: 24px;
  line-height: 28px;
  transition: all 0.2s ease-in-out;
}
#installationStatusTabs .v-tab__ico.disabled {
  background: #bdbdbd;
}
#installationStatusTabs .v-tab__ico.checked {
  text-indent: -9999em;
}
#installationStatusTabs .v-tab__ico.checked::before {
  font-family: "Material Design Icons";
  content: "\F012c";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-indent: 0;
}
#installationStatusTabs .v-tab__ico.selected {
  height: 44px;
  width: 44px;
  font-size: 22px;
  line-height: 44px;
}
#installationStatusTabs .v-tab__ico.selected::before {
  font-size: 30px;
  line-height: 44px;
}
</style>
