<template>
  <ecup-modal
    title="支払い方法変更"
    @close_this_modal="$emit('close_this_modal')"
    modal_width="650px"
  >
    <div style="width: 350px" class="mx-auto py-5">
      <v-row>
        <v-col cols="auto" class="text-subtitle-1">
          現在の支払い方法
        </v-col>
      </v-row>
      <div v-if="paymentMethod">
        <v-row class="text-body-2" justify="end">
          <v-col cols="auto" class="pt-0">
            •••• {{ paymentMethod.last4 }}
          </v-col>
          <v-col cols="auto" class="pt-0">
            有効期限: {{ paymentMethod.exp_month | zeroPadding }}/{{
              paymentMethod.exp_year
            }}
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row class="text-body-2 secondary--text" justify="end">
          <v-col cols="auto" class="pt-0">
            支払い方法がありません。
          </v-col>
        </v-row>
      </div>

      <payment-method
        @attached="$router.go({ name: 'Dashboard' })"
        @back="$emit('close_this_modal')"
      />
    </div>
  </ecup-modal>
</template>

<script>
import axios from "axios";
import EcupModal from "../components/EcupModal.vue";
import PaymentMethod from "./components/PaymentMethod.vue";

export default {
  name: "template",
  mixins: [],
  components: { EcupModal, PaymentMethod },
  props: [],
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  watch: {},
  data() {
    return {
      paymentMethod: null,
    };
  },
  methods: {},
  created() {
    axios.get("/api/v1/shops/payment_method").then((res) => {
      this.paymentMethod = res.data.payment_method;
    });
  },
  filters: {
    zeroPadding(value) {
      return ("0" + value).slice(-2);
    },
  },
};
</script>

<style scoped></style>
