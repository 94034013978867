import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import Beginner from "../view/components/Svg/Beginner.vue"

Vue.use(Vuetify)

const opts = {
    theme: {
        themes: {
            light: {
                primary: "#2a8cc7",
                secondary: "#9ea0a0",
                accent: "#2ab8c7",
                error: "#c74c2a",
                warning: "#c78f2a",
                info: "#2196f3",
                success: "#92b125",
            },
        },
    },
    icons: {
        iconfont: 'mdi', // default - only for display purposes
        values: {
            beginner: {
                component: Beginner
            }
        }
    },
}

export default new Vuetify(opts)