<template>
  <div class="pa-8">
    <div v-if="requirePaymentMethod">
      <div class="text-h5 mx-auto text-center mb-6">支払い方法</div>
      <div style="width: 500px" class="mx-auto">
        <p class="error--text text-body-1">
          支払い方法が登録されていません。{{
            plan.name
          }}プランに使用する支払い方法を追加してください。
        </p>
        <payment-method
          @attached="$emit('changePlan')"
          @back="
            $emit('back');
            loading = false;
          "
        />
      </div>
    </div>

    <div v-else-if="planChangeCompleted">
      <div style="width: 500px" class="mx-auto text-center mt-16 pt-16">
        <p>プラン変更を受け付けました。</p>
        <a class="text-body-2" @click="$router.push({ name: 'Dashboard' })">
          ダッシュボードに戻る
        </a>
      </div>
    </div>

    <div v-else-if="user">
      <v-row justify="center">
        <v-col cols="auto" class="text-h5 mx-auto mb-5">
          ご利用プランの確認
        </v-col>
      </v-row>

      <div style="width: 500px" class="mx-auto">
        <v-row>
          <v-col cols="auto" class="text-h6 font-weight-bold">
            {{ plan.name }}プラン
          </v-col>
        </v-row>

        <div v-if="user.plan.code === plan.code">
          <v-row class="mt-7">
            <v-col class="px-0">
              <v-btn large block disabled text>
                <v-icon left> mdi-check </v-icon>
                現在のプランです
              </v-btn>
            </v-col>
          </v-row>
        </div>

        <div v-else>
          <slot name="plan_confirmation" />

          <v-row class="mt-3">
            <v-col class="px-0">
              <v-btn
                large
                block
                color="primary"
                :loading="loading"
                :disabled="loading"
                @click="confirm()"
                >確定</v-btn
              >
            </v-col>
          </v-row>
        </div>

        <v-row>
          <v-col class="px-0">
            <v-btn large block color="white" @click="$router.back()"
              >戻る</v-btn
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="px-0">
            <span class="text-caption">
              新しいプランを確定すると、EC-UPの
              <a href="https://ec-up.jp/terms/" target="_blank">利用規約</a>
              と<a href="https://ec-up.jp/policy/" target="_blank"
                >プライバシーポリシーに</a
              >
              同意したことになります。
            </span>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentMethod from "./PaymentMethod.vue";

export default {
  components: { PaymentMethod },

  props: ["user", "plan", "requirePaymentMethod", "planChangeCompleted"],

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    confirm() {
      this.loading = true;

      if (this.user["plan_changed_today?"]) {
        alert("プランの変更は1日1回までです。");
        this.loading = false;
        return;
      }

      if (this.plan.max_num_of_items < this.user.items_count) {
        alert(
          `商品数が${this.plan.max_num_of_items / 10000}万以上の場合、${
            this.plan.name
          }プランをご利用いただけません。`
        );
        this.loading = false;
        return;
      }

      this.$emit("changePlan");
    },
  },
};
</script>
