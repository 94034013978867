var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.is_render)?_c('transition',{attrs:{"name":"modal"}},[_c('div',{staticClass:"modal-mask"},[_c('div',{staticClass:"modal-wrapper"},[_c('div',{staticClass:"modal-container px-7 py-3"},[_c('v-container',[_c('v-row',{attrs:{"id":"ikkatsu_modal_head_row"}},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6","align-self":"end"}},[_c('div',{staticClass:"text-h5"},[_vm._v("システム設定")])]),_vm._v(" "),_c('v-col',{staticClass:"pa-0 offset-sm-5",staticStyle:{"padding-bottom":"0px"},attrs:{"cols":"1","align-self":"end"}},[_c('v-icon',{staticClass:"modal_close_icon",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("mdi-close-box")])],1)],1),_vm._v(" "),_c('operation-selection-status',{attrs:{"total_items_count":_vm.total_items,"tool_name":_vm.selected_tool && _vm.selected_tool['name'],"tool_description":_vm.selected_tool && _vm.selected_tool['description'],"is_all_tools":_vm.delete_request_data.is_all_tools,"checked_items_count":_vm.checked.length,"editing_target_is_all_items":_vm.editing_target_is_all_items,"operation":_vm.operation}}),_vm._v(" "),_c('div',{staticClass:"modal-body mt-3"},[(_vm.render_now == 'selection_of_operation')?_c('operation-selection',{attrs:{"operation":_vm.operation},on:{"selected":function($event){_vm.operation = $event;
                _vm.render_now = 'selection_of_system';}}}):_vm._e(),_vm._v(" "),(_vm.operation == 'bundle_create')?_c('div',[(_vm.render_now == 'selection_of_system')?_c('system-selection',{attrs:{"shops_tools":_vm.user.shops_tools,"tools":_vm.tools,"allow_all_item":false},on:{"selected":function($event){_vm.create_request_data['operation_shops_tool_id'] = $event;
                  _vm.addingToolSelected(
                    _vm.getToolByShopsToolId(_vm.tools, _vm.user.shops_tools, $event)
                  );
                  _vm.check_same_create_job_exists();
                  _vm.render_now = 'selection_of_design';}}}):_vm._e(),_vm._v(" "),(
                  _vm.render_now == 'selection_of_design' &&
                  !_vm.is_same_create_job_exists
                )?_c('design-selection',{attrs:{"layouts":_vm.selected_tool['layouts'],"selected_layout":_vm.selected_layout,"position":_vm.create_request_data['position_in_description'],"errorMessage":_vm.errorMessageLayout,"element":_vm.element},on:{"layout_selected":function($event){_vm.create_request_data['operation_layout_id'] = $event;
                  _vm.layoutChoosed();},"position_selected":function($event){_vm.create_request_data['position_in_description'] = $event}}}):_vm._e(),_vm._v(" "),(
                  _vm.render_now == 'selection_of_design' &&
                  _vm.is_same_create_job_exists
                )?_c('v-alert',{attrs:{"outlined":"","type":"success","text":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{staticClass:"item_sync_icon mr-5",attrs:{"large":"","mdi":"","color":"primary"}},[_vm._v("mdi-autorenew")])]},proxy:true}],null,false,2463456309)},[_vm._v(" "),_c('div',{staticClass:"text-body-2"},[_c('div',[_vm._v("現在、同じシステムを表示する処理を実行中です。")]),_vm._v(" "),_c('div',[_vm._v("処理の完了までしばらくお待ちください。")])])]):_vm._e(),_vm._v(" "),_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('return-btn',{on:{"clicked":_vm.return_to_before_step}})],1),_vm._v(" "),(
                    _vm.render_now == 'selection_of_design' &&
                    _vm.create_request_data.operation_layout_id &&
                    !_vm.is_same_create_job_exists
                  )?_c('v-col',{attrs:{"cols":"10"}},[_c('v-btn',{attrs:{"x-large":"","block":"","color":"primary","disabled":_vm.updateDisabled},on:{"click":_vm.createRequestFinished}},[_c('div',{staticClass:"text-h6 font-weight-bold"},[_vm._v("更新する")])])],1):_vm._e()],1),_vm._v(" "),(
                  _vm.render_now == 'selection_of_design' &&
                  _vm.isCrowded() &&
                  !_vm.is_same_create_job_exists
                )?_c('v-row',{attrs:{"justify":"center"}},[_c('span',{staticClass:"red--text"},[_vm._v("現在混み合っています。更新の完了までに数時間かかることがあります。")])]):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.operation == 'bundle_delete')?_c('div',[(_vm.render_now == 'selection_of_system')?_c('div',[_c('system-selection',{attrs:{"shops_tools":_vm.user.shops_tools,"tools":_vm.tools,"allow_all_item":true},on:{"return_to_before_step":function($event){_vm.render_now = 'selection_of_operation'},"selected":function($event){_vm.delete_request_data.is_all_tools = false;
                    _vm.deletingToolSelected($event);
                    _vm.render_now = 'confirm_to_delete';},"selected_all_items":function($event){_vm.delete_request_data.job_target_items_tools_attributes =
                      [];
                    _vm.selected_deleting_tool_id = null;
                    _vm.selected_deleting_tool = null;
                    _vm.delete_request_data.is_all_tools = true;
                    _vm.check_same_delete_job_exists();
                    _vm.render_now = 'confirm_to_delete';}}})],1):_vm._e(),_vm._v(" "),(_vm.render_now == 'confirm_to_delete')?_c('div',[(!_vm.is_same_delete_job_exists)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"size":"50","color":"error"}},[_vm._v("mdi-alert")])],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"7"}},[_c('div',[_vm._v("選択したシステムを削除してもよろしいですか？")]),_vm._v(" "),_c('div',[_vm._v("[更新する]ボタンを押すと即時更新が行われます。")])])],1):_vm._e(),_vm._v(" "),(_vm.is_same_delete_job_exists)?_c('v-alert',{attrs:{"outlined":"","type":"success","text":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{staticClass:"item_sync_icon mr-5",attrs:{"large":"","mdi":"","color":"primary"}},[_vm._v("mdi-autorenew")])]},proxy:true}],null,false,2463456309)},[_vm._v(" "),_c('div',{staticClass:"text-body-2"},[_c('div',[_vm._v("現在、同じシステムを取り外す処理を実行中です。")]),_vm._v(" "),_c('div',[_vm._v("処理の完了までしばらくお待ちください。")])])]):_vm._e()],1):_vm._e(),_vm._v(" "),_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('return-btn',{on:{"clicked":_vm.return_to_before_step}})],1),_vm._v(" "),(
                    _vm.render_now == 'confirm_to_delete' &&
                    !_vm.is_same_delete_job_exists
                  )?_c('v-col',{attrs:{"cols":"10"}},[_c('v-btn',{attrs:{"x-large":"","block":"","color":"primary"},on:{"click":_vm.deleteRequestFinished}},[_c('div',{staticClass:"text-h6 font-weight-bold"},[_vm._v("更新する")])])],1):_vm._e()],1),_vm._v(" "),(
                  _vm.isCrowded() &&
                  _vm.render_now == 'confirm_to_delete' &&
                  !_vm.is_same_delete_job_exists
                )?_c('v-row',{attrs:{"justify":"center"}},[_c('span',{staticClass:"red--text"},[_vm._v("現在混み合っています。更新の完了までに数時間かかることがあります。")])]):_vm._e()],1):_vm._e()],1)],1)],1)])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }