<template>
  <v-card class="pa-5">
    <v-row>
      <v-col cols="10">
        <v-card-title class="black--text headline">
          <span>利用可能画像数・バイト数設定</span>
        </v-card-title>
      </v-col>
      <v-col cols="2" class="text-right">
        <v-btn
          color="primary"
          @click="$emit('updateConfig')"
          :loading="loading"
          :disabled="loading"
        >
          保存する
        </v-btn>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-card-text class="black--text">
      システムに利用するバイト数と画像数（スマホ用のみ）の設定です。
    </v-card-text>

    <v-card-text class="black--text">
      <v-row>
        <v-col cols="6">
          <v-row align="center">
            <v-col cols="auto">
              <v-icon left large class="success--text">
                mdi-cellphone-text
              </v-icon>
              <span class="grey--text text--darken-4 body-1 font-weight-medium"
                >スマホ用商品説明文</span
              >
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="auto"> 画像数＆バイト数 </v-col>
            <v-col>
              <v-radio-group
                :value="for_smartphone"
                class="mt-0 pt-0"
                @change="change_for_smartphone($event)"
              >
                <v-radio :value="true" :disabled="loading" class="mb-0">
                  <template v-slot:label>
                    <span class="black--text">最大限利用する</span>
                  </template>
                </v-radio>
                <span class="text-caption pl-8">
                  EC-UPの効果を最大化する設定です。
                </span>

                <v-radio :value="false" :disabled="loading" class="mt-3">
                  <template v-slot:label>
                    <div class="black--text">画像1枚分＆250byte空ける</div>
                  </template>
                </v-radio>
                <span class="text-caption pl-8">
                  セール時など、一括で期間限定バナーの設置（削除）作業をすることが多いショップさまにおすすめの設定です。
                </span>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="6">
          <v-row align="center">
            <v-col cols="auto">
              <v-icon
                left
                large
                class="success--text"
                style="transform: rotateX(180deg)"
              >
                mdi-comment-text-outline
              </v-icon>
              <span class="grey--text text--darken-4 body-1 font-weight-medium"
                >PC用販売説明文</span
              >
            </v-col>
          </v-row>

          <v-row class="flex-nowrap">
            <v-col cols="auto"> バイト数 </v-col>
            <v-col>
              <v-radio-group
                :value="by_sales_method"
                class="mt-0 pt-0"
                @change="change_by_sales_method($event)"
              >
                <v-radio :value="true" :disabled="loading" class="mb-0">
                  <template v-slot:label>
                    <span class="black--text">最大限利用する</span>
                  </template>
                </v-radio>
                <span class="text-caption pl-8">
                  EC-UPの効果を最大化する設定です。
                </span>

                <v-radio :value="false" :disabled="loading" class="mt-3">
                  <template v-slot:label>
                    <div class="black--text">250byte空ける</div>
                  </template>
                </v-radio>
                <span class="text-caption pl-8">
                  セール時など、一括で期間限定バナーの設置（削除）作業をすることが多いショップさまにおすすめの設定です。
                </span>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row align="center">
            <v-col cols="auto">
              <v-icon
                left
                large
                class="success--text"
                style="transform: rotateY(180deg)"
              >
                mdi-comment-text-outline
              </v-icon>
              <span class="grey--text text--darken-4 body-1 font-weight-medium"
                >PC用商品説明文</span
              >
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="auto"> バイト数 </v-col>
            <v-col>
              <v-radio-group
                :value="for_pc"
                class="mt-0 pt-0"
                @change="change_for_pc($event)"
              >
                <v-radio :value="true" :disabled="loading" class="mb-0">
                  <template v-slot:label>
                    <span class="black--text">最大限利用する</span>
                  </template>
                </v-radio>
                <span class="text-caption pl-8">
                  EC-UPの効果を最大化する設定です。
                </span>

                <v-radio :value="false" :disabled="loading" class="mt-3">
                  <template v-slot:label>
                    <div class="black--text">250byte空ける</div>
                  </template>
                </v-radio>
                <span class="text-caption pl-8">
                  セール時など、一括で期間限定バナーの設置（削除）作業をすることが多いショップさまにおすすめの設定です。
                </span>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: [
    "max_byte_size",
    "max_number_of_images",
    "default_max_byte_size",
    "default_max_number_of_images",
    "loading",
  ],

  computed: {
    for_smartphone() {
      return (
        this.max_byte_size.for_smartphone ==
          this.default_max_byte_size.for_smartphone &&
        this.max_number_of_images.for_smartphone ==
          this.default_max_number_of_images.for_smartphone
      );
    },

    by_sales_method() {
      return (
        this.max_byte_size.by_sales_method ==
        this.default_max_byte_size.by_sales_method
      );
    },

    for_pc() {
      return this.max_byte_size.for_pc == this.default_max_byte_size.for_pc;
    },
  },

  methods: {
    change_for_smartphone(value) {
      if (value) {
        this.max_byte_size.for_smartphone =
          this.default_max_byte_size.for_smartphone;
        this.max_number_of_images.for_smartphone =
          this.default_max_number_of_images.for_smartphone;
      } else {
        this.max_byte_size.for_smartphone =
          this.default_max_byte_size.for_smartphone - 250;
        this.max_number_of_images.for_smartphone =
          this.default_max_number_of_images.for_smartphone - 1;
      }
    },
    change_by_sales_method(value) {
      if (value) {
        this.max_byte_size.by_sales_method =
          this.default_max_byte_size.by_sales_method;
      } else {
        this.max_byte_size.by_sales_method =
          this.default_max_byte_size.by_sales_method - 250;
      }
    },
    change_for_pc(value) {
      if (value) {
        this.max_byte_size.for_pc = this.default_max_byte_size.for_pc;
      } else {
        this.max_byte_size.for_pc = this.default_max_byte_size.for_pc - 250;
      }
    },
  },
};
</script>
