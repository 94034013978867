<template>
  <v-card
    class="pa-2 d-inline-block text-left mr-5 d-flex"
    style="white-space: nowrap"
  >
    <div class="mt-n2 mr-2">
      <span style="font-size: 0.7rem">R-Loginパスワード</span>
      <v-img
        contain
        class="ml-3"
        max-width="13"
        src="/images/rms_pw.png"
      ></v-img>
    </div>
    <div v-if="!user.is_rms_shop_pw_active" class="ml-n6 mr-4 d-flex align-end">
      <span class="body-1 red--text text--darken-1 font-weight-bold"
        >期限切れです</span
      >
    </div>
    <div class="d-flex align-center">
      <v-btn color="primary" @click="rms_pw_update_modal = !rms_pw_update_modal"
        >変更
      </v-btn>
    </div>

    <rms-password-update
      v-if="rms_pw_update_modal"
      @close_this_modal="
        rms_pw_update_modal = false;
        $emit('fetchUser');
      "
    />
  </v-card>
</template>

<script>
import EcupModal from "../../components/EcupModal.vue";
import RmsPasswordUpdate from "../../components/RmsPasswordUpdate.vue";

export default {
  name: "RLoginPasswordCard",

  components: {
    EcupModal,
    RmsPasswordUpdate,
  },

  props: ["user"],

  data() {
    return {
      rms_pw_update_modal: false,
    };
  },
};
</script>