<template>
  <v-data-table
    class="is-bordered"
    :headers="headers"
    :items="shops"
    sort-by=""
    hide-default-footer
    disable-pagination
  >
    <template v-slot:body="{ items }">
      <tbody>
        <tr v-for="(item, index) in items" :key="item.name">
          <td
            class="text-center font-weight-bold"
            :class="getStatusColorClass(item)"
          >
            {{ getStatusName(item) }}
          </td>
          <td>{{ item.shop_name }}</td>
          <td>{{ item.shop_code }}</td>
          <td class="text-no-wrap" :class="getShopFormColorClass(item)">
            {{ item.shop_form }}
          </td>
          <td class="text-right">{{ item.items_count.toLocaleString() }}</td>
          <td class="text-no-wrap">
            <v-row align="center">
              <v-col cols="6">
                <span>{{ item.planNameAndTrialPastDays }}</span>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-if="!isAmbassador(item)"
                  class="ml-2 hide-text-field plan-change-button"
                  @change="onChangeSelect($event, item)"
                  :items="selectablePlans(item)"
                  placeholder="変更"
                  item-text="label"
                  item-value="value"
                  v-model="selectedPlans[index]"
                  dense
                  hide-details
                  append-icon=""
                >
                  変更
                </v-select>
              </v-col>
            </v-row>
          </td>
          <td class="text-right">
            {{ item.price.toLocaleString() }}
            <template v-if="item.initial_cost.cost">
              <br /><span class="text-caption" style="word-break: keep-all"
                >(初期費用{{ item.initial_cost.month }}月:{{
                  item.initial_cost.cost.toLocaleString()
                }})</span
              >
            </template>
          </td>
          <td class="text-right">{{ item.kickback | toLocaleString }}</td>
          <td class="text-right">{{ item.coupon | percentize }}</td>
          <td
            :class="getLicensePastDaysColorClass(item)"
            v-if="!isAmbassador(item)"
          >
            <span>{{ item.lisence_past_days }}</span>
            <v-btn
              v-if="item.lisence_past_days"
              small
              outlined
              class="ml-2"
              @click="$emit('change-lisence', item)"
              >変更</v-btn
            >
            <v-btn
              v-else
              small
              depressed
              color="primary"
              class="ml-2"
              @click="$emit('change-lisence', item)"
              >登録</v-btn
            >
          </td>
          <td v-else></td>
          <td class="text-center">
            <v-btn
              v-if="!isAmbassador(item)"
              small
              outlined
              @click="$emit('change-options', item.shop_code)"
              >変更</v-btn
            >
          </td>
          <td :class="editRLoginPasswordColor(item)" class="text-center">
            <v-btn
              v-if="!isAmbassador(item)"
              small
              outlined
              @click="$emit('editRLoginPassword', item.shop_code)"
              >変更</v-btn
            >
          </td>
          <td class="text-center">
            <v-icon small class="mr-2" @click="$emit('edit-memo', item)">
              mdi-pencil
            </v-icon>
          </td>
        </tr>
      </tbody>
      <ecup-modal
        v-if="changePlanModal"
        @close_this_modal="closeChangePlanModal()"
      >
        <v-card flat>
          <v-card-title class="justify-center"
            >{{ selectShop.shop_name }} のプランを{{
              planCode
            }}に変更してもよろしいですか？ ※1ヵ月間ダウングレードできません
          </v-card-title>
          <v-card-text> </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              width="30%"
              :loading="loading"
              :disabled="loading"
              @click="changePlan()"
            >
              変更する
            </v-btn>
          </v-card-actions>
        </v-card>
      </ecup-modal>
    </template>
  </v-data-table>
</template>
<script>
import EcupModal from "../../components/EcupModal.vue";
import axios from "axios";
export default {
  name: "ShopList",

  components: {
    EcupModal,
  },

  props: {
    shops: {
      type: Array,
      require: false,
      default: [],
    },
  },

  data: () => ({
    headers: [
      { text: "ステータス", value: "status", class: "text-no-wrap" },
      { text: "ショップ名", value: "shop_name", class: "text-no-wrap" },
      { text: "店舗URL", value: "shop_code", class: "text-no-wrap" },
      { text: "販売店形態", value: "shop_form", class: "text-no-wrap" },
      { text: "商品数", value: "items_count", class: "text-no-wrap" },
      {
        text: "プラン(トライアル残日数)",
        value: "planNameAndTrialPastDays",
        class: "text-no-wrap",
      },
      { text: "料金", value: "price", class: "text-no-wrap" },
      { text: "キックバック", value: "kickback", class: "text-no-wrap" },
      { text: "クーポン", value: "coupon", class: "text-no-wrap" },
      {
        text: "残キー日数",
        value: "lisence_past_days",
        class: "text-no-wrap",
        width: "120",
      },
      {
        text: "オプション変更",
        value: "option",
        class: "text-no-wrap",
      },
      {
        text: "R-Loginパスワード",
        value: "r-login-password",
        class: "text-no-wrap",
      },
      { text: "メモ", value: "memo", class: "text-no-wrap" },
    ],
    plans: [
      { label: "フリー", value: "free" },
      { label: "シルバー", value: "silver" },
      { label: "ゴールド", value: "gold" },
      { label: "プラチナ", value: "platinum" },
    ],
    selectedPlans: [],
    changePlanModal: false,
    selectShop: {},
    loading: false,
    planCode: "",
  }),

  watch: {
    shops(val) {
      if (val.length > 0) {
        this.selectedPlans = new Array(val.length);
      }
    },
  },

  computed: {
    selectablePlans() {
      return (item) => {
        return this.plans.filter((p) => p.label !== item.plan_name);
      };
    },

    initialCost() {
      return this.planCode === "platinum" && !this.selectShop.has_paid
        ? 50000
        : 0;
    },
  },

  filters: {
    percentize(val) {
      if (val) {
        return val + "%";
      } else {
        return "-";
      }
    },

    toLocaleString(val) {
      if (val) {
        return val.toLocaleString();
      } else {
        return "-";
      }
    },
  },

  methods: {
    isAmbassador(shop) {
      return shop.shop_form === "アンバサダー";
    },
    getStatusName(shop) {
      if (shop.status === "waiting") {
        return "設定中";
      } else if (shop.status === "operating") {
        return "稼働";
      } else if (shop.status === "stopped") {
        return "停止中";
      } else {
        return "-";
      }
    },
    getStatusColorClass(shop) {
      let colorClass = "";
      if (shop.status === "operating") {
        colorClass += " blue lighten-2";
      } else if (shop.status === "stopped") {
        colorClass += " red lighten-2";
      }
      return colorClass;
    },
    getShopFormColorClass(shop) {
      let colorClass = "";
      if (this.isAmbassador(shop)) {
        colorClass = "blue lighten-5";
      } else {
        colorClass = "yellow lighten-4";
      }
      return colorClass;
    },
    onChangeSelect(value, item) {
      this.changePlanModal = true;
      this.selectShop = item;
      this.planCode = value;
    },
    changePlan() {
      this.loading = true;
      const data = {
        shop_code: this.selectShop.shop_code,
        plan_code: this.planCode,
        initial_cost: this.initialCost,
      };
      axios
        .post(`/api/v1/shops/admin_change_plan`, data)
        .then((res) => {
          if (res.data.error_code === "less_than_a_month") {
            alert("プランのダウングレードは1ヶ月に1回までです。");
          } else {
            const selectShop = res.data.shop;
            const shopIndex = this.shops.findIndex(
              (shop) => shop.id === selectShop.id
            );
            const resData = {
              shop_index: shopIndex,
              plan_code: res.data.plan.name,
            };
            this.$emit("change-plan");
          }
          this.planCode = "";
          this.selectShop = {};
          this.loading = false;
          this.changePlanModal = false;
          this.selectedPlans = new Array(this.shops.length);
        })
        .catch((error) => {
          alert("プランの変更に失敗しました。");
          this.loading = false;
          this.changePlanModal = false;
          this.selectedPlans = new Array(this.shops.length);
        });
    },
    getLicensePastDaysColorClass(item) {
      return item.lisence_past_days
        ? item.lisence_past_days < 1
          ? "red lighten-2"
          : item.lisence_past_days < 5
          ? "yellow lighten-4"
          : ""
        : "";
    },
    editRLoginPasswordColor(item) {
      return item.rms_registration_status === 3 && !item.is_rms_shop_pw_active
        ? "red lighten-2"
        : "";
    },
    closeChangePlanModal() {
      this.selectShop = {};
      this.changePlanModal = false;
      this.planCode = "";
      this.loading = false;
      this.selectedPlans = new Array(this.shops.length);
    },
  },
};
</script>

<style>
.v-data-table.is-bordered th {
  background-color: #e0e0e0;
}
.v-data-table.is-bordered td,
.v-data-table.is-bordered td.red,
.v-data-table.is-bordered td.yellow,
.v-data-table.is-bordered td.blue {
  border-color: rgba(0, 0, 0, 0.12) !important;
}
.v-data-table.is-bordered tr:hover {
  background: transparent !important;
}
.v-data-table.is-bordered th:not(:first-of-type) {
  border-left: thin solid rgba(0, 0, 0, 0.12);
}
.v-data-table.is-bordered td:not(:first-of-type) {
  border-left: thin solid rgba(0, 0, 0, 0.12);
}
.plan-change-button .v-input__slot {
  width: 53.41px;
  height: 28px !important;
  border: 1px solid rgba(0, 0, 0, 0.87);
  border-radius: 4px !important;
  font-size: 0.75rem;
  text-align: center;
}
.plan-change-button .v-input__slot::before {
  border-style: none !important;
}
input::placeholder {
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: center;
  opacity: 1;
}
.plan-change-button .v-input__control {
  padding: 0 !important;
}
.plan-change-button .v-select__selection {
  text-align: center;
}
</style>
