<template>
  <v-container fill-height fluid v-if="user" class="pa-0">
    <v-row fill-height justify-content="center">
      <v-col
        xs="12"
        md="4"
        lg="4"
        style="max-width: &quot;100px&quot;"
        :style="{
          'background-color': plan.color,
        }"
      >
        <v-card min-width="200" class="ma-5">
          <v-container>
            <v-row>
              <v-col class="mt-16 mb-5">
                <plan-features-card
                  :user="user"
                  :plan="plan"
                  :percentOff="percentOff"
                  :selectedOptionCodes="selectedOptionCodes"
                  @input="$emit('input', $event)"
                >
                  <template
                    v-for="(slotContent, slotName) of $scopedSlots"
                    #[slotName]="data"
                  >
                    <slot :name="slotName" v-bind="data"></slot>
                  </template>
                </plan-features-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>

        <slot name="upsell_card" />

        <div class="ma-5">
          <a @click="$router.back()" class="black--text">←戻る</a>
        </div>
      </v-col>

      <v-col md="8" lg="8" xs="12" style="background-color: white">
        <plan-confirmation
          :user="user"
          :plan="plan"
          :nextPlan="nextPlan"
          :requirePaymentMethod="requirePaymentMethod"
          :planChangeCompleted="planChangeCompleted"
          @changePlan="changePlan"
          @back="requirePaymentMethod = false"
        >
          <template #plan_confirmation>
            <slot name="plan_confirmation" />
          </template>
        </plan-confirmation>
      </v-col>
    </v-row>

    <ecup-modal
      v-if="googleFormUrl && showEnquete"
      @close_this_modal="showEnquete = false"
    >
      <v-row justify="center" class="mt-10">
        <v-col cols="auto">
          <iframe
            :src="googleFormUrl"
            width="640"
            height="744"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            >読み込んでいます…</iframe
          >
        </v-col>
      </v-row>
    </ecup-modal>
  </v-container>
</template>

<script>
import axios from "axios";
import EcupModal from "../../components/EcupModal.vue";
import PaymentMethod from "./PaymentMethod.vue";
import PlanConfirmation from "./PlanConfirmation.vue";
import PlanFeaturesCard from "./PlanFeaturesCard.vue";

export default {
  components: {
    EcupModal,
    PaymentMethod,
    PlanConfirmation,
    PlanFeaturesCard,
  },

  props: {
    user: Object,
    plan: Object,
    nextPlan: Object,
    percentOff: {
      type: Number,
      default: 0,
    },
    initialCost: {
      type: Number,
      default: 0,
    },
    selectedOptionCodes: Array,
    googleFormUrl: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      requirePaymentMethod: false,
      planChangeCompleted: false,
      showEnquete: false,
    };
  },

  methods: {
    changePlan() {
      let params = {
        shop_code: this.user.shop_code,
        plan_code: this.plan.code,
        initial_cost: this.initialCost,
        option_codes: this.selectedOptionCodes,
      };

      axios.post("/api/v1/shops/change_plan", params).then((res) => {
        if (res.data.error_code === "resource_missing") {
          this.requirePaymentMethod = true;
        } else {
          this.planChangeCompleted = true;
          this.showEnquete = true;
        }
      });
    },
  },
};
</script>
