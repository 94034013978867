export const freePlan = {
  code: "free",
  color: "#93BE4C",
  route_name: "ChangeToFreePlan",
  icon: "/images/plans/free.png",
  features: [{ 利用可能ショップ: "商品数2万まで" }, { 更新頻度: "1日1回" }],
  messages: [
    "フリープランでは、商品説明文の最後に「Powered by EC-UP」のクレジットが表示されます。",
    "商品ごとのシステム設定は全てリセットされ、後日有料プランに変更しても復活はできません。",
  ],
};

export const silverPlan = {
  code: "silver",
  color: "#C4C4C4",
  route_name: "ChangeToSilverPlan",
  icon: "/images/plans/silver.png",
  features: [
    { 利用可能ショップ: "商品数1万まで" },
    { 更新頻度: "1日1回" },
    { おまかせ機能: "レイアウト変更不可" },
    { ラクイチアピール: "デザイン4種類" },
    { "PCページ自動更新/トップページ自動更新": "+ 3,000円" },
  ],
};

const goldPlanBase = {
  color: "#CCA83F",
  icon: "/images/plans/gold.png",
  features: [
    { 利用可能ショップ: "商品数2万まで" },
    { 更新頻度: "1日2回以上" },
    { おまかせ機能: "レイアウト変更可能" },
    { ラクイチアピール: "デザイン16種類" },
    { "PCページ自動更新/トップページ自動更新": "+ 0円" },
  ],
  appeal: "おすすめプラン",
};

export const goldPlan = {
  code: "gold",
  route_name: "ChangeToGoldPlan",
  ...goldPlanBase,
};

export const goldTrialPlan = {
  code: "gold_trial",
  route_name: "ChangeToGoldTrialPlan",
  ...goldPlanBase,
};

export const platinumPlan = {
  code: "platinum",
  color: "#4688A4",
  route_name: "ChangeToPlatinumPlan",
  icon: "/images/plans/platinum.png",
  features: [
    { 利用可能ショップ: "商品数50万まで" },
    { 更新頻度: "商品数により変動" },
    { おまかせ機能: "レイアウト変更可能" },
    { ラクイチアピール: "デザイン16種類" },
    { "PCページ自動更新/トップページ自動更新": "+ 0円" },
  ],
};
