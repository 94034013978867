<template>
  <div>
    <head>
      <meta charset="utf-8">
      <meta content="width=device-width, initial-scale=1.0" name="viewport">

      <title>楽天ショップ運営の最強ツール　イーシーアップ</title>
      <meta content="" name="description">
      <meta content="" name="keywords">

      <!-- Favicons -->
      <!-- <link href="assets/img/favicon.png" rel="icon">
      <link href="assets/img/apple-touch-icon.png" rel="apple-touch-icon">-->
    </head>

    <body>
      <!-- ======= Top Section ======= -->
      <section id="top">
      <div class="d-none d-md-block"><img src="images/invitation_page/pcmain.svg" class="img-fluid" alt=""></div>
      <div class="d-block d-md-none"><img src="images/invitation_page/spmain.svg" class="img-fluid" alt=""></div>
      </section><!-- End top -->

      <main id="main">

        <!-- ======= Target Section ======= -->
        <section id="target" class="target">
          <div class="container g-0">
              <div class="row g-0">
                <div class="col target01">
                  <img src="images/invitation_page/target01.svg" class="img-fluid" alt="">
                </div>

                <div class="col target02">
                  <img src="images/invitation_page/target02.svg" class="img-fluid" alt="">
                </div>
              </div>
        </div>
        <div class="campaign">
          <h2>紹介先にあなたの紹介コードを教えよう！</h2>
          <dl>
            <dt>あなたの紹介コード</dt>
            <dd>{{shopCode}}</dd>
          </dl>
          <h3>EC-UPをご検討されているショップさまにお伝えください。</h3>
          <p>留意事項<br>
            クーポン1枚で1ヶ月分の利用金額を割引することが可能です。<br>
            クーポンを獲得している場合、無料→有料プランへの移行時や、毎月の課金継続時に自動的に割引が適用されます（自動適用OFFも可能）。<br>
            ※有料オプション機能は、クーポン割引の対象外となります。<br>
            ※楽天市場のお客さまへ発行するクーポンとは異なります。<br>
            ※パートナー企業さまよりお申込みの場合にはクーポンは適用されません。</p>
        </div>
        <a :href="signUpForInvitationUrl" class="qr-area" target="_blank" rel="noopener noreferrer">
          <div class="container">
            <div class="row">
              <div class="col-4 col-md-2 offset-md-2 col-lg-2 offset-lg-2 text-end">
                <img :src="qrCodeDataUrl" class="img-fluid qr-code" alt="">
              </div>
              <div class="col-8 col-md-8">
                <h1><span class="triangle"></span><span class="text">新規登録はこちらから</span></h1>
                <p>QRコードを紹介相手に見せてください。<br>
                紹介された方は店舗URLを入力して新規登録を完了し、後日送信されるメールから初期設定へお進みください。</p>
              </div>
            </div>
          </div>
        </a>
        </section><!-- End Target Section -->

        <!-- ======= Example Section ======= -->
        <section id="example" class="example">
          <div class="container">

            <div class="section-title" data-aos="fade-up">
              <h2>こんな方、あなたのまわりにいませんか？</h2>
            </div>

            <div class="row g-2 g-lg-3" data-aos="fade-left">
              <div class="col-lg-3 col-6">
                <div class="box" data-aos="zoom-in" data-aos-delay="50">
                  <img src="images/invitation_page/illust01.svg" class="img-fluid" alt="">
                  <h3>新たに<br class="d-block d-md-none">楽天ショップを<br>開設された方</h3>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div class="box" data-aos="zoom-in" data-aos-delay="100">
                  <img src="images/invitation_page/illust02.svg" class="img-fluid" alt="">
                  <h3>楽天ショップの運用に<br>お悩みの方</h3>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div class="box" data-aos="zoom-in" data-aos-delay="150">
                  <img src="images/invitation_page/illust03.svg" class="img-fluid" alt="">
                  <h3>普段の仕事が忙しくて<br>楽天ショップ運営に<br>手が回らない方</h3>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div class="box" data-aos="zoom-in" data-aos-delay="200">
                  <img src="images/invitation_page/illust04.svg" class="img-fluid" alt="">
                  <h3>なかなか<br>効果が上がらず<br>お手上げ状態の方</h3>
                </div>
              </div>
            </div>

          </div>
        </section><!-- End Example Section -->

        <!-- ======= Details Section ======= -->
        <section id="details" class="details">
          <div class="container">
            <img src="images/invitation_page/ecup_olution.svg" class="img-fluid" alt="">
            <div class="polygon-b"></div>
            <div class="section-title">
              <h2 class="text-center">紹介したい相手には<br class="d-block d-md-none">まずこの動画！</h2>
            </div>
            <div class="vid_contents">
              <iframe class="vid_main" width="928" height="522" src="https://www.youtube.com/embed/6gQCzxUchtc?controls=1&loop=1&playsinline=1&mute=1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen frameborder="0"></iframe>
            </div>

            <div class="section-title">
              <h2 class="text-center">紹介制度の説明</h2>
            </div>
            <div class="content">
              <p class="text-center">この制度は、EC-UPご利用中のショップさまが、<br>未利用のショップさまにEC-UPをご紹介いただくことで<br>双方が割引クーポンを得られるお得な制度です。</p>    
              <img src="images/invitation_page/flow_illust.svg" class="img-fluid" alt="">
              <h3 class="text-center">紹介コードを使った<br class="d-block d-md-none">登録方法の手順</h3>
              <img src="images/invitation_page/flow_word01.svg" class="img-fluid" alt="">
              <h4 class="text-center">紹介先が有料化すると・・・</h4>
              <img src="images/invitation_page/flow_word02.svg" class="img-fluid" alt="">
            </div>
          </div>
        </section><!-- End Details Section -->

        <!-- ======= Intro Section ======= -->
        <section id="intro" class="intro">
          <div class="container">
        <div class="section-title">
          <h2 class="text-center">こんな時にEC-UPを<br class="d-block d-md-none">紹介しよう！</h2>
        </div>  
        <div class="content">
          <div class="row align-items-center">
            <div class="col-md-5 text-center" data-aos="fade-right">
              <img src="images/invitation_page/illust05.svg" class="img-fluid" alt="">
            </div>
            <div class="col-md-7" data-aos="fade-up">
              <h3>EC勉強会で！</h3>
              <p>同業種が集まる勉強会は有益な情報共有ができる大切な場所。ぜひEC-UPの素晴らしさを伝えながこのページをご案内ください。</p>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-md-5 text-center" data-aos="fade-left">
              <img src="images/invitation_page/illust06.svg" class="img-fluid" alt="">
            </div>
            <div class="col-md-7" data-aos="fade-up">
              <h3>セミナーで！</h3>
              <p>ECに関するセミナーも全国各地で頻繁に行われています。ぜひEC-UPを伝えてください！</p>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-md-5 text-center" data-aos="fade-right">
              <img src="images/invitation_page/illust07.svg" class="img-fluid" alt="">
            </div>
            <div class="col-md-7" data-aos="fade-up">
              <h3>飲み会・交流会で！</h3>
              <p>同業種が集まる飲み会や交流会は思わぬところから輪が広がる大切な場所です。ネタの1つとしてEC-UPを！</p>
            </div>
          </div>	
        </div> 
      </div>
        </section><!-- End Intro Section -->

        <!-- ======= F.A.Q Section ======= -->
        <section id="faq" class="faq">
          <div class="container">

            <div class="section-title" data-aos="fade-up">
              <h2>EC-UP Q&amp;A</h2>
            </div>

            <div class="faq-list">
              <ul>
                <li data-aos="fade-up">
                  <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" class="collapse" data-bs-target="#faq-list-1">クーポンとは何ですか？ <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                  <div id="faq-list-1" class="collapse show" data-bs-parent=".faq-list">
                    <p>クーポンとは、有料プランをご利用中のショップさまが、費用を割引することができる仕組みです。<br>
                      10％OFFクーポン、50％OFFクーポンの2種類があり、クーポン1枚で1ヶ月分の利用金額を割引することが可能です。<br>
                      クーポンを獲得している場合、無料→有料プランへの移行時や、毎月の課金継続時に自動的に割引が適用されます（自動適用OFFも可能）。<br>
                      自社ショップのクーポン獲得状況は、ログイン後のダッシュボード画面から確認できます。</p>
                  </div>
                </li>
                
                <li data-aos="fade-up">
                  <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-2" class="collapsed">クーポンはいつ使えますか？ <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                  <div id="faq-list-2" class="collapse" data-bs-parent=".faq-list">
                    <p>無料→有料プランへの移行時や毎月の継続課金、または他有料プランへ変更時に利用できます。<br>一度に利用できるクーポンは1枚のみとなります。</p>
                    <small>※プラチナプランはクーポンを利用できません。<br>
                    ※パートナー企業さまより登録した場合にはクーポンは利用できません。<br>
                    ※オプション機能はクーポンの割引の対象外となります。</small>  
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="100">
                  <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-3" class="collapsed">複数のクーポンをまとめて利用できますか？ <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                  <div id="faq-list-3" class="collapse" data-bs-parent=".faq-list">
                    <p>いいえ、できません。<br>1回の決済につき1枚のみ利用可能となっています。<br>「10％OFFクーポン」と「50％OFFクーポン」のどちらも獲得している場合、割引率の高い50％OFFクーポンが優先的に適用されます。</p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="200">
                  <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-4" class="collapsed">クーポンは何枚でも獲得できますか？<i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                  <div id="faq-list-4" class="collapse" data-bs-parent=".faq-list">
                    <p>何枚でも獲得できます。上限はございません。</p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="300">
                  <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-5" class="collapsed">クーポンを利用しない事はできますか？ <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                  <div id="faq-list-5" class="collapse" data-bs-parent=".faq-list">
                    <p>はい、可能です。<br>管理画面のダッシュボードから「クーポンの自動適応」をOFFにする事で利用しない事が可能です。<br>誤ってクーポンの自動適用をOFFにした場合でも、決済後に適用することはできませんのでご注意ください。</p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="400">
                  <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-6" class="collapsed">クーポン1枚につき、いつまで割引が有効ですか？<i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                  <div id="faq-list-6" class="collapse" data-bs-parent=".faq-list">
                    <p>クーポン1枚につき、1ヶ月分の料金が割引となります。<br>クーポンがなくなりますと、次回決済より通常価格に戻ります。</p>
                  </div>
                </li>

              </ul>
            </div>

          </div>
        </section><!-- End F.A.Q Section -->

        <!-- ======= Contact Section ======= -->
        <section id="contact" class="contact">
          <div class="container">
        <div class="row">
          <div class="col-md-6"><p>ご不明な点はサポート窓口までお問い合わせください。</p></div>
        </div>
          </div>
        </section><!-- End Contact Section -->

      </main><!-- End #main -->

      <!-- ======= Footer ======= -->
      <footer id="footer">
        <div class="container">
          <div class="footer-top">
            <div class="mb-3"><img src="images/invitation_page/logo.svg" class="img-fluid" alt=""></div>
            <p>ECデータバンク株式会社 EC DataBank Inc.<br>〒277-0005 千葉県柏市柏4-8-14 柏染谷ビル4F<br>
            <a href="https://ecdb.co.jp/" target="_blank">https://ecdb.co.jp/</a></p>
            <dl>
              <dt>EC-UPに関するお問い合わせ先</dt>
              <dd>☎︎ <a href="tel:050-1741-7396">050-1741-7396</a></dd>
              <dd>メールでのお問い合わせは<a href="https://ec-up.jp/request/">こちら</a></dd>
            </dl>
          </div>
        </div>	
        <div class="copyright">
          &copy; 2024 EC DataBank Inc.
        </div>
      </footer><!-- End Footer -->

      <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-chevron-up"></i></a>
      <div id="preloader"></div>
    </body>
  </div>
</template>

<script>
import QRCode from 'qrcode';

export default {
  data() {
    return {
      qrCodeSvgContent: '',
      qrCodeDataUrl: '',
      shopCode: '',
      signUpForInvitationUrl: '',
    };
  },
  
  mounted() {
    this.shopCode = this.$route.params.shop_code; // shop_code 受け取り
    this.envBaseUrl = this.getEnvBaseUrl(); // 環境のURL取得
    this.signUpForInvitationUrl = `${this.envBaseUrl}/#/sign_up/${this.shopCode}`;
    this.generateQRCodeSvg(this.signUpForInvitationUrl); // QRコードを作成する
    this.loadExternalScripts(); // 外部スクリプトの読み込み
    window.addEventListener('scroll', this.toggleBackToTop); // 画面上部に戻るボタン
    this.addBootstrapCSS(); // Bootstrap CSS を読み込む
  },
  beforeDestroy() {
    this.removeBootstrapCSS(); // Bootstrap CSS を削除する
  },

  watch: {
    qrCodeSvgContent(newValue) {
      if (newValue) {
        let base64Data = btoa(unescape(encodeURIComponent(newValue)));
        this.qrCodeDataUrl = `data:image/svg+xml;base64,${base64Data}`;
      } else {
        this.qrCodeDataUrl = '';
      }
    }
  },
  
  methods: {
    addBootstrapCSS() {
      const linkElement = document.createElement('link');
      linkElement.rel = 'stylesheet';
      linkElement.href = 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css';
      linkElement.id = 'bootstrap-css';
      document.head.appendChild(linkElement);
    },
    removeBootstrapCSS() {
      const bootstrapCSS = document.getElementById('bootstrap-css');
      if (bootstrapCSS) {
        document.head.removeChild(bootstrapCSS);
      }
    },
    getEnvBaseUrl() {
      const fullURL = window.location.href;
      const extractedBaseUrl = fullURL.split('/#/invitation_page')[0];
      return extractedBaseUrl;
    },
    async generateQRCodeSvg(url) {
      try {
        this.qrCodeSvgContent = await QRCode.toString(url, { type: 'svg' });
      } catch (error) {
        console.error('QRコードの生成に失敗しました:', error);
        this.qrCodeSvgContent = '';
      }
    },
    async loadExternalScripts() {
      try {
        await Promise.all([
          this.loadScript('https://cdn.jsdelivr.net/npm/aos@2.3.4/dist/aos.js'),
          this.loadScript('https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js'),
          this.loadScript('https://cdn.jsdelivr.net/npm/swiper/swiper-bundle.min.js'),
        ]);
        AOS.init();
        AOS.refresh();
      } catch (error) {
        console.error('Error loading scripts:', error);
      } finally {
        this.hidePreloader(); // プリローダーを非表示にする
      }
    },
    loadScript(src) {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    },
    hidePreloader() {
      const preloader = document.getElementById('preloader');
      if (preloader) {
        preloader.remove();
      }
    },
    toggleBackToTop() {
      const backToTop = document.querySelector('.back-to-top');
      if (backToTop) {
        backToTop.classList.toggle('active', window.scrollY > 100);
      }
    },
  }
};
</script>

<style scoped>
@import "../../stylesheets/invitation_page.scss";

</style>

<style>
html body p {
  margin: 0;
  padding: 0;
}
html body .alert {
  margin: 0;
  padding: 0;
}
</style>
