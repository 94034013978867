<template>
  <v-row justify="center">
    <v-col cols="6" v-for="(plan, i) in plans" :key="i">
      <div class="balloon-bottom" v-if="plan.appeal">
        <v-icon color="white" large> mdi-crown </v-icon>
        {{ plan.appeal }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Appeal",

  props: ["plans"],
};
</script>

<style scoped>
.balloon-bottom {
  position: relative;
  padding: 10px;
  color: white;
  font-weight: bold;
  text-align: center;
  background-color: #cca83f;
  margin-bottom: 1em;
  border-radius: 7px;
  top: 5px;
}

/* afterで三角を表現 */
.balloon-bottom::after {
  content: "";
  position: absolute;
  left: 105px;
  bottom: -15px;
  display: block;
  width: 0;
  height: 0;
  border-right: 30px solid transparent;
  border-top: 30px solid #cca83f;
  border-left: 30px solid transparent;
}
</style>
