import axios from "axios";
import firebase from "./firebase";
import Vue from "vue";
import VueAxios from "vue-axios";

axios.defaults.headers.get["Accept"] = "application/json";
axios.defaults.headers.common = {
  "X-Requested-With": "XMLHttpRequest",
  "X-CSRF-TOKEN": document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content"),
};

axios.interceptors.request.use(
  async (config) => {
    let token = null;
    const currentUser = await firebase.getCurrentUser();
    if (currentUser) {
      token = await currentUser.getIdToken();
    }
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    //catchへ行くようにする
    return Promise.reject(error);
  }
);

Vue.use(VueAxios, axios);

export default axios;
