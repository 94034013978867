<template>
  <thead>
    <tr height="90px">
      <th class="bg-gray">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-simple-checkbox
              v-bind="attrs"
              v-on="on"
              :value="checkedAll"
              @input="$emit('update:checkedAll', $event)"
            ></v-simple-checkbox>
          </template>
          <span v-if="checkedAll">まとめて解除</span>
          <span v-else>まとめてチェック</span>
        </v-tooltip>
      </th>

      <th
        class="bg-gray px-0"
        :class="key"
        v-for="(value, key) in headers"
        :key="key"
        @click="$emit('sort', key)"
        :width="value.width"
      >
        <!-- ヘッダの注釈 -->
        <v-tooltip top v-if="value.description">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" v-html="value.name"></span>
          </template>
          <span v-html="value.description"></span>
        </v-tooltip>
        <span v-else v-html="value.name"></span>

        <!-- ソートアイコン -->
        <v-icon v-if="order == key && direction == 'asc'" color="info"
          >mdi-menu-up</v-icon
        >
        <v-icon v-else-if="order == key && direction == 'desc'" color="info"
          >mdi-menu-down</v-icon
        >
        <v-icon v-else color="#9F9F9F">mdi-menu-swap</v-icon>
      </th>

      <!-- <th class="bg-gray text-center">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">商品ページ表示<br />（スマホ）</span>
          </template>
          スマホ商品ページを開く
        </v-tooltip>
      </th> -->
      <th
        class="bg-gray px-2"
        v-if="user[columnNameFullAuto] && user.plan.code != 'free'"
        @click="$emit('sort', columnNameFullAuto)"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">おまかせ</span>
          </template>
          各商品に対してAIおまかせ機能の適用可否を設定します
        </v-tooltip>
        <v-icon
          v-if="order == columnNameFullAuto && direction == 'asc'"
          color="info"
          >mdi-menu-up</v-icon
        >
        <v-icon
          v-else-if="order == columnNameFullAuto && direction == 'desc'"
          color="info"
          >mdi-menu-down</v-icon
        >
        <v-icon v-else color="#9F9F9F">mdi-menu-swap</v-icon>
      </th>
      <!-- <th
        class="bg-gray text-center item_table_nallow_th px-2"
        tr
        v-for="tool in tools"
        :key="tool.id"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ tool.name }}</span>
          </template>
          {{ tool.name }}システムの稼働状況
        </v-tooltip>
      </th> -->
    </tr>
  </thead>
</template>

<script>
export default {
  data: () => ({}),
  components: {},
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  props: [
    "checkedAll",
    "headers",
    "order",
    "direction",
    "tools",
    "columnNameFullAuto",
  ],
  methods: {},
};
</script>

<style scoped>
.bg-gray {
  background-color: #f7f7f7 !important;
}

.item_table_nallow_th {
  width: 60px;
}
</style>
