<template>
  <v-row>
    <v-col>
      <v-divider></v-divider>

      <v-row>
        <v-col cols="1" align-self="center">
          <v-img
            :src="'/images/items/favorite.png'"
            max-width="50"
            style="margin-top: 40px"
          />
        </v-col>
        <v-col cols="9">
          <v-card-title style="padding-bottom: 0">お気に入り</v-card-title>
          <v-card-text>
            <br />
            <span v-if="isAvailablePremium()">
              【レイアウト・デザイン設定】にて【おまかせ機能レイアウト】へ設置後に表示されます。
            </span>
            <span v-else-if="user.plan.code === 'silver'">
              おまかせ機能ではご利用になれません。【PC商品一覧】【スマホ商品一覧】にて商品毎に設定が必要です。<br />
              ※ゴールドプラン/プラチナプランは、おまかせ機能でのご利用が可能です。お問い合わせください。
            </span>
            <span v-else> フリープランではご利用になれません。 </span>
            <br />
          </v-card-text>
        </v-col>
      </v-row>

      <FavoriteLayout
        :user="user"
        :full_auto_layout_id="full_auto_layout_id"
        @select="selectLayout"
      />
    </v-col>
  </v-row>
</template>

<script>
import FavoriteLayout from "./FavoriteLayout.vue";

export default {
  props: ["user", "config"],

  data() {
    return {
      full_auto_layout_id: null,
    };
  },

  watch: {
    config: {
      handler(val) {
        this.full_auto_layout_id = val?.full_auto_layout_id;
      },
      immediate: true,
    },
  },

  components: { FavoriteLayout },

  methods: {
    isAvailablePremium() {
      return this.user["is_available_premium?"];
    },

    selectLayout(val) {
      this.$emit("update", { full_auto_layout_id: val });
    },
  },
};
</script>
