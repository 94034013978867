<template>
  <div>
    <total-amount
      :plan="plan"
      :percent-off="percentOff"
      :initial-cost="initialCost"
      :selected-option-codes="selectedOptionCodes"
      :user="user"
      :unused-time-amount="unusedTimeAmount"
      :remaining-time-amount="remainingTimeAmount"
      :balance="balance"
      :option-total-amount="optionTotalAmount"
    >
    </total-amount>

    <v-divider class="my-3"></v-divider>

    <subscription-billing-info
      :user="user"
      :plan="plan"
      :nextPlan="nextPlan"
      :selectedOptionCodes="selectedOptionCodes"
      :option-total-amount="optionTotalAmount"
      :period-end="periodEnd"
    >
    </subscription-billing-info>
  </div>
</template>

<script>
import axios from "axios";
import TotalAmount from "./TotalAmount.vue";
import SubscriptionBillingInfo from "./SubscriptionBillingInfo.vue";

export default {
  components: { TotalAmount, SubscriptionBillingInfo },

  props: [
    "user",
    "plan",
    "nextPlan",
    "percentOff",
    "initialCost",
    "selectedOptionCodes",
  ],

  computed: {
    optionTotalAmount() {
      let price = 0;

      this.optionsToDisplay.forEach((plans_option) => {
        if (this.selectedOptionCodes.includes(plans_option.option.code)) {
          price += plans_option.amount;
        }
      });

      return price;
    },

    optionsToDisplay() {
      return this.plan.plans_options.filter((plans_option) => {
        if (!this.selectedOptionCodes.includes(plans_option.option.code)) {
          return false;
        }

        if (plans_option.is_available && plans_option.is_showing) {
          return true;
        }

        return false;
      });
    },
  },

  data() {
    return {
      unusedTimeAmount: 0,
      remainingTimeAmount: 0,
      balance: 0,
      periodEnd: null,
    };
  },

  created() {
    if (!this.user.use_stripe) {
      return;
    }

    axios
      .get("/api/v1/shops/retrieve_upcoming_invoice", {
        params: { plan_code: this.plan.code },
      })
      .then((res) => {
        const invoice = res.data.invoice;

        // 旧プランの未使用分
        const unusedTimes = invoice.lines.data.filter(
          (item) => item.amount < 0 && item.type === "invoiceitem"
        );
        this.unusedTimeAmount = unusedTimes.reduce(
          (accumulator, unusedTime) => accumulator + unusedTime.amount,
          0
        );

        // 新プランの日割り計算
        const remainingTime = invoice.lines.data.find(
          (item) => 0 <= item.amount && item.type === "invoiceitem"
        );
        this.remainingTimeAmount = remainingTime?.amount;

        // 残高
        this.balance = invoice.starting_balance;

        // period_end
        const subscription = invoice.lines.data.find(
          (item) => item.type === "subscription"
        );
        const period_end = remainingTime
          ? remainingTime.period.end
          : subscription.period.end;
        this.periodEnd = new Date(period_end * 1000);
      });
  },
};
</script>
