<template>
  <v-overlay v-if="isOpen" :value="isOpen" z-index="300">
    <v-progress-circular indeterminate size="32"></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: 'LoadingModal',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  }
}
</script>
