<template>
  <div>
    <v-row justify="center" class="mb-0">
      <v-col
        cols="11"
        class="step_header"
        :class="{
          finished_step_header: mode == 'finished',
          active_step_header: mode == 'active',
          not_yet_step_header: mode == 'not_yet',
        }"
      >
        <div
          :class="{
            'py-0': true,
            'text-center': true,
            'white--text': true,
            'text-h5': true,
            'font-weight-bold': true,
          }"
        >
          STEP{{ step_number }}
        </div>
      </v-col>
      <v-col
        cols="11"
        class="step_body"
        :class="{
          finished_step_body: mode == 'finished',
          active_step_body: mode == 'active',
          not_yet_step_body: mode == 'not_yet',
        }"
      >
        <div
          class="step_title"
          :class="{
            'text-h6': true,
            'text--primary': true,
            'font-weight-bold': true,
            'pl-3': true,
            'pl-10': step_checkbox,
            finished_step_title: mode == 'finished',
            active_step_title: mode == 'active',
            not_yet_step_title: mode == 'not_yet',
          }"
        >
          {{ step_name }}
        </div>
        <v-divider class="my-3"></v-divider>
        <div v-if="mode == 'active'">
          {{ step_description }}
        </div>
        <div>
          <span v-if="mode == 'finished' && is_last_step"
            >設定が完了しました。ご対応ありがとうございました。</span
          >
          <span v-else-if="mode == 'finished'"
            >STEP{{ step_number }}が完了しました。STEP{{
              step_number + 1
            }}に進みましょう。</span
          >
          <slot></slot>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "step-card",
  mixins: [],
  components: {},
  props: {
    step_number: {
      type: Number,
      required: true,
    },
    step_name: {
      type: String,
      required: true,
    },
    step_checkbox: {
      type: Boolean,
      required: false,
    },
    step_description: {
      type: String,
      required: false,
    },
    is_last_step: {
      type: Boolean,
      required: false,
    },
    mode: {
      type: String,
      required: true,
      validator: function(value) {
        // プロパティの値は、必ずいずれかの文字列でなければならない
        return ["active", "finished", "not_yet"].indexOf(value) !== -1;
      },
    },
  },
  computed: {},
  watch: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.finished_step_header {
  background-color: #c3c3c3;
}
.step_header {
  background-color: #92b126;
}
.finished_step_body {
  background-color: #eeeeee;
}
.step_body {
  position: relative;
  border: solid 2px #92b126;
}
.not_yet_step_body {
  border: solid 2px #eeeeee;
}
</style>
