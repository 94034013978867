<template>
  <v-select
    :items="tools"
    item-text="name"
    item-value="id"
    label="システム選択"
    dense
    outlined
    clearable
    hide-details
    @input="(item) => $emit('tool_selected', getShopsTool(item))"
  >
  </v-select>
</template>

<script>
export default {
  props: ["tools", "value", "shops_tools", "dense"],
  name: "system-select",
  methods: {
    getShopsTool(tool_id) {
      if (tool_id) {
        return this.shops_tools.find((x) => x.tool_id == tool_id).id;
      } else {
        return null;
      }
    },
  },
};
</script>
