// v9 compat packages are API compatible with v8 code
import { initializeApp } from "firebase/app"
import { getAuth, onAuthStateChanged } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDp1Pc3_rjHKI97_VFm6-THm1pESL_AQqY",
  authDomain: "ecai-a4694.firebaseapp.com",
  databaseURL: "https://ecai-a4694.firebaseio.com",
  projectId: "ecai-a4694",
  storageBucket: "ecai-a4694.appspot.com",
  messagingSenderId: "761890115129",
  appId: "1:761890115129:web:1c11848a689f3e340587e8",
};

const firebase = initializeApp(firebaseConfig);

firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, user => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

export default firebase;
