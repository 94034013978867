<template>
  <v-snackbar :value="open" color="success" top text :timeout="-1">
    <slot></slot>
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" color="black" @click="$emit('close')">
        閉じる
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "floating-notification-alert",
  mixins: [],
  components: {},
  props: {
    open: {
      type: Boolean,
      require: true,
    },
    ms: {
      type: [Number],
      require: false,
      default: 5000
    }
  },
  computed: {},
  watch: {
    open: function () {
      if (this.open) {
        setTimeout(this.hide_alert, this.ms);
      } 
    },
  },
  data() {
    return {
      open_alert: false,
    };
  },
  methods: {
    hide_alert(){
      this.$emit("close")
    }
  },
};
</script>

<style scoped>
</style>
