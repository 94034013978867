<template>
  <v-main class="signin">
    <v-container>
      <v-row justify="center">
        <v-col cols="10">
          <v-card class="mx-auto" id="user_registration_card">
            <v-container>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <p class="display-1 text--primary">
                      新しいログインパスワード
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <v-form ref="form" v-model="valid_password">
                      <v-text-field
                        v-model="newPassword"
                        :type="show_pw ? 'text' : 'password'"
                        label="パスワード"
                        filled
                        outlined
                        :append-icon="show_pw ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show_pw = !show_pw"
                        :rules="[rules.password]"
                      ></v-text-field>
                    </v-form>
                  </v-col>
                  <p v-if="error" class="errors">{{ error }}</p>
                </v-row>
                <v-row justify="center">
                  <v-btn
                    @click="change"
                    exactid="sign_in_button"
                    color="primary"
                    :loading="loading"
                    :disabled="!newPassword || loading || !valid_password"
                    >変更
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import firebase from "../../packs/firebase";
import { updatePassword } from "firebase/auth";
import axios from "axios";
import { mapMutations } from "vuex";
import { SET_USER } from "store/mutation-types";

export default {
  async created() {
    await axios.get(`/api/v1/shops/me`).then((res) => {
      this.SET_USER(res.data);
    });
  },

  data: () => ({
    newPassword: null,
    show_pw: false,
    valid_password: false,
    error: "",
    loader: null,
    loading: false,
    rules: {
      password: (value) => {
        const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
        return (
          !value ||
          pattern.test(value) ||
          "パスワードは次の条件を満たしてください。英小文字・英大文字・数字を含む８文字以上"
        );
      },
    },
  }),

  methods: {
    async change() {
      this.loading = true;

      let vue_instance = this;

      const user = await firebase.getCurrentUser();
      await updatePassword(user, this.newPassword)
        .then(() => {
          alert("パスワードを変更しました。");
          vue_instance.newPassword = "";
        })
        .catch((error) => {
          ((code) => {
            switch (code) {
              case "auth/weak-password":
                alert("パスワードは最低6文字以上にしてください。");
              case "auth/requires-recent-login":
                alert(
                  "認証の有効期限が切れました。お手数ですが再度ログインし直して操作をやり直してください。"
                );
            }
          })(error.code);
        });

      this.loading = false;
    },

    ...mapMutations({
      SET_USER,
    }),
  },
};
</script>

<style scoped>
#user_registration_card {
  margin-top: 50px;
  margin-bottom: 50px;
}

#sign_in_button {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
