<template>
  <ecup-modal
    title="R-Loginパスワード 更新"
    @close_this_modal="$emit('close_this_modal')"
  >
    <v-card flat class="mt-5">
      <v-row justify="center">
        <v-col cols="auto">
          <v-img src="/images/components/rms_password_update.gif"></v-img>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="6">
          <v-text-field
            v-model="rmsShopPw"
            label="パスワードを入力"
            outlined
            hide-details="auto"
            filled
            :disabled="result"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            @click:append="show = !show"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row justify="center" v-if="result === true">
        <v-col cols="auto">
          <p>
            <span class="success--text font-weight-bold">
              連携に成功しました！
            </span>
          </p>
        </v-col>
      </v-row>
      <v-row justify="center" v-else-if="result === false">
        <v-col cols="auto">
          <p class="error--text">
            連携に失敗しました。<br />
            ID /
            パスワードが間違っているか、連携設定に失敗している可能性があります。再度ご確認をお願いします。
          </p>
        </v-col>
      </v-row>

      <v-row justify="center" v-if="result">
        <v-col cols="auto">
          <v-btn
            color="primary"
            class="ma-2"
            @click="$emit('close_this_modal', user.shop_code)"
            :loading="loading"
          >
            閉じる
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="center" v-else>
        <v-col cols="auto">
          <v-btn
            color="primary"
            @click="updateRmsPassword()"
            :disabled="!rmsShopPw || loading"
            :loading="loading"
          >
            送信
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <!-- ローディング画面 -->
    <template v-if="loading">
      <v-overlay>
        <div class="text-center text-h5">ID・パスワードを確認中です…</div>
        <div class="text-center mt-15">
          <v-progress-circular
            :size="70"
            :width="7"
            color="white"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-overlay>
    </template>
  </ecup-modal>
</template>

<script>
import axios from "axios";
import EcupModal from "./EcupModal.vue";

export default {
  name: "RmsPasswordUpdate",

  components: { EcupModal },

  props: {
    user: {
      type: Object,
      default: {},
    },

    isAdminShop: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      rmsShopPw: "",
      loading: false,
      result: null,
      show: false,
    };
  },

  methods: {
    async updateRmsPassword() {
      let url = this.isAdminShop ? "/api/v1/jobs/admin_create" : "/api/v1/jobs";

      this.loading = true;

      const data = {
        shop_code: this.user.shop_code,
        operation: "rms_pw_update",
        rms_shop_pw: this.rmsShopPw,
      };

      const res = await axios.post(url, data);

      if (res.status === 201) {
        console.log("created!");

        let count = 0;
        let status = "waiting";
        const id = res.data.id;

        url = this.isAdminShop ? "/api/v1/jobs/admin_show/" : "/api/v1/jobs/";

        while (count < 25 && ["waiting", "operating"].includes(status)) {
          console.log("waiting...");
          await new Promise((resolve) => setTimeout(resolve, 5000));

          let response = await axios.get(url + id, {
            params: { shop_code: this.user.shop_code },
          });
          status = response.data.status.status;
          count++;
        }

        this.loading = false;
        this.result = status === "finished" ? true : false;
      }
    },
  },
};
</script>
