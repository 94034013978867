<template>
  <div class="form-container">
    <form v-if="!successMessage" @submit.prevent="handleSubmit">
      <div class="input-group">
        <label for="select-input">入力項目を選択してください</label>
        <select v-model="selectedInput" id="select-input">
          <option value="orderNumber">注文番号</option>
          <option value="phoneNumber">電話番号</option>
        </select>
      </div>

      <!-- 注文番号入力フィールド -->
      <div v-if="selectedInput === 'orderNumber'" class="input-group">
        <label for="order-number">注文番号入力</label>
        <input 
          type="text" 
          v-model="orderNumber" 
          id="order-number" 
          @input="formatOrderNumber" 
          maxlength="26" 
          placeholder="注文番号を入力" 
        />
      </div>

      <!-- 電話番号入力フィールド -->
      <div v-if="selectedInput === 'phoneNumber'" class="input-group">
        <label for="phone-number">電話番号入力</label>
        <input 
          type="tel" 
          v-model="phoneNumber" 
          id="phone-number" 
          @input="validatePhoneNumber" 
          maxlength="11" 
          placeholder="電話番号を入力"
        />
      </div>

      <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
      <button type="submit">送信する</button>
    </form>

    <div v-if="successMessage" class="success-message">
      <p class="success">{{ successMessage }}</p>
      <button @click="closeWindow">閉じる</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['shopCode', 'accessToken'],
  data() {
    return {
      orderNumber: '',
      phoneNumber: '',
      errorMessage: '',
      successMessage: '', // 送信成功メッセージ用のデータ
      selectedInput: 'orderNumber' // デフォルトで「注文番号」を選択
    };
  },
  methods: {
    handleSubmit() {
      if (this.selectedInput === 'orderNumber' && this.orderNumber) {
        const formattedOrderNumber = this.orderNumber.toString();
        console.log('Order number submitted:', formattedOrderNumber);
        this.sendDataToServer({ orderNumber: formattedOrderNumber });
      } else if (this.selectedInput === 'phoneNumber' && this.phoneNumber) {
        const formattedPhoneNumber = this.phoneNumber.toString();
        console.log('Phone number submitted:', formattedPhoneNumber);
        this.sendDataToServer({ phoneNumber: formattedPhoneNumber });
      } else {
        this.errorMessage = '選択した項目に対応する値を入力してください。';
        return;
      }
    },
    formatOrderNumber() {
      let value = this.orderNumber.replace(/-/g, ''); // ハイフンを一旦削除
      const regex = /^[0-9]*$/; // 半角数字のみ許可

      if (!regex.test(value)) {
        this.errorMessage = '半角数字のみを入力してください。';
        this.orderNumber = value.replace(/[^0-9]/g, ''); // 半角数字以外を削除
        return;
      } else {
        this.errorMessage = '';
      }

      if (value.length > 6) value = value.slice(0, 6) + '-' + value.slice(6);
      if (value.length > 15) value = value.slice(0, 15) + '-' + value.slice(15);

      this.orderNumber = value;
    },
    validatePhoneNumber() {
      const regex = /^[0-9]{0,11}$/; // 半角数字のみ許可、最大11桁
      if (!regex.test(this.phoneNumber)) {
        this.errorMessage = '半角数字で正しい電話番号を入力してください。';
        this.phoneNumber = this.phoneNumber.replace(/[^0-9]/g, ''); // 半角数字以外を削除
        return;
      } else {
        this.errorMessage = '';
      }
    },
    sendDataToServer(data) {
      fetch('/liff_endpoint/callback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({
          ...data, 
          shop_code: this.shopCode,
          accessToken: this.accessToken
        })
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to send data');
        }
        return response.json();
      })
      .then(data => {
        console.log('Data sent successfully', data);
        this.successMessage = '送信に成功しました';
        this.errorMessage = '';
      })
      .catch(err => {
        console.error('Error sending data', err);
        this.errorMessage = '送信に失敗しました。再試行してください。';
        this.successMessage = '';
      });
    },
    closeWindow() {
      window.close();
    }
  },
};
</script>

<style scoped>
.form-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.input-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
}

input,
select,
button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box;
}

button {
  background-color: #00b900;
  color: #fff;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.error {
  color: red;
  margin-bottom: 10px;
}

.success {
  color: green;
  margin-bottom: 10px;
}

/* スマホ画面サイズ用のスタイル */
@media screen and (max-width: 480px) {
  .form-container {
    padding: 10px;
    border: none;
    box-shadow: none;
  }

  label {
    font-size: 14px;
  }

  input,
  select,
  button {
    padding: 8px;
    font-size: 14px;
  }

  button {
    padding: 10px;
  }
}
</style>
