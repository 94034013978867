<template>
  <div>
    <span class="error--text text-body-2">
      <v-row>
        <v-col cols="12" class="px-0">
          <stripe-element-card
            ref="elementRef"
            hidePostalCode
            :pk="pulishableKey"
            @token="tokenCreated"
          />
        </v-col>
      </v-row>

      <v-row v-if="errorMessage">
        <v-col cols="12">
          <v-icon color="error" small>mdi-alert</v-icon>
          {{ errorMessage }}
        </v-col>
      </v-row>
    </span>

    <v-row>
      <v-col cols="12" class="px-0 py-2">
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="loading"
          @click="submit()"
          block
          large
          class="text-button"
        >
          確定
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="px-0 py-2">
        <v-btn
          color="white"
          @click="$emit('back')"
          block
          large
          class="text-button"
        >
          戻る
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="px-0 py-2">
        <span class="text-caption">
          支払い方法を登録することにより、EC-UPの<a
            href="https://ec-up.jp/terms/"
            target="_blank"
            >利用規約</a
          >および
          <a href="https://ec-up.jp/policy/" target="_blank"
            >プライバシーポリシー</a
          >に同意したことになります。
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import { StripeElementCard } from "@vue-stripe/vue-stripe";

export default {
  components: {
    StripeElementCard,
  },

  data() {
    this.pulishableKey = process.env.STRIPE_PUBLISHABLE_KEY;
    return {
      token: null,
      loading: false,
      errorMessage: null,
    };
  },

  methods: {
    submit() {
      this.errorMessage = null;
      // this will trigger the process
      this.$refs.elementRef.submit();
    },

    tokenCreated(token) {
      this.loading = true;
      // handle the token
      // send it to your server
      axios.post("/api/v1/shops/attach_token", token).then((res) => {
        if (res.data.error_message) {
          this.loading = false;
          this.errorMessage = res.data.error_message;
        } else {
          this.$emit("attached");
        }
      });
    },
  },
};
</script>

<style scoped></style>
