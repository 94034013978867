import * as types from "store/mutation-types";

export const state = {
  user: null,
  analysis_data: [],
  isFooterLinksVisible: true,
  userDetails: null,
};

export const mutations = {
  async [types.SET_USER](state, user) {
    state.user = user;
  },
  async [types.SET_RAKUTEN_ITEM_STATS](state, rakuten_item_stats) {
    state.rakuten_item_stats = rakuten_item_stats;
    state.rakuten_item_stats.total = rakuten_item_stats.total_on_sale + rakuten_item_stats.total_sold_out
  },
  async [types.SET_ANALYSIS_DATA](state, analysis_data) {
    state.analysis_data = analysis_data;
  },
  async [types.SET_USER_DETAILS](state, userDetails) {
    state.userDetails = userDetails;
  },
  setFooterLinks(state, isShow) {
    state.isFooterLinksVisible = isShow;
  },
};
