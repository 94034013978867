export default {
  methods: {
    getToolByToolId: function (tools,toolId) {
      return tools.find((element) => element.id == toolId);
    },
    getShopsToolByShopsToolId(shopsTools,ShopsToolId) {
      return shopsTools.find((element) => element.id == ShopsToolId);
    },
    getToolByShopsToolId(tools,shopsTools,shopsToolId){
      return this.getToolByToolId(
        tools,
        this.getShopsToolByShopsToolId(shopsTools,shopsToolId).tool_id
      )
    }
  }
}