<template>
  <v-container fluid class="pa-6" v-if="user">
    <v-row>
      <v-col class="display-1">
        <v-icon left large color="success" style="transform: rotateY(180deg)">
          mdi-comment-text-outline
        </v-icon>
        {{ name }} 商品一覧
      </v-col>
    </v-row>

    <Main
      @fetchUser="fetchUser"
      @setUser="setUser"
      :headers="headers"
      :name="name"
      :targetElement="targetElement"
      :columnNameFullAuto="columnNameFullAuto"
      :columnNameRecentError="columnNameRecentError"
      :columnNameRakutenPageLink="columnNameRakutenPageLink"
      :columnNameRemainingByteSize="columnNameRemainingByteSize"
      :exclusionFieldId="exclusionFieldId"
      :user="user"
    />

    <Guard
      v-if="showGuard"
      @showOptionIndexModal="showOptionIndexModal = true"
      :user="user"
    />

    <OptionIndexModal
      v-if="showOptionIndexModal"
      @close_this_modal="showOptionIndexModal = false"
      :user="user"
    />
  </v-container>
</template>

<script>
import Main from "./components/Main.vue";
import Guard from "./components/Guard.vue";
import OptionIndexModal from "../option/OptionIndexModal.vue";
import userUtilsMixin from "../../packs/mixins/user_utils";

export default {
  name: "ItemForPC",
  components: { Main, Guard, OptionIndexModal },
  mixins: [userUtilsMixin],
  computed: {
    user() {
      return this.$store.state.user;
    },
    showGuard() {
      return !this.user["use_for_pc?"];
    },
  },
  data() {
    return {
      name: "PC用商品説明文",
      targetElement: "descriptionForPC",
      columnNameFullAuto: "is_full_auto_on_ForPC",
      columnNameRecentError: "recent_error_ForPC",
      columnNameRakutenPageLink: "rakuten_page_link",
      columnNameRemainingByteSize: "remaining_byte_size_ForPC",
      exclusionFieldId: ["528", "570"],
      headers: {
        itemName: { name: "商品画像/商品名", description: null, width: "50%" },
        remainingBytesPc: {
          name: "残バイト数",
          description: "残りの目安byte数<br />最大10240byte中",
          width: "30%",
        },
        itemLayout: {
          name: "商品ページ<br />レイアウト",
          description: "",
          width: "30%",
        },
      },
      showOptionIndexModal: false,
    };
  },
  async created() {
    this.fetchUser();
  },
  methods: {
    fetchUser() {
      this.fetch_user();
    },
    setUser(user) {
      this.SET_USER(user);
    },
  },
};
</script>
