<template>
  <div class="mt-5">
    <v-row>
      <v-col cols="12" class="my-2" style="background-color: #eeeeee">
        <div class="text-body-2 ml-5">
          <span>選択ページ：</span>
          <span class="ecup_green_text"
            >{{ shopPageLayout.title }}</span
          >

          <span> ｜　処理：</span>
          <span class="ecup_green_text">{{
            operation
          }}</span>

          <span v-if="shopPageSystem"> ｜　システム：</span>
          <span class="ecup_green_text">{{
            shopPageSystem.name
          }}</span>
        </div>
      </v-col>
    </v-row>

    <v-row justify="center" v-if="shopPageSystem">
      <v-col cols="12" class="my-2 box3">
        <p class="text-body-2" v-html="shopPageSystem.description"></p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "operation-selection-status",
  components: {},
  props: {
    shopPageLayout: { type: Object },
    shopPageSystem: { type: Object },
  },
  computed: {
    operation() {
      if (!this.shopPageLayout || this.shopPageLayout['widget_count_' + this.shopPageSystem.code] === null) {
        return "";
      } else if (this.shopPageLayout['widget_count_' + this.shopPageSystem.code] === 0) {
        return "表示する";
      } else {
        return "更新する＆取り外す";
      }
    },
  },
  watch: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.box3 {
  padding: 0.5em 1em;
  background: #cde4ff; /*背景色*/
}
.box3 p {
  margin: 0;
  padding: 0;
}
</style>
