<template>
  <div>
    <v-row flat justify="center">
      <v-col cols="12" class="px-0">
        <black-rounded-subtitle>システムを選択する</black-rounded-subtitle>
      </v-col>
      <v-col cols="6" v-for="shops_tool in shops_tools" :key="shops_tool.id">
        <v-btn
          v-if="getToolByToolId(tools, shops_tool.tool_id)"
          block
          outlined
          x-large
          color="#86B82C"
          class="ecup_system_modal_system_selection_btn"
          @click="$emit('selected', shops_tool.id)"
        >
          <span class="text-h6">{{
            getToolByToolId(tools, shops_tool.tool_id)["name"]
          }}</span>
        </v-btn>
      </v-col>
      <v-col cols="12" v-if="allow_all_item">
        <v-btn
          block
          outlined
          x-large
          color="#86B82C"
          class="ecup_system_modal_system_selection_btn"
          @click="$emit('selected_all_items')"
          ><span class="text-h6">全システム</span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ToolUtilsMixin from "../../../../packs/mixins/tool_utils";
import BlackRoundedSubtitle from "./BlackRoundedSubtitle.vue";

export default {
  name: "system-selection",
  mixins: [ToolUtilsMixin],
  components: { BlackRoundedSubtitle },
  props: ["shops_tools", "tools", "allow_all_item"],
  computed: {},
  watch: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.ecup_system_modal_system_selection_btn {
  border: 3px solid;
  padding: 30px !important;
}
</style>