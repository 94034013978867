<template>
  <v-container fluid class="pa-6" v-if="user">
    <v-row>
      <v-col class="display-1">
        <v-icon left large color="success"> mdi-cellphone-text </v-icon>
        {{ name }} 商品一覧
      </v-col>
    </v-row>

    <Main
      @fetchUser="fetchUser"
      @setUser="setUser"
      :headers="headers"
      :name="name"
      :targetElement="targetElement"
      :columnNameFullAuto="columnNameFullAuto"
      :columnNameRecentError="columnNameRecentError"
      :columnNameRakutenPageLink="columnNameRakutenPageLink"
      :columnNameRemainingByteSize="columnNameRemainingByteSize"
      :exclusionFieldId="exclusionFieldId"
      :user="user"
    />
  </v-container>
</template>

<script>
import Main from "./components/Main.vue";
import userUtilsMixin from "../../packs/mixins/user_utils";

export default {
  name: "ItemForSmartPhone",
  components: { Main },
  mixins: [userUtilsMixin],
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  data() {
    return {
      name: "スマートフォン用商品説明文",
      targetElement: "descriptionForSmartPhone",
      columnNameFullAuto: "is_full_auto_on",
      columnNameRecentError: "recent_error_ForSmartphone",
      columnNameRakutenPageLink: "smartphone_rakuten_page_link",
      columnNameRemainingByteSize: "remaining_byte_size",
      exclusionFieldId: ["521", "528"],
      headers: {
        itemName: { name: "商品画像/商品名", description: null, width: "50%" },
        remainingBytes: {
          name: "残バイト数",
          description: "残りの目安byte数<br />最大10240byte中",
          width: "30%",
        },
        remainingImages: {
          name: "残画像数",
          description: "残りの画像数<br />最大20枚中",
          width: "30%",
        },
      },
    };
  },
  async created() {
    this.fetchUser();
  },
  methods: {
    fetchUser() {
      this.fetch_user();
    },
    setUser(user) {
      this.SET_USER(user);
    },
  },
};
</script>
