<template>
  <v-main class="signin">
    <v-container>
      <v-row justify="center">
        <v-col cols="10">
          <v-card class="mx-auto" id="user_registration_card">
            <v-container>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <p class="display-1 text--primary">
                      新しいログインメールアドレス
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="mailaddress"
                      type="text"
                      label="メールアドレス"
                      filled
                      outlined
                      validate-on-blur
                      :rules="[rules.email]"
                    ></v-text-field>
                  </v-col>
                  <p v-if="error" class="error--text">{{ error }}</p>
                </v-row>
                <v-row justify="center">
                  <v-btn
                    @click="change"
                    exactid="sign_in_button"
                    color="primary"
                    :loading="loading"
                    :disabled="!mailaddress || loading"
                    >変更
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import firebase from "../../packs/firebase";
import { updateEmail } from "firebase/auth";
import axios from "axios";
import { mapMutations } from "vuex";
import { SET_USER } from "store/mutation-types";

export default {
  async created() {
    await axios.get(`/api/v1/shops/me`).then((res) => {
      this.SET_USER(res.data);
    });
  },

  data: () => ({
    mailaddress: null,
    error: "",
    loader: null,
    loading: false,
    rules: {
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return (
          !value || pattern.test(value) || "メールアドレスが間違っています"
        );
      },
    },
  }),

  methods: {
    async change() {
      this.loading = true;

      let vue_instance = this;

      const user = await firebase.getCurrentUser();
      await updateEmail(user, this.mailaddress)
        .then(() => {
          axios.post(`/api/v1/shops/me`, { email: vue_instance.mailaddress });
          alert("メールアドレスを変更しました。");
          vue_instance.mailaddress = "";
        })
        .catch((error) => {
          console.log(error);
          this.error = ((code) => {
            switch (code) {
              case "auth/email-already-in-use":
                return "既にそのメールアドレスは使われています";
              case "auth/requires-recent-login":
                alert(
                  "認証の有効期限が切れました。お手数ですが再度ログインし直して操作をやり直してください。"
                );
            }
          })(error.code);
        });

      this.loading = false;
    },

    ...mapMutations({
      SET_USER,
    }),
  },
};
</script>

<style scoped>
#user_registration_card {
  margin-top: 50px;
  margin-bottom: 50px;
}

#sign_in_button {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
