<template>
  <ecup-modal
    title="オプション機能を追加しました！"
    title_center="true"
    modal_width="600px"
    @close_this_modal="$emit('close_this_modal')"
  >
    <div class="pt-5">
      <p>オプション機能「{{ option.name }}」のご利用ありがとうございます。</p>
    </div>
    <div v-if="option.code === 'for_pc'">
      <p>
        「PC用販売説明文」と「PC版商品説明文」にシステムの設置ができるようになりました！
      </p>
      <p v-if="!isAdminShop">
        下記「おまかせ機能ON」を選択する事で、AIによる最適なシステム運用をおまかせする事ができます。
      </p>
      <div class="text-center px-6 pt-2 pb-6 mx-n6 mb-n6" v-if="!isAdminShop">
        <v-btn
          color="primary"
          class="mx-2"
          large
          min-width="160px"
          @click="clickFullAutoOn()"
        >
          おまかせ機能ON
        </v-btn>
        <v-btn
          color="grey"
          class="white--text mx-2"
          large
          min-width="160px"
          @click="$emit('close_this_modal')"
        >
          あとで
        </v-btn>
      </div>
    </div>
  </ecup-modal>
</template>

<script>
import EcupModal from "../../components/EcupModal.vue";

export default {
  components: { EcupModal },
  props: {
    option: {
      type: Object,
      default: null,
    },
    isAdminShop: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clickFullAutoOn() {
      this.$emit("closeAllModal");
      this.$router.push({ name: "ConfigFullAuto" }).catch((err) => err);
    },
  },
};
</script>
