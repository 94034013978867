<template>
  <v-row>
    <v-col cols="12">
      <v-btn large block color="white" @click="priceList = true" :elevation="3">
        プラン詳細はこちら
      </v-btn>
    </v-col>

    <v-dialog v-model="priceList" width="auto">
      <v-card>
        <v-card-actions>
          <v-divider></v-divider>
          <v-btn icon fab text x-large @click="priceList = false">
            <v-icon> mdi-close-box </v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <v-img src="/images/plans/price_list.png"></v-img>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "DatailButton",

  data() {
    return {
      priceList: false,
    };
  },
};
</script>
