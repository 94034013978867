<template>
  <v-card>
    <v-card-text class="pa-5">
      <span class="v-align-middle subtitle-1 font-weight-bold">稼働システム</span>
      <span>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="v-align-middle"
              v-bind="attrs"
              v-on="on"
              color="#B8B8B8"
              >mdi-help-circle</v-icon
            >
          </template>
          <div>EC-UPシステムが稼働中の商品数です。</div>
          <!-- <div>EC-UPツールをつけて、画像数を有効活用しましょう！</div> -->
        </v-tooltip>
      </span>
      <apexchart
        ref="runnning_system_bar_appexchart"
        type="bar"
        height="400"
        :options="runnning_system_bar_chart_options"
        :series="runnning_system_bar_chart_series"
      ></apexchart>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "RunningSystemsCard",
  async created() {},
  props: ["number_of_items_group_by_tool", "tools"],
  watch: {
    number_of_items_group_by_tool: function (newVal, oldVal) {
      if (this.tools.length != 0) {
        let setSeries = this.setSeriese();
        this.runnning_system_bar_chart_series[0].data = setSeries;
        this.$refs.runnning_system_bar_appexchart.updateSeries(
          this.runnning_system_bar_chart_series
        );
      }
    },
    tools: function (newVal, oldVal) {
      let option = this.runnning_system_bar_chart_options;
      option.xaxis.categories = this.tools.map((tool) => {
        return tool.name;
      });
      this.$refs.runnning_system_bar_appexchart.updateOptions(option);
      if (this.number_of_items_group_by_tool.length != 0) {
        let setSeries = this.setSeriese();
        this.runnning_system_bar_chart_series[0].data = setSeries;
        this.$refs.runnning_system_bar_appexchart.updateSeries(
          this.runnning_system_bar_chart_series
        );
      }
    },
  },
  data() {
    return {
      runnning_system_bar_chart_options: {
        chart: {
          width: "100%",
          height: 150,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            barHeight: "65%",
            horizontal: true, // 横型のグラフにする場合
            colors: {
              ranges: [
                {
                  from: 0,
                  to: 1000000,
                  color: "#92b125",
                },
              ],
            },
          },
        },
        xaxis: {
          categories: [],
        },
      },
      runnning_system_bar_chart_series: [
        {
          name: "商品数",
          data: [],
        },
      ],
    };
  },
  methods: {
    setSeriese() {
      return this.tools.map((tool) => {
        let tool_count = this.number_of_items_group_by_tool.find((count) => {
          return tool.name == count.name;
        });
        if (tool_count) {
          return tool_count.number_of_runnning_tool;
        } else {
          return 0;
        }
      });
    },
  },
};
</script>
