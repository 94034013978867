<template>
  <div>
    <v-row justify="space-between">
      <v-col cols="auto">お支払い金額</v-col>
      <v-col cols="auto" class="text-h6">
        {{ TotalAmount | positive | toLocaleString }} 円
      </v-col>
    </v-row>

    <div
      class="detail mt-3"
      v-if="
        remainingTimeAmount ||
        unusedTimeAmount ||
        balance ||
        initialCost ||
        optionTotalAmount
      "
    >
      <v-row justify="space-between">
        <v-col cols="auto" class="text-body-2">
          {{ plan.name }}プラン
          <span v-if="remainingTimeAmount">(決済日までの日割り計算)</span>
          <span class="text-caption" v-if="percentOff">
            <br />{{ percentOff }}%の割引きが含まれます
          </span>
        </v-col>
        <v-col cols="auto" class="text-body-2" align-self="center">
          <span v-if="remainingTimeAmount">
            {{ remainingTimeAmount.toLocaleString() }} 円
          </span>
          <span v-else> {{ discountedPrice.toLocaleString() }} 円 </span>
        </v-col>
      </v-row>

      <v-row justify="space-between" v-if="unusedTimeAmount">
        <v-col cols="auto" class="text-body-2 pt-0"> 旧プランの未使用分 </v-col>
        <v-col cols="auto" class="text-body-2 pt-0" align-self="center">
          {{ unusedTimeAmount.toLocaleString() }} 円
        </v-col>
      </v-row>

      <v-row justify="space-between" v-if="balance">
        <v-col cols="auto" class="text-body-2 pt-0"> 残高 </v-col>
        <v-col cols="auto" class="text-body-2 pt-0" align-self="center">
          {{ balance.toLocaleString() }} 円
        </v-col>
      </v-row>

      <v-row justify="space-between" v-if="initialCost">
        <v-col cols="auto" class="text-body-2 pt-0"> 初期費用 </v-col>
        <v-col cols="auto" class="text-body-2 pt-0" align-self="center">
          {{ initialCost.toLocaleString() }} 円
        </v-col>
      </v-row>

      <v-row justify="space-between" v-if="optionTotalAmount">
        <v-col cols="auto" class="text-body-2 pt-0"> オプション機能 </v-col>
        <v-col cols="auto" class="text-body-2 pt-0" align-self="center">
          {{ optionTotalAmount.toLocaleString() }} 円
        </v-col>
      </v-row>

      <v-row justify="space-between">
        <v-col cols="auto" class="pt-0 text-body-2 font-weight-bold">
          合計
        </v-col>
        <v-col
          cols="auto"
          class="pt-0 text-body-2 font-weight-bold"
          align-self="center"
        >
          {{ TotalAmount | positive | toLocaleString }} 円
        </v-col>
      </v-row>
    </div>

    <div v-else-if="percentOff">
      <v-row justify="end">
        <v-col cols="auto" align-self="end" class="text-body-2 py-0">
          {{ percentOff }}%OFFクーポンを利用
        </v-col>
      </v-row>
    </div>

    <div v-if="percentOff">
      <v-row justify="space-between" class="mt-3">
        <v-col cols="auto"> {{ percentOff }}%OFFクーポン枚数 </v-col>
        <v-col cols="auto">
          {{ user[`my_available_off_${percentOff}_coupons_count`] }}枚 →
          {{ user[`my_available_off_${percentOff}_coupons_count`] - 1 }}枚
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: Object,
    plan: Object,
    percentOff: {
      type: Number,
      default: 0,
    },
    initialCost: {
      type: Number,
      default: 0,
    },
    selectedOptionCodes: Array,
    unusedTimeAmount: Number,
    remainingTimeAmount: Number,
    balance: Number,
    optionTotalAmount: Number,
  },

  computed: {
    TotalAmount() {
      return (
        (this.remainingTimeAmount || this.discountedPrice) +
        this.unusedTimeAmount +
        this.balance +
        this.initialCost +
        this.optionTotalAmount
      );
    },

    discountedPrice() {
      return this.plan.amount * ((100 - this.percentOff) / 100);
    },
  },

  filters: {
    positive(value) {
      return 0 < value ? value : 0;
    },

    toLocaleString(value) {
      return value.toLocaleString();
    },
  },
};
</script>

<style scoped>
.detail {
  padding: 1em;
  background-color: #f5f5f5;
  border-radius: 5px;
}
</style>
