<template>
  <v-row justify="center">
    <v-col cols="12" class="notes">
      <v-row>
        <v-col>
          有料プランへご変更される場合、2万商品未満であれば、ダッシュボードのプラン変更より「ゴールドプラン」の選択が可能です。<br />
          2万商品以上の場合、プラチナプランにお申し込みいただく必要があります。<br />
          その際は、下記フォームよりプラチナプランご希望の旨をご連絡くださいませ。<br />
          <br />
          ▼お問い合わせ先<br />
          <a :href="config('HREF_FEEDBACK')" target="_blank">
            {{ config("HREF_FEEDBACK") }}
          </a>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import config from "../../../const";

export default {
  props: ["itemLimit"],

  methods: {
    config(value) {
      return config[value];
    },
  },
};
</script>

<style>
.notes {
  background-color: #eceff1;
  border-radius: 5px;
}
</style>
