<template>
  <div class="mt-5">
    <v-row>
      <v-col cols="12" class="my-2" style="background-color: #eeeeee">
        <div class="text-body-2 ml-5">
          <span>選択商品：</span>
          <span class="ecup_green_text" v-if="editing_target_is_all_items"
            >全{{ $store.state.rakuten_item_stats.total }}商品</span
          >
          <span v-else class="ecup_green_text"
            >{{ checked_items_count }}商品</span
          >

          <span v-if="operation_jp"> ｜　処理：</span>
          <span class="ecup_green_text" v-if="operation_jp">{{
            operation_jp
          }}</span>

          <span v-if="tool_name || is_all_tools"> ｜　システム：</span>
          <span class="ecup_green_text" v-if="is_all_tools">全システム</span>
          <span class="ecup_green_text" v-else-if="tool_name">{{
            tool_name
          }}</span>
        </div>
      </v-col>
    </v-row>

    <v-row justify="center" v-if="tool_description">
      <v-col cols="12" class="my-2 box3">
        <p class="text-body-2" v-html="tool_description"></p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "operation-selection-status",
  components: {},
  props: [
    "total_items_count",
    "checked_items_count",
    "editing_target_is_all_items",
    "operation",
    "tool_name",
    "tool_description",
    "is_all_tools",
  ],
  computed: {
    operation_jp() {
      if (!this.operation) {
        return null;
      } else if (this.operation == "bundle_create") {
        return "表示する";
      } else if (this.operation == "bundle_delete") {
        return "取り外す";
      }
      return null;
    },
  },
  watch: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.box3 {
  padding: 0.5em 1em;
  background: #cde4ff; /*背景色*/
}
.box3 p {
  margin: 0;
  padding: 0;
}
</style>
