<template>
  <v-menu
    bottom
    offset-y
    transition="slide-y-transition"
    :close-on-content-click="false"
    v-model="noticesCard"
  >
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
            <v-badge
              :value="unreadNotices.length"
              color="error"
              bordered
              dot
              overlap
            >
              <v-icon> mdi-email-outline </v-icon>
            </v-badge>
          </v-btn>
        </template>
        <span>メール</span>
      </v-tooltip>
    </template>

    <v-card width="450" height="600">
      <v-toolbar flat>
        <v-btn icon @click="selectedNotice = null" v-if="selectedNotice">
          <v-icon> mdi-chevron-left </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="
            noticesCard = false;
            selectedNotice = null;
          "
        >
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-toolbar>

      <div v-if="selectedNotice" class="srcollbar">
        <v-card-title>{{ selectedNotice.title }}</v-card-title>
        <v-card-subtitle>
          {{ time_ymdt_format(selectedNotice.created_at) }}
        </v-card-subtitle>
        <v-card-text
          v-html="selectedNotice.body"
          v-if="selectedNotice.is_html"
        ></v-card-text>
        <v-card-text
          v-else
          style="white-space: pre-wrap; word-wrap: break-word"
        >
          {{ selectedNotice.body }}
        </v-card-text>
      </div>

      <v-list flat v-else-if="notices.length" class="srcollbar">
        <v-list-item
          two-line
          color="#2196f3"
          :input-value="unreadNotices.includes(notice)"
          @click="selectNotice(notice)"
          v-for="(notice, index) in notices"
          :key="index"
        >
          <v-list-item-avatar>
            <v-icon v-if="unreadNotices.includes(notice)">mdi-email</v-icon>
            <v-icon v-else>mdi-email-open</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ notice.title }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ time_ymdt_format(notice.created_at) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <div v-else>
        <v-card-subtitle>お知らせはありません</v-card-subtitle>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import axios from "axios";
import timeUtilsMixin from "../../../packs/mixins/time_utils";

export default {
  created() {
    this.getNotices();
  },

  mixins: [timeUtilsMixin],

  data() {
    return {
      noticesCard: false,
      notices: [],
      unreadNotices: [],
      selectedNotice: null,
    };
  },

  methods: {
    getNotices() {
      axios.get("/api/v1/shops/notices").then((res) => {
        this.notices = res.data.notices;
        this.unreadNotices = this.notices.filter((notice) => {
          return !res.data.read_notice_ids.includes(notice.id);
        });
      });
    },

    selectNotice(notice) {
      this.selectedNotice = notice;
      if (this.unreadNotices.includes(notice)) {
        axios
          .post(`/api/v1/shops/confirm_notice`, { notice_id: notice.id })
          .then(() => {
            this.getNotices();
          });
      }
    },
  },
};
</script>

<style>
.srcollbar {
  height: 536px;
  overflow: auto;
}
</style>
