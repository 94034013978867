<template>
  <modal>
    <v-card class="modal-container">
      <v-card-title class="justify-center">
        <v-img
          src="/images/logo/ec-up_setLR_BIG_trans.gif"
          max-height="100"
          max-width="150"
        ></v-img>
      </v-card-title>

      <v-card-text class="text-center grey--text text--darken-3">
        <v-row justify="center">
          <v-col cols="auto">
            <p class="text-subtitle-1 font-weight-bold">
              EC-UPのご登録ありがとうございました！
            </p>
            <p class="text-h6">
              ご利用の準備が整いました。<br />
              メニュー「商品一覧」より<br />
              商品ごとに設定を進めましょう。
            </p>
            <p class="text-caption">
              ※おまかせ機能ONのショップさまは、AIによる自動運用となりますので、<br />
              商品ごとの設定は一切不要です。
            </p>
            <p class="text-caption">
              ▼スライド画像で、EC-UPの使い方をご説明しております
            </p>
            <v-carousel show-arrows-on-hover hide-delimiters height="309">
              <v-carousel-item
                v-for="(item, i) in items"
                :key="i"
                :src="item.src"
              ></v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="6">
            <v-btn
              large
              block
              color="primary"
              @click="startTrial()"
              :loading="loading"
              :disabled="loading"
            >
              閉じる
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </modal>
</template>

<script>
import axios from "axios";
import Modal from "../components/Modal.vue";

export default {
  components: { Modal },
  data() {
    return {
      items: [
        {
          src: "/images/modals/instructions/first_login/01.gif",
        },
        {
          src: "/images/modals/instructions/02.png",
        },
        {
          src: "/images/modals/instructions/first_login/03.png",
        },
        {
          src: "/images/modals/instructions/first_login/04.png",
        },
        {
          src: "/images/modals/instructions/first_login/05.png",
        },
      ],
      loading: false,
    };
  },

  methods: {
    async startTrial() {
      this.loading = true;
      await axios
        .post("/api/v1/shops/me", { completed_guidance: 1 })
        .then((res) => {
          this.$emit("close", res.data);
        });
    },
  },
};
</script>

<style scoped>
.modal-container {
  max-width: 600px;
}
</style>
