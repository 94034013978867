<template>
  <v-card-text class="pa-5">
    <v-row class="d-flex justify-space-between align-center">
      <v-col class="text-right">
        <v-btn
          height="20"
          class="text-no-wrap rounded-pill white--text align-center"
          :class="isFullAutoOn ? 'blue' : 'secondary'"
          @click="updateUserConfig(!isFullAutoOn)"
        >
          <v-icon small class="mr-1">mdi-refresh-auto</v-icon>
          {{ isFullAutoOn ? "おまかせON" : "おまかせOFF" }}
        </v-btn>
      </v-col>
    </v-row>

    <div class="text-right py-3 pr-3">
      <span class="text-h4">{{ number_of_running_items }}/</span>
      <span class="text-h6">{{ number_of_items }}</span
      ><span class="ml-1 text-subtitle-1">商品</span>
    </div>

    <v-row>
      <v-col>
        <div v-if="user.updating_item_info_started_at">
          <v-icon color="success">mdi-checkbox-marked-circle</v-icon>
          <span
            >最新の定期更新 :
            {{ time_ymdt_format(user.updating_item_info_started_at) }}
          </span>
        </div>
      </v-col>
    </v-row>

    <v-expansion-panels v-model="panel">
      <v-expansion-panel>
        <v-expansion-panel-header
          v-if="!isShow"
          @click="showDetails()"
          class=""
          expand-icon=""
          :eager="true"
        >
          <v-btn>詳細を開く</v-btn>
        </v-expansion-panel-header>

        <v-expansion-panel-content class="status-table">
          <hr class="my-6 grey--text" style="border-style: dashed" />

          <v-row>
            <v-col class="pb-1" cols="12">
              <p class="black--text mb-1">稼働状況</p>
            </v-col>

            <v-col class="py-1" cols="6">
              <router-link
                :to="{
                  name: `Item${descriptionKey}`,
                  query: { filter: 'running' },
                }"
              >
                <v-row align="center">
                  <v-col class="text-right" cols="4">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="pl-3"
                          color="success"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-checkbox-marked-circle
                        </v-icon>
                      </template>
                      <div>稼働中</div>
                    </v-tooltip>
                  </v-col>

                  <v-col
                    cols="8"
                    class="black--text text-h6 text-right font-weight-regular pl-0"
                  >
                    {{ number_of_running_items }}
                    <small class="pl-1" style="font-size: 12px">商品</small>
                  </v-col>
                </v-row>
              </router-link>
            </v-col>

            <v-col class="py-1" cols="6">
              <v-row>
                <v-col class="text-right" cols="4">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="pl-3"
                        color="#A1A1A1"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-check-circle-outline
                      </v-icon>
                    </template>
                    <div>未稼働</div>
                  </v-tooltip>
                </v-col>

                <v-col
                  cols="8"
                  class="black--text text-h6 text-right font-weight-regular pl-0"
                >
                  {{ number_of_not_running_items }}
                  <small class="pl-1" style="font-size: 12px">商品</small>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-1" cols="6">
              <v-row align="center">
                <v-col class="text-right" cols="4">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="pl-3"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-autorenew
                      </v-icon>
                    </template>
                    <div>更新中</div>
                  </v-tooltip>
                </v-col>

                <v-col
                  cols="8"
                  class="black--text text-h6 text-right font-weight-regular pl-0"
                >
                  {{ number_of_configuring_items }}
                  <small class="pl-1" style="font-size: 12px">商品</small>
                </v-col>
              </v-row>
            </v-col>

            <v-col class="py-1" cols="6">
              <router-link
                :to="{
                  name: `Item${descriptionKey}`,
                  query: { only_error: true },
                }"
              >
                <v-row>
                  <v-col class="text-right" cols="4">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="pl-3"
                          color="error"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-alert-octagon
                        </v-icon>
                      </template>
                      <div>更新停止</div>
                    </v-tooltip>
                  </v-col>

                  <v-col
                    cols="8"
                    class="black--text text-h6 text-right font-weight-regular pl-0"
                  >
                    {{ number_of_error_items }}
                    <small class="pl-1" style="font-size: 12px">商品</small>
                  </v-col>
                </v-row>
              </router-link>
            </v-col>
          </v-row>

          <hr class="my-6 grey--text" style="border-style: dashed" />

          <v-row>
            <v-col class="pb-1" cols="12">
              <p class="black--text mb-1">システム稼働数</p>
            </v-col>
          </v-row>

          <v-row v-for="i in Math.ceil(tools.length / 2.0)" :key="i">
            <v-col class="py-1" cols="6">
              <v-row align="center">
                <v-col class="text-right" cols="4">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-img
                        v-bind="attrs"
                        v-on="on"
                        max-width="38"
                        :src="`/images/items/${tools[(i - 1) * 2].name_en}.png`"
                      ></v-img>
                    </template>
                    <span>{{ tools[(i - 1) * 2].name }}</span>
                  </v-tooltip>
                </v-col>

                <v-col
                  cols="8"
                  class="black--text text-h6 text-right font-weight-regular pl-0"
                >
                  {{ getNumberOfRunningTool(tools[(i - 1) * 2].name) }}
                  <small class="pl-1" style="font-size: 12px">商品</small>
                </v-col>
              </v-row>
            </v-col>

            <v-col class="py-1" cols="6" v-if="tools[(i - 1) * 2 + 1]">
              <v-row>
                <v-col class="text-right" cols="4">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-img
                        v-bind="attrs"
                        v-on="on"
                        max-width="38"
                        :src="`/images/items/${
                          tools[(i - 1) * 2 + 1].name_en
                        }.png`"
                      ></v-img>
                    </template>
                    <span>{{ tools[(i - 1) * 2 + 1].name }}</span>
                  </v-tooltip>
                </v-col>

                <v-col
                  cols="8"
                  class="black--text text-h6 text-right font-weight-regular pl-0"
                >
                  {{ getNumberOfRunningTool(tools[(i - 1) * 2 + 1].name) }}
                  <small class="pl-1" style="font-size: 12px">商品</small>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <hr class="my-6 grey--text" style="border-style: dashed" />

          <v-row>
            <v-col class="pb-1" cols="12">
              <p class="black--text mb-1">商品ページリソース利用状況</p>
            </v-col>

            <v-col class="pt-1 pb-0 text-center black--text" cols="6">
              バイト数
            </v-col>
            <v-col
              class="pt-1 pb-0 text-center black--text"
              cols="6"
              v-if="countImages"
            >
              画像数
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="py-1 text-center black--text text-h5 font-weight-regular"
              cols="6"
            >
              {{ bytesizeUsed }}
              <small class="pl-1" style="font-size: 12px">%</small>
            </v-col>
            <v-col
              class="py-1 text-center black--text text-h5 font-weight-regular"
              cols="6"
              v-if="countImages"
            >
              {{ imagesUsed }}
              <small class="pl-1" style="font-size: 12px">%</small>
            </v-col>
          </v-row>

          <v-row class="mt-3 grey--text">
            <v-col class="py-1 text-center" cols="6">
              {{ bytesyze_used | toKb | commaSeparated }}
              / {{ total_bytes | toKb | commaSeparated }} Kbyte
            </v-col>
            <v-col class="py-1 text-center" cols="6" v-if="countImages">
              {{ images_used }}/ {{ total_images }} 枚
            </v-col>
          </v-row>

          <v-row class="mt-0 grey--text">
            <v-col class="py-1 text-center" cols="6">
              全ショップ平均 55%
            </v-col>
            <v-col class="py-1 text-center" cols="6" v-if="countImages">
              全ショップ平均 62%
            </v-col>
          </v-row>
        </v-expansion-panel-content>

        <v-expansion-panel-header
          v-if="isShow"
          @click="isShow = false"
          class=""
          expand-icon=""
          :eager="true"
        >
          <v-btn>閉じる</v-btn>
        </v-expansion-panel-header>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card-text>
</template>

<script>
import timeUtilsMixin from "../../../../packs/mixins/time_utils";
import axios from "axios";
import { mapMutations } from "vuex";
import { SET_USER } from "store/mutation-types";

export default {
  props: {
    descriptionKey: { class: String, required: true },
    fullAutoColumnName: { class: String, required: true },
    countImages: { class: Boolean, default: false },
  },

  mixins: [timeUtilsMixin],

  data() {
    return {
      panel: 1,
      number_of_items: 0,
      number_of_running_items: 0,
      //稼働状況
      number_of_not_running_items: 0,
      number_of_configuring_items: 0,
      number_of_error_items: 0,
      // システム稼働数
      tools: [],
      number_of_items_group_by_tool: [],
      // 商品ページリソース利用状況
      bytesyze_remaining: 0,
      bytesyze_used_by_shop: 0,
      bytesyze_used_by_ecup: 0,
      bytesyze_used: 0,
      images_used_by_ecup: 0,
      images_used_by_shop: 0,
      images_used: 0,
      images_remaining: 0,
      isShow: false,
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },

    isFullAutoOn() {
      return this.user?.[this.fullAutoColumnName] || false;
    },

    bytesizeUsed() {
      return Math.round((this.bytesyze_used / this.total_bytes) * 100) || 0;
    },

    imagesUsed() {
      return Math.round((this.images_used / this.total_images) * 100) || 0;
    },

    total_bytes() {
      return (
        this.bytesyze_used_by_ecup +
        this.bytesyze_used_by_shop +
        this.bytesyze_remaining
      );
    },

    total_images() {
      return this.images_used + this.images_remaining;
    },
  },

  filters: {
    toKb: function (value) {
      return parseInt(value / 1024);
    },
    commaSeparated: function (value) {
      return value.toLocaleString();
    },
  },

  watch: {
    user: async function (newVal, oldVal) {
      if (newVal) {
        this.set_ecup_status_stats();
      }
    },
  },

  created() {
    this.set_ecup_status_stats();

    axios
      .get("/api/v1/tools", {
        params: { target: `description${this.descriptionKey}` },
      })
      .then((response) => {
        this.tools = response.data.tools;
      });
  },

  methods: {
    async set_ecup_status_stats() {
      let count_res = await axios.get("/api/v1/items/ecup_status_stats", {
        params: { code: this.descriptionKey },
      });

      this.number_of_items = count_res.data.number_of_items;
      this.number_of_running_items = count_res.data.number_of_running_items;

      this.number_of_not_running_items =
        count_res.data.number_of_not_running_items;
      this.number_of_configuring_items =
        count_res.data.number_of_configuring_items;
      this.number_of_error_items = count_res.data.number_of_error_items;
      this.number_of_items_group_by_tool =
        count_res.data.number_of_items_group_by_tool;
    },

    async updateUserConfig(is_full_auto_on) {
      let res = await axios.post("/api/v1/shops/me", {
        [this.fullAutoColumnName]: is_full_auto_on,
      });

      this.SET_USER(res.data);
    },

    // 商品ページリソース利用状況
    async set_rakuten_resources_stats() {
      let res = await axios.get(
        "/api/v1/items/comsumption_stats_of_rakten_resources",
        { params: { code: this.descriptionKey } }
      );

      this.bytesyze_remaining = res.data.bytesyze_remaining;
      this.bytesyze_used_by_shop = res.data.bytesyze_used_by_shop;
      this.bytesyze_used = res.data.bytesyze_used;
      this.bytesyze_used_by_ecup = res.data.bytesyze_used_by_ecup;

      if (this.countImages) {
        this.images_used = res.data.images_used;
        this.images_used_by_ecup = res.data.images_used_by_ecup;
        this.images_used_by_shop = res.data.images_used_by_shop;
        this.images_remaining = res.data.images_remaining;
      }
    },

    showDetails() {
      this.isShow = true;
      this.set_rakuten_resources_stats();
    },

    ...mapMutations({
      SET_USER,
    }),

    getNumberOfRunningTool(toolName) {
      const toolData = this.number_of_items_group_by_tool.find(
        (data) => data.name === toolName
      );

      return toolData ? toolData.number_of_running_tool : "0";
    },
  },
};
</script>
<style scoped>
.v-expansion-panel:before {
  box-shadow: none !important;
}

.v-expansion-panel-content {
  margin: 0 -24px !important;
  padding: 0 !important;
}
</style>
