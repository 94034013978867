<template>
  <v-row justify="center">
    <v-col cols="auto">
      <v-carousel show-arrows-on-hover hide-delimiters height="420">
        <v-carousel-item
          v-for="(item, i) in items"
          :key="i"
          :src="item.src"
        ></v-carousel-item>
      </v-carousel>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          src: "/images/modals/instructions/01.gif",
        },
        {
          src: "/images/modals/instructions/02.png",
        },
        {
          src: "/images/modals/instructions/03.png",
        },
        {
          src: "/images/modals/instructions/04.png",
        },
        {
          src: "/images/modals/instructions/05.png",
        },
        {
          src: "/images/modals/instructions/06.png",
        },
        {
          src: "/images/modals/instructions/07.gif",
        },
        {
          src: "/images/modals/instructions/08.png",
        },
        {
          src: "/images/modals/instructions/09.png",
        },
        {
          src: "/images/modals/instructions/10.png",
        },
        {
          src: "/images/modals/instructions/11.png",
        },
        {
          src: "/images/modals/instructions/12.png",
        },
      ],
    };
  },
};
</script>
<style scoped></style>
