<template>
  <v-row no-gutters v-if="isShow" align="center">
    <v-col cols="6">
      <apexchart
        type="bar"
        height="200px"
        :options="options"
        :series="series"
      ></apexchart>
    </v-col>
    <v-col cols="6" class="mt-n10" align="center">
      <div class="text-h6 grey--text text--darken-2 font-weight-bold pb-6">
        {{ effectType }}
      </div>
      <div class="red--text text-h3">
        {{ discription }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
const effectType = "一日あたり受注数[PC]";
export default {
  name: "PcOrdersPerDay",
  data() {
    return {
      isShow: false,
      effectType: "",
      discription: "",
      options: {
        chart: {
          offsetX: 0,
          offsetY: 0,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            distributed: true,
            horizontal: false,
            columnWidth: "50%",
            dataLabels: {
              position: "top",
            },
          },
        },
        colors: ["#C4C4C4", "#f44336"],
        title: {
          align: "center",
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ["#000", "#000"],
          },
          offsetY: -30,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: ["ECUP利用前", "ECUP利用後"],
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
        grid: {
          show: false,
        },
      },
      series: [
        {
          data: [],
        },
      ],
    };
  },
  created() {
    const data = this.$store.getters.analysis_data(effectType);
    if (data) {
      this.setData(data);
    }
  },
  watch: {
    analysisData(newVal) {
      if (newVal) {
        this.setData(newVal);
      }
    },
  },
  computed: {
    analysisData() {
      return this.$store.getters.analysis_data(effectType);
    },
  },

  methods: {
    setData(val) {
      const data = val;
      this.options.dataLabels.formatter = (val, opt) => {
        return val + data.unit;
      };
      const effectPercentage = data.achievement_ratio;

      this.series[0].data = [data.comparison_effect, data.target_effect];
      this.effectType = data.effect_type;
      this.isShow = true;
      this.discription = effectPercentage + "%UP!";
    },
  },
};
</script>
