<template>
  <div>
    <v-row>
      <v-col cols="auto" class="text-h6 font-weight-bold"> 自動継続課金 </v-col>
    </v-row>

    <v-row>
      <v-col cols="auto" class="py-0"> 次回のお支払い金額 </v-col>
    </v-row>

    <v-row justify="end" align="center">
      <v-col cols="auto" class="py-0 text-body-2"> 通常価格： </v-col>
      <v-col cols="3" class="py-0 text-end">
        {{ (planToDisplay.amount + optionTotalAmount) | toLocaleString }} 円
      </v-col>
    </v-row>

    <v-row justify="end" align="center" v-if="planToDisplay.code !== 'platinum'">
      <v-col cols="auto" class="py-0 text-body-2">
        50%OFFクーポンをお持ちの場合：
      </v-col>
      <v-col cols="3" class="py-0 text-end">
        {{ (planToDisplay.amount * 0.5 + optionTotalAmount) | toLocaleString }} 円
      </v-col>
    </v-row>

    <v-row justify="end" align="center" v-if="planToDisplay.code !== 'platinum'">
      <v-col cols="auto" class="py-0 text-body-2">
        10%OFFクーポンをお持ちの場合：
      </v-col>
      <v-col cols="3" class="py-0 text-end">
        {{ (planToDisplay.amount * 0.9 + optionTotalAmount) | toLocaleString }} 円
      </v-col>
    </v-row>

    <v-row justify="space-between" class="mt-5" v-if="periodEnd">
      <v-col cols="auto"> 次回決済日</v-col>
      <v-col cols="auto">
        {{ time_ymd_format(periodEnd) }}
      </v-col>
    </v-row>

    <v-row>
      <v-col class="text-caption grey--text text--darken-2 font-weight-light">
        毎月同日が決済日となり、自動継続課金となります。<br />
        有料プランから無料プランへの変更は「プラン変更」から、解約についてはページ下部「退会について」から行えます。
      </v-col>
    </v-row>
  </div>
</template>

<script>
import timeUtilsMixin from "../../../../packs/mixins/time_utils";

export default {
  mixins: [timeUtilsMixin],

  props: [
    "user",
    "plan",
    "nextPlan",
    "selectedOptionCodes",
    "optionTotalAmount",
    "periodEnd",
  ],

  computed: {
    planToDisplay() {
      return this.nextPlan || this.plan;
    },
  },

  filters: {
    toLocaleString(value) {
      return value.toLocaleString();
    },
  },
};
</script>
