<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" class="px-0">
        <black-rounded-subtitle>デザインを選択する</black-rounded-subtitle>
      </v-col>
      <v-col cols="12">
        <v-select
          :items="layouts"
          item-value="id"
          :item-text="
            (layout) =>
              !isAvailablePremium && layout.is_premium
                ? layout.name + '　※ゴールド以上限定'
                : layout.name
          "
          :value="selected_layout.id"
          @change="$emit('layout_selected', $event)"
          filled
          outlined
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="auto" class="error--text pa-0" v-if="errorMessage">
        {{ errorMessage }}
      </v-col>
      <v-col cols="auto" align-self="auto" style="text-align: center">
        <img
          class="preview"
          :src="selected_layout.preview_image_url"
          v-if="selected_layout != null"
        />
      </v-col>
    </v-row>
    <v-row justify="center">
      <div class="body-1">※見え方は端末やブラウザによって異なります</div>
    </v-row>
    <v-row justify="center" class="mt-3">
      <v-col cols="12" class="px-0">
        <black-rounded-subtitle>表示箇所を選択する</black-rounded-subtitle>
      </v-col>
      <v-col cols="10" class="px-0">
        <v-radio-group
          :value="position"
          @change="$emit('position_selected', $event)"
          column
        >
          <div style="display: inline-flex; justify-content: space-between">
            <div
              class="position_selection_boxies"
              @click="$emit('position_selected', 'top')"
            >
              <v-img width="270px" :src="imgSrcTop()"></v-img>
              <div class="ml-3 mb-3">
                <v-radio
                  label="上部"
                  color="blue"
                  value="top"
                  class="mx-auto"
                ></v-radio>
              </div>
            </div>
            <div
              class="position_selection_boxies"
              @click="$emit('position_selected', 'bottom')"
            >
              <v-img width="270px" :src="imgSrcBottom()"></v-img>
              <div class="ml-3 mb-3">
                <v-radio
                  label="下部"
                  color="blue"
                  value="bottom"
                  selected
                ></v-radio>
              </div>
            </div>
          </div>
        </v-radio-group>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BlackRoundedSubtitle from "./BlackRoundedSubtitle.vue";

export default {
  name: "design-selection",
  mixins: [],
  components: {
    BlackRoundedSubtitle,
  },
  props: ["layouts", "selected_layout", "position", "errorMessage", "element"],
  computed: {
    user() {
      return this.$store.state.user;
    },
    isAvailablePremium() {
      return this.user["is_available_premium?"];
    },
  },
  watch: {},
  data() {
    return {};
  },
  methods: {
    imgSrcTop() {
      switch (this.element) {
        case "descriptionForSmartPhone":
          return "/images/items/position/sp/top.png";
        case "descriptionBySalesMethod":
        case "descriptionForPC":
          return "/images/items/position/pc/top.png";
      }
    },

    imgSrcBottom() {
      switch (this.element) {
        case "descriptionForSmartPhone":
          return "/images/items/position/sp/bottom.png";
        case "descriptionBySalesMethod":
        case "descriptionForPC":
          return "/images/items/position/pc/bottom.png";
      }
    },
  },
};
</script>

<style scoped>
.position_selection_boxies {
  border: 3px solid #86b82c;
}
.preview {
  max-width: 450px;
  max-height: 1000px;
}
</style>
