<template>
  <ecup-modal
    title="オプション機能 追加/変更"
    @close_this_modal="$emit('close_this_modal')"
    modal_width="920px"
  >
    <!-- show -->
    <v-row justify="center" class="mt-3">
      <v-col cols="12">
        <v-card outlined>
          <v-card-title>
            <v-icon x-large left :color="option.icon_color">
              {{ option.icon }}
            </v-icon>
            <span
              class="text-h4 grey--text text--darken-4 font-weight-medium pl-2"
            >
              {{ option.name }}
            </span>

            <v-spacer></v-spacer>

            <span class="grey--text text--darken-2 body-2 font-weight-medium">
              ※{{ isFree | formatIsFree }}オプション
            </span>
            <v-spacer></v-spacer>
            <span class="grey--text text--darken-2 body-2 font-weight-medium">
              シルバー：＋{{ amount("silver").toLocaleString() }}円<br />
              ゴールド / プラチナ：＋{{ amount("gold").toLocaleString() }}円
            </span>

            <v-spacer></v-spacer>

            <v-tooltip bottom :disabled="!Boolean(option.note)">
              <template v-slot:activator="{ on, attrs }">
                <v-col
                  cols="auto"
                  class="d-flex justify-end pl-5"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-switch
                    v-model="option.is_enabled"
                    inset
                    class="pt-0 mt-0 d-inline-block"
                    hide-details
                    @change="switchOption($event)"
                    :loading="switchLoading"
                    :disabled="Boolean(option.note)"
                  >
                  </v-switch>
                </v-col>
              </template>
              <span v-html="option.note"></span>
            </v-tooltip>

            <span
              v-if="option.is_enabled"
              class="light-blue--text text--darken-1 font-weight-bold"
            >
              ON
            </span>
            <span v-else class="grey--text text--lighten-1 font-weight-bold">
              OFF
            </span>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col class="col-12">
                <p class="grey--text text--darken-3 mb-3">
                  {{ option.description }}
                </p>

                <v-row>
                  <v-col class="col-8 offset-2">
                    <span class="caption">{{ option.settingExplanation }}</span>
                    <v-carousel
                      id="rakuten_api_access_permit_carousel"
                      :show-arrows="true"
                      show-arrows-on-hover
                      height="340px"
                      class="mx-auto bg-gray"
                      hide-delimiters
                    >
                      <v-carousel-item
                        v-for="(image, i) in option.procedure_images"
                        :key="i"
                        :src="image.src"
                      ></v-carousel-item>
                    </v-carousel>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>

          <div v-if="option.code === 'summary_mail' && !option.disabled">
            <v-card-actions class="justify-center">
              <v-btn
                large
                @click="sendSample()"
                :loading="sendSampleLoading"
                :disabled="sentSample"
                v-if="user.rms_registration_status === 0"
              >
                サンプルメールを送信する
              </v-btn>

              <v-btn
                large
                color="primary"
                class="ml-5"
                @click="configSummaryMail()"
                :disabled="!canConfigSummaryMail"
              >
                サマリーメールの利用設定を行う
              </v-btn>
            </v-card-actions>
          </div>

          <ShopTopButton
            v-if="option.code === 'shop_top'"
            :user="user"
            :option="option"
            :isAdminShop="isAdminShop"
            @success="$emit('setUser', $event)"
          />

          <v-card-text>
            <div v-if="option.code === 'summary_mail'">
              <div v-if="user.rms_registration_status === 1">
                <p class="text-center success--text font-weight-bold">
                  サマリーメールの利用設定
                  <span class="text-h6 font-weight-bold">前半</span>
                  が完了しました！
                </p>
                <p class="text-center">
                  スタッフの追加ありがとうございました。<br />
                  EC-UPにて1営業日以内に確認作業を行います。<span
                    class="error--text"
                    >後半は確認作業後に実施可能になります。</span
                  ><br />
                  ウインドウ右上の<v-icon>mdi-close-box</v-icon>をクリックして完了メールをお待ちください。
                </p>
              </div>
              <div v-else-if="user.rms_registration_status === 2">
                <p class="text-center success--text font-weight-bold">
                  <span class="font-weight-bold"
                    >お待たせいたしました、EC-UPでの確認作業が完了しました。</span
                  >
                </p>
                <p class="text-center">
                  <v-btn depressed small readonly color="primary" class="px-1"
                    >サマリーメールの利用設定を行う</v-btn
                  >
                  より<b>EC-UPスタッフの権限設定</b>をしていただき、<br />
                  <b>R-LoginのID・パスワードを送信して</b
                  >、サマリーメールの利用設定を完了しましょう。<br />
                </p>
              </div>
              <p
                class="text-center success--text font-weight-bold"
                v-else-if="user.rms_registration_status === 3"
              >
                サマリーメールの利用設定が完了しました！
              </p>
            </div>

            <p
              class="text-center success--text font-weight-bold"
              v-if="option.code === 'shop_top' && !Boolean(option.note)"
            >
              設定が完了しました！右上のスイッチからオプションのON/OFFが可能です。
            </p>

            <v-divider class="my-5"></v-divider>

            <v-row>
              <v-col cols="4" class="d-flex justify-center align-center">
                <span class="body-2 pr-2">オススメ度</span>
                <v-rating
                  v-model="option.osusumeRating"
                  background-color="orange lighten-3"
                  color="orange"
                  size="20"
                  class="shorten"
                ></v-rating>
              </v-col>
              <v-col cols="4" class="d-flex justify-center align-center">
                <span class="body-2 pr-2">売上貢献度</span>
                <v-rating
                  v-model="option.uriageRating"
                  background-color="orange lighten-3"
                  color="orange"
                  size="20"
                  class="shorten"
                ></v-rating>
              </v-col>
              <v-col cols="4" class="d-flex justify-center align-center">
                <span class="body-2 pr-2">作業時間削減度</span>
                <v-rating
                  v-model="option.sakugenRating"
                  background-color="orange lighten-3"
                  color="orange"
                  size="20"
                  class="shorten"
                ></v-rating>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- /show -->

    <create-option-confirm
      v-if="showCreateOptionConfirm"
      @close_this_modal="cancel()"
      @on="createOption()"
      @closeAllModal="(showCreateOptionConfirm = false), $emit('closeAllModal')"
      :option="option"
      :user="user"
      :amount="amount(user.plan.code)"
      :isAdminShop="isAdminShop"
    />
    <cancel-option-confirm
      v-if="showCancelOptionConfirm"
      @close_this_modal="cancel()"
      @off="cancelOption()"
      @closeAllModal="(showCancelOptionConfirm = false), $emit('closeAllModal')"
      :option="option"
      :user="user"
      :isAdminShop="isAdminShop"
    />

    <create-option-complete
      v-if="showCreateOptionComplete"
      @close_this_modal="showCreateOptionComplete = false"
      @complete="(showCreateOptionComplete = false), $emit('close_this_modal')"
      @closeAllModal="(showCancelOptionConfirm = false), $emit('closeAllModal')"
      :option="option"
      :isAdminShop="isAdminShop"
    />
    <cancel-option-complete
      v-if="showCancelOptionComplete"
      @close_this_modal="showCancelOptionComplete = false"
      @complete="(showCancelOptionComplete = false), $emit('close_this_modal')"
      :option="option"
      :isAdminShop="isAdminShop"
    />

    <!-- EC-UPスタッフ追加モーダル -->
    <add-ecup-staff
      v-if="showAddEcupStaff"
      :isAdminShop="isAdminShop"
      :user="user"
      :option="option"
      @close_this_modal="
        showAddEcupStaff = false;
        $emit('fetchUser');
      "
      @complete="
        showAddEcupStaff = false;
        $emit('setUser', $event);
      "
    />

    <!-- EC-UPスタッフ権限承認モーダル -->
    <approve-auth-ecup-staff
      v-if="showApproveAuthEcupStaff"
      :isAdminShop="isAdminShop"
      :user="user"
      @close_this_modal="showApproveAuthEcupStaff = false"
      @complete="
        switchLoading = true;
        showApproveAuthEcupStaff = false;
        createOption();
      "
    />
  </ecup-modal>
</template>

<script>
import axios from "axios";
import EcupModal from "../components/EcupModal.vue";
import CreateOptionConfirm from "./components/CreateOptionConfirm.vue";
import CancelOptionConfirm from "./components/CancelOptionConfirm.vue";
import CreateOptionComplete from "./components/CreateOptionComplete.vue";
import CancelOptionComplete from "./components/CancelOptionComplete.vue";
import AddEcupStaff from "./components/summary_mail/AddEcupStaff.vue";
import ApproveAuthEcupStaff from "./components/summary_mail/ApproveAuthEcupStaff.vue";
import config from "../../const";
import ShopTopButton from "./components/shop_top/Button.vue";

export default {
  components: {
    EcupModal,
    CreateOptionConfirm,
    CancelOptionConfirm,
    CreateOptionComplete,
    CancelOptionComplete,
    AddEcupStaff,
    ApproveAuthEcupStaff,
    ShopTopButton,
  },

  props: {
    user: {
      type: Object,
      default: {},
    },

    isAdminShop: {
      type: Boolean,
      default: false,
    },

    option: {
      type: Object,
      default: {},
    },
  },

  data() {
    return {
      showCreateOptionConfirm: false,
      showCancelOptionConfirm: false,
      showCreateOptionComplete: false,
      showCancelOptionComplete: false,
      showAddEcupStaff: false,
      showApproveAuthEcupStaff: false,
      sendSampleLoading: false,
      sentSample: false,
      switchLoading: false,
    };
  },

  computed: {
    isFree() {
      return this.amount(this.user.plan.code) === 0;
    },

    canConfigSummaryMail() {
      return (
        (this.user.rms_registration_status === 0 && this.sentSample) ||
        this.user.rms_registration_status === 2
      );
    },
  },

  filters: {
    formatIsFree(value) {
      return value === true ? "無料" : "有料";
    },
  },

  methods: {
    amount(plan_code) {
      let plans_option = this.option.plans_options.find(
        (plans_option) => plans_option.plan.code == plan_code
      );
      return plans_option.amount;
    },

    switchOption(event) {
      this.switchLoading = true;

      if (event) {
        if (this.isFree) {
          this.createOption();
        } else {
          this.showCreateOptionConfirm = true;
        }
      } else {
        if (this.isFree) {
          this.cancelOption();
        } else {
          this.showCancelOptionConfirm = true;
        }
      }
    },

    createOption() {
      let url = this.isAdminShop
        ? "/api/v1/shops/admin_create_option"
        : "/api/v1/shops/create_option";

      axios
        .post(url, {
          shop_code: this.user.shop_code,
          option_code: this.option.code,
        })
        .then((res) => {
          this.showCreateOptionConfirm = false;
          this.switchLoading = false;
          this.$emit("fetchUser");
          this.showCreateOptionComplete = true;
        });
    },

    cancelOption() {
      let url = this.isAdminShop
        ? "/api/v1/shops/admin_cancel_option"
        : "/api/v1/shops/cancel_option";

      axios
        .post(url, {
          shop_code: this.user.shop_code,
          option_code: this.option.code,
        })
        .then((res) => {
          this.showCancelOptionConfirm = false;
          this.switchLoading = false;
          this.$emit("fetchUser");
          this.showCancelOptionComplete = true;
        });
    },

    cancel() {
      this.option.is_enabled = !this.option.is_enabled;
      this.showCreateOptionConfirm = false;
      this.showCancelOptionConfirm = false;
      this.switchLoading = false;
    },

    sendSample() {
      this.sendSampleLoading = true;
      axios
        .get(`/api/v1/shops/send_sample_summary_email`, {
          params: { shop_code: this.user.shop_code },
        })
        .then((res) => {
          this.sendSampleLoading = false;
          this.sentSample = true;
        });
    },

    configSummaryMail() {
      switch (this.user.rms_registration_status) {
        case 0:
          this.showAddEcupStaff = true;
          break;
        case 2:
          this.showApproveAuthEcupStaff = true;
          break;
        default:
          break;
      }
    },

    config(value) {
      return config[value];
    },
  },
};
</script>

<style>
.v-rating.shorten .v-icon {
  padding: 0;
}
</style>
