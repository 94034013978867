var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"mb-0",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"step_header",class:{
        finished_step_header: _vm.mode == 'finished',
        active_step_header: _vm.mode == 'active',
        not_yet_step_header: _vm.mode == 'not_yet',
      },attrs:{"cols":"11"}},[_c('div',{class:{
          'py-0': true,
          'text-center': true,
          'white--text': true,
          'text-h5': true,
          'font-weight-bold': true,
        }},[_vm._v("\n        STEP"+_vm._s(_vm.step_number)+"\n      ")])]),_vm._v(" "),_c('v-col',{staticClass:"step_body",class:{
        finished_step_body: _vm.mode == 'finished',
        active_step_body: _vm.mode == 'active',
        not_yet_step_body: _vm.mode == 'not_yet',
      },attrs:{"cols":"11"}},[_c('div',{staticClass:"step_title",class:{
          'text-h6': true,
          'text--primary': true,
          'font-weight-bold': true,
          'pl-3': true,
          'pl-10': _vm.step_checkbox,
          finished_step_title: _vm.mode == 'finished',
          active_step_title: _vm.mode == 'active',
          not_yet_step_title: _vm.mode == 'not_yet',
        }},[_vm._v("\n        "+_vm._s(_vm.step_name)+"\n      ")]),_vm._v(" "),_c('v-divider',{staticClass:"my-3"}),_vm._v(" "),(_vm.mode == 'active')?_c('div',[_vm._v("\n        "+_vm._s(_vm.step_description)+"\n      ")]):_vm._e(),_vm._v(" "),_c('div',[(_vm.mode == 'finished' && _vm.is_last_step)?_c('span',[_vm._v("設定が完了しました。ご対応ありがとうございました。")]):(_vm.mode == 'finished')?_c('span',[_vm._v("STEP"+_vm._s(_vm.step_number)+"が完了しました。STEP"+_vm._s(_vm.step_number + 1)+"に進みましょう。")]):_vm._e(),_vm._v(" "),_vm._t("default")],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }