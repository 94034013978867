<template>
  <ecup-modal modal_width="600px" @close_this_modal="$emit('close_this_modal')">
    <div class="pt-5">
      <p>オプション機能「{{ option.name }}」を解除しました。</p>
    </div>
  </ecup-modal>
</template>

<script>
import EcupModal from "../../components/EcupModal.vue";

export default {
  components: { EcupModal },
  props: {
    option: {
      type: Object,
      default: null,
    },
    isAdminShop: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
