<template>
  <v-carousel
    v-if="model >= 0"
    v-model="model"
    class="movies-page"
    light
  >
    <template v-slot:prev="{ on, attrs }">
      <v-btn
        icon
        v-on="on"
        v-bind="attrs"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </template>
    <template v-slot:next="{ on, attrs }">
      <v-btn
        icon
        v-on="on"
        v-bind="attrs"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </template>
    <v-carousel-item
      v-for="(item, i) in items"
      :key="i"
    >
      <div class="d-flex align-center justify-center fill-height">
        <div>
          <div
            class="text-h5"
            :style="{ 'max-width': playerWidth + 'px' }"
          >
            {{ item.text }}
          </div>
          <div :id="item.videoId" class="player" />
        </div>
      </div>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import { YOUTUBE_MOVIES } from "../data/movies";

export default {
  name: "Movies",
  data: function () {
    return {
      model: -1,
      items: [ ...YOUTUBE_MOVIES ],
      isYoutubeApiReady: false,
      activePlayer: null,
      players: [],
      playerWidth: 560,
      playerHeight: 315,
    };
  },
  created() {
    this.loadYoutubeAPI();
    window.onYouTubeIframeAPIReady = () => {
      this.isYoutubeApiReady = true;
    };
  },
  beforeDestroy() {
    try {
      this.players.forEach((player) => {
        player.destroy();
      });
    } catch (_e) {}
  },
  watch: {
    isYoutubeApiReady(value) {
      if (value) {
        const index = this.items.findIndex((item) => item.videoId === this.$route.query.id);
        this.model = index >= 0 ? index : 0;
      }
    },
    model(value) {
      if (this.activePlayer) {
        this.activePlayer.stopVideo();
        this.activePlayer = null;
      }

      setTimeout(() => {
        this.createPlayer(this.items[value].videoId);
      });
    }
  },
  methods: {
    loadYoutubeAPI() {
      const tag = window.document.createElement('script');
      tag.src = '//www.youtube.com/iframe_api';
      tag.async = true;
      const firstScriptTag = document.querySelector('script');
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    },
    onYouTubeStateChange({ player, status }) {
      if (status === window.YT.PlayerState.PLAYING) {
        this.activePlayer = player;
      }
    },
    createPlayer(videoId) {
      const player = this.players.find((p) => p.getVideoData().video_id === videoId);
      if (player) return;

      this.players.push(new window.YT.Player(videoId, {
        videoId,
        width: this.playerWidth,
        height: this.playerHeight,
        frameborder: '0',
        events: {
          onStateChange: (event) => {
            this.onYouTubeStateChange({
              player: event.target,
              status: event.data
            });
          }
        }
      }));
    }
  }
};
</script>

<style lang="scss">
.movies-page {
  .v-carousel__controls,
  .v-window__next,
  .v-window__prev {
    background: transparent !important;
  }
  .v-carousel__controls {
    .v-btn:before,
    .v-btn > .v-btn__content .v-icon {
      color: #92B126;
    }
    .v-carousel__controls__item .v-icon {
      opacity: .5;
    }
  }
}
</style>
