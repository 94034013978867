<template>
  <v-row>
    <v-col cols="12">
      <a :href="config('HREF_FAQ')" target="_blank">
        <v-btn
          large
          block
          color="white"
          @click="$emit('close_this_modal')"
          elevation="3"
        >
          クーポンや各システムについてのよくある質問はこちら
          <v-icon right>mdi-open-in-new</v-icon>
        </v-btn>
      </a>
    </v-col>
  </v-row>
</template>

<script>
import config from "../../../const";

export default {
  name: "FAQButton",

  methods: {
    config(value) {
      return config[value];
    },
  },
};
</script>
