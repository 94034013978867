<template>
  <v-card id="effectCard">
    <v-card-text class="pl-5 pr-5 pt-5 pb-0">
      <span class="v-align-middle subtitle-1 font-weight-bold">データ分析</span>
    </v-card-text>

    <v-carousel class="pa-5 pt-0" v-if="items.length > 0">
      <v-carousel-item style="height: 238px" v-for="item in items" :key="item">
        <component :is="item"></component>
      </v-carousel-item>
    </v-carousel>
    <v-card-text
      v-else
      class="d-flex justify-center align-center"
      style="height: 300px"
    >
      <span class="text-h6">データ集計中です。表示されるまでお待ちください。<br>
        詳細は<a href="https://ec-up.zendesk.com/hc/ja/articles/5537080894223" target="_blank">コチラ</a>
        にてご案内しております。
      </span>
    </v-card-text>
  </v-card>
</template>

<script>
const EFFECT_TYPE_LIST = {
  SalesPerDay: "一日あたり売上",
  SpSalesPerDay: "一日あたり売上[スマホ]",
  PcSalesPerDay: "一日あたり売上[PC]",
  OrdersPerDay: "一日あたり受注数",
  SpOrdersPerDay: "一日あたり受注数[スマホ]",
  PcOrdersPerDay: "一日あたり受注数[PC]",
  CombinedSalesRate: "併売率",
  SpCombinedSalesRate: "併売率[スマホ]",
  PcCombinedSalesRate: "併売率[PC]",
  CustomerUnitPrice: "客単価",
  SpCustomerUnitPrice: "客単価[スマホ]",
  PcCustomerUnitPrice: "客単価[PC]",
  SpBytesize: "スマホ商品ページバイト数有効活用率",
  SpNumberOfImages: "スマホ商品ページ画像数有効活用率",
  PcBytesize: "PC用商品説明文バイト数有効活用率",
  SalesMethodBytesize: "PC用販売説明文バイト数有効活用率",
  //SpPercentageOfSales: "スマホ売上の割合",
};

import axios from "axios";
import { mapMutations } from "vuex";
import { SET_ANALYSIS_DATA } from "store/mutation-types";

import SalesPerDay from "./data_analysis/SalesPerDay.vue";
import SpSalesPerDay from "./data_analysis/SpSalesPerDay.vue";
import PcSalesPerDay from "./data_analysis/PcSalesPerDay.vue";
import OrdersPerDay from "./data_analysis/OrdersPerDay.vue";
import SpOrdersPerDay from "./data_analysis/SpOrdersPerDay.vue";
import PcOrdersPerDay from "./data_analysis/PcOrdersPerDay.vue";
import CombinedSalesRate from "./data_analysis/CombinedSalesRate.vue";
import SpCombinedSalesRate from "./data_analysis/SpCombinedSalesRate.vue";
import PcCombinedSalesRate from "./data_analysis/PcCombinedSalesRate.vue";
import CustomerUnitPrice from "./data_analysis/CustomerUnitPrice.vue";
import SpCustomerUnitPrice from "./data_analysis/SpCustomerUnitPrice.vue";
import PcCustomerUnitPrice from "./data_analysis/PcCustomerUnitPrice.vue";
import SpBytesize from "./data_analysis/SpBytesize.vue";
import SpNumberOfImages from "./data_analysis/SpNumberOfImages.vue";
import PcBytesize from "./data_analysis/PcBytesize.vue";
import SalesMethodBytesize from "./data_analysis/SalesMethodBytesize.vue";
//import SpPercentageOfSales from "./data_analysis/SpPercentageOfSales.vue";

export default {
  name: "EcupEffectCard",
  components: {
    SalesPerDay,
    SpSalesPerDay,
    PcSalesPerDay,
    OrdersPerDay,
    SpOrdersPerDay,
    PcOrdersPerDay,
    CombinedSalesRate,
    SpCombinedSalesRate,
    PcCombinedSalesRate,
    CustomerUnitPrice,
    SpCustomerUnitPrice,
    PcCustomerUnitPrice,
    SpBytesize,
    SpNumberOfImages,
    PcBytesize,
    SalesMethodBytesize,
    //SpPercentageOfSales,
  },
  props: [],
  created() {
    this.setEcupEffect();
  },
  data() {
    return {
      tab: null,
      items: [],
    };
  },

  methods: {
    async setEcupEffect() {
      let res = await axios.get(`/api/v1/items/ecup_effects`);
      this.SET_ANALYSIS_DATA(res.data);
      Object.keys(EFFECT_TYPE_LIST).forEach((key) => {
        const analytics_data = res.data.find(
          (data) => data.effect_type === EFFECT_TYPE_LIST[key]
        );
        if (analytics_data !== undefined) {
          if (
            analytics_data.achievement_ratio &&
            analytics_data.achievement_ratio < 1.01
          ) {
            return;
          }

          this.items.push(key);
        }
      });
    },
    ...mapMutations({
      SET_ANALYSIS_DATA,
    }),
  },
};
</script>
<style>
#effectCard .v-carousel {
  height: auto !important;
}

/* window */
#effectCard .v-window-item .v-image {
  height: auto !important;
}
#effectCard .v-window-item .v-card {
  background-color: #fff;
}

/* arrows */
#effectCard .v-window__prev,
#effectCard .v-window__next {
  top: auto;
  bottom: 0;
  background-color: transparent;
  z-index: 2;
}
#effectCard .v-window__prev .v-btn,
#effectCard .v-window__next .v-btn {
  width: auto;
  height: auto;
}
#effectCard .v-window__prev .v-btn .v-icon,
#effectCard .v-window__next .v-btn .v-icon {
  color: #5c5c5c;
}

/* controls */
#effectCard .v-carousel__controls {
  left: 0 !important;
  bottom: 20px;
  display: block;
  height: auto;
  padding: 0 60px;
  background-color: transparent;
}
#effectCard .v-carousel__controls > .v-item-group {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
}
#effectCard .v-carousel__controls__item {
  height: 4px;
  width: 100%;
  max-width: 50px;
  flex-shrink: 1;
  background-color: #c0c0c0;
  border-radius: 0;
  margin: 0 5px;
}
#effectCard .v-carousel__controls__item.v-item--active {
  background-color: #828282;
}
#effectCard .v-carousel__controls__item .v-btn__content {
  display: none;
}
</style>
