<template>
  <ecup-modal
    title="サマリーメール利用手順（前半）：EC-UP用利用者の追加"
    @close_this_modal="$emit('close_this_modal')"
  >
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1"> </v-stepper-step>

        <v-divider></v-divider>
        <v-stepper-step :complete="e1 > 2" step="2"> </v-stepper-step>

        <v-divider></v-divider>
        <v-stepper-step step="3"> </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <p>
            サマリーメールを受信するには、R-DatatoolにアクセスできるEC-UP用利用者を登録する必要があります。
          </p>

          <p>
            <b> <u>下記ボタン</u> よりR-Login </b>
            に
            <b> <u>管理者権限のあるアカウントでログイン</u> </b>
            し、設定を進めてください。
          </p>

          <v-row justify="center">
            <v-col cols="auto">
              <v-btn
                rounded
                height="100"
                color="#102040"
                class="white--text text-h4 font-weight-bold"
                href="https://glogin.rms.rakuten.co.jp"
                target="_blank"
                style="text-transform: none"
              >
                R-Loginを開く
              </v-btn>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="auto" class="error--text text-body-2">
              通常、編集作業等を行うRMSとは別になります。<br />利用スタッフ管理などを行うため、「R-Login」へのアクセスが必要です。
            </v-col>
          </v-row>

          <v-checkbox v-model="isRmsLogin" label="R-Loginにログインしました">
          </v-checkbox>

          <v-row justify="center">
            <v-col cols="auto">
              <v-btn
                class="ma-2"
                color="primary"
                @click="e1 = 2"
                :disabled="!isRmsLogin"
              >
                次へ
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="2">
          <p>「利用者追加」をクリックします。</p>

          <v-row justify="center">
            <v-col cols="auto">
              <v-img
                :src="`/images/options/summary_mail/add_ecup_staff/02.png`"
              ></v-img>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="auto" class="error--text text-body-2">
              クリックできない場合、管理者権限のあるアカウントでログインし直してください。
            </v-col>
          </v-row>

          <v-checkbox
            v-model="addedUser"
            label="「利用者追加」をクリックしました"
          >
          </v-checkbox>

          <v-row justify="center">
            <v-col cols="auto">
              <v-btn class="ma-2" @click="e1 = 1"> 戻る </v-btn>
              <v-btn
                class="ma-2"
                color="primary"
                @click="e1 = 3"
                :disabled="!addedUser"
              >
                次へ
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="3">
          <p>
            下より<b>コピー＆ペースト</b>して利用者情報を入力してください。<br />
            登録番号と所属は任意で変更可能です。
          </p>

          <v-row justify="center">
            <v-col cols="auto">
              <img
                src="/images/options/summary_mail/add_ecup_staff/03_01.png"
              />
            </v-col>

            <v-col cols="auto">
              <v-row>
                <v-col cols="5">
                  <v-text-field
                    value="ECデータバンク"
                    label="姓"
                    dense
                    hide-details
                    outlined
                    filled
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    value="株式会社"
                    label="名"
                    dense
                    hide-details
                    outlined
                    filled
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="10">
                  <v-text-field
                    value="tool@ecdb.jp"
                    label="メールアドレス"
                    dense
                    hide-details
                    outlined
                    filled
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="10">
                  <v-text-field
                    value="tool@ecdb.jp"
                    label="メールアドレス（確認）"
                    dense
                    hide-details
                    outlined
                    filled
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="10">
                  <v-text-field
                    value="ECUP"
                    label="登録番号"
                    dense
                    hide-details
                    outlined
                    filled
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="10">
                  <v-text-field
                    value="ECUP"
                    label="所属"
                    dense
                    hide-details
                    outlined
                    filled
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="auto">
              <img
                src="/images/options/summary_mail/add_ecup_staff/03_02.png"
              />
            </v-col>
          </v-row>

          <v-checkbox
            v-model="userInfoEntered"
            label="入力内容の確認 ＞ メールを送信しました"
          >
          </v-checkbox>

          <v-row justify="center">
            <v-col cols="auto">
              <v-btn class="ma-2" @click="e1 = 2"> 戻る </v-btn>
              <v-btn
                class="ma-2"
                color="primary"
                @click="AddEcupStaff()"
                :disabled="!userInfoEntered"
                :loading="loading"
              >
                完了
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </ecup-modal>
</template>

<script>
import axios from "axios";
import EcupModal from "../../../components/EcupModal.vue";

export default {
  name: "AddEcupStaff",

  components: { EcupModal },

  props: {
    user: {
      type: Object,
      default: {},
    },

    isAdminShop: {
      type: Boolean,
      default: false,
    },

    option: {
      type: Object,
      default: {},
    },
  },

  data() {
    return {
      e1: 1,
      isRmsLogin: false,
      addedUser: false,
      userInfoEntered: false,
      loading: false,
    };
  },

  methods: {
    AddEcupStaff() {
      let url = this.isAdminShop
        ? "/api/v1/shops/admin_add_ecup_staff"
        : "/api/v1/shops/add_ecup_staff";

      this.loading = true;

      axios
        .post(url, {
          shop_code: this.user.shop_code,
          option_id: this.option.id,
        })
        .then((res) => {
          this.$emit("complete", res.data);
        });
    },
  },
};
</script>
