<template>
  <v-card class="pb-5" min-height="320px">
    <v-card-title class="pa-5">
      <span class="v-align-middle subtitle-1 font-weight-bold"
        >おすすめアクション</span
      >
    </v-card-title>
    <div class="px-5">
      <v-row>
        <v-col>
          <v-tabs v-model="tab" class="mb-5">
            <v-tab>未完了</v-tab>
            <v-tab>完了</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <template v-for="uncomp in uncompleteActions">
                <v-card flat :key="uncomp.id">
                  <div class="d-block mb-5">
                    <div
                      class="d-inline-flex align-center"
                      style="cursor: pointer"
                      @click="clickUncompletedAction(uncomp.id)"
                    >
                      <span class="pr-4">
                        <v-chip
                          :class="getTagColor(uncomp.tag)"
                          class="d-inline-block white--text text-center"
                          style="min-width: 70px"
                        >
                          {{ uncomp.tag }}
                        </v-chip>
                      </span>
                      <span class="grey--text text--darken-2">
                        {{ uncomp.description }}
                      </span>
                    </div>
                    <div
                      class="text-caption error--text"
                      style="margin-left: 85px"
                    >
                      {{ uncomp.supplement }}
                    </div>
                  </div>
                </v-card>
              </template>
            </v-tab-item>
            <v-tab-item>
              <template v-for="comp in completeActions">
                <v-card flat :key="comp.index">
                  <v-row>
                    <v-col cols="2">
                      <v-chip
                        :class="getTagColor(comp.tag)"
                        class="white--text"
                        style="min-width: 70px"
                      >
                        {{ comp.tag }}
                      </v-chip>
                    </v-col>
                    <v-col cols="6">
                      <span class="grey--text text--darken-2">{{
                        comp.description
                      }}</span>
                    </v-col>
                    <v-col cols="4">
                      <span class="grey--text text--darken-2"
                        >完了日: {{ comp.created_at }}</span
                      >
                    </v-col>
                  </v-row>
                </v-card>
              </template>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>

    <!-- クーポン獲得モーダル -->
    <ecup-modal
      v-if="get_coupon_modal"
      title="招待してクーポン獲得"
      @close_this_modal="get_coupon_modal = false"
    >
      <template>
        <div class="mt-3">
          EC-UPをお知り合いにご紹介いただきますと、10%オフクーポンを差し上げます。<br />
          さらに、そのご紹介いただいたショップが有料プランを選択しますと、50%オフクーポンを差し上げます。<br />
          登録時に紹介コード「{{
            user.shop_code
          }}」を入力いただいたショップを、{{
            user.name
          }}様からの紹介として扱います。<br />
          EC-UPをお得に利用しましょう！
        </div>
      </template>
    </ecup-modal>

    <!-- ライセンスキーモーダル -->
    <ecup-modal
      v-if="send_rms_secret_modal"
      title="ライセンスキーの変更"
      @close_this_modal="send_rms_secret_modal = false"
    >
      <send-rms-secret
        :get_secret="false"
        :allow_expired_at_date_null="false"
        @lisence_key_update_succeed="
          fetch_user();
          send_rms_secret_modal = false;
        "
      >
      </send-rms-secret>
    </ecup-modal>

    <!-- プラン変更モーダル -->
    <ecup-modal
      v-if="change_plan_modal"
      title="プランを変更しましょう！"
      @close_this_modal="change_plan_modal = false"
    >
      <template>
        <div class="mt-3">
          トライアル期間は、{{ trial_ends_at }} までです。<br />
          期間中はゴールドプランと同様の機能をご利用いただいております。<br />
          <ul>
            <li>
              トライアル期間が終了しますと、EC-UPのシステムは全て消去され、フリープランに移行します。
            </li>
            <li>
              有料プランに移行されますと、Powered By
              EC-UPのクレジットが消去されます。
            </li>
            <li>
              有料プランに移行されますと、ラクイチアピールの集計期間開始が無料トライアル開始日から、2017年1月1日まで過去に遡って1位画像を作成し掲載できます。
            </li>
            <li>
              有料プランに移行されますとプラン変更日が決済日となり、トライアル期間はその時点で終了となります。
            </li>
          </ul>
          <div>お早めにプランの選択をされることをおすすめします。</div>
          <div class="mt-4 text-center">
            <v-btn color="primary" @click="showPlanIndexModal = true"
              >プラン変更
            </v-btn>
          </div>
        </div>
      </template>
    </ecup-modal>

    <plan-index-modal
      v-if="showPlanIndexModal"
      @close_this_modal="showPlanIndexModal = false"
    />

    <option-index-modal
      v-if="showOptionIndexModal"
      @close_this_modal="showOptionIndexModal = false"
      :user="user"
      :specifiedOptionCode="'summary_mail'"
    />

    <!-- RMS パスワード更新モーダル -->
    <ecup-modal
      v-if="rms_pw_update_modal"
      @close_this_modal="rms_pw_update_modal = false"
      title="RMS パスワード更新"
    >
      <rms-password-update
        @close_this_modal="rms_pw_update_modal = false"
        @rms_login_result="RmsLoginResult"
      />
    </ecup-modal>
  </v-card>
</template>

<script>
import timeUtilsMixin from "../../../packs/mixins/time_utils";
import userUtilsMixin from "../../../packs/mixins/user_utils";
import { SET_USER } from "store/mutation-types";
import { mapMutations } from "vuex";

import EcupModal from "../../components/EcupModal.vue";
import SendRmsSecret from "../../components/SendRmsSecret.vue";
import PlanIndexModal from "../../plan/PlanIndexModal.vue";
import OptionIndexModal from "../../option/OptionIndexModal.vue";
import RmsPasswordUpdate from "../../components/RmsPasswordUpdate.vue";

const TAG_COLORS = {
  おトク: "orange darken-2",
  設定: "cyan accent-4",
  効果UP: "success",
  分析: "light-green",
};
export default {
  name: "RecommendedActionCard",
  mixins: [timeUtilsMixin, userUtilsMixin],
  components: {
    EcupModal,
    SendRmsSecret,
    PlanIndexModal,
    OptionIndexModal,
    RmsPasswordUpdate,
  },

  props: {
    uncomplete_actions: {
      type: Array,
      default: [],
    },
    completed_actions: {
      type: Array,
      default: [],
    },
    trial_ends_at: {
      type: String,
      default: "",
    },
    user: {
      type: Object,
      default: {},
    },
  },

  data() {
    return {
      tab: null,
      send_rms_secret_modal: false,
      get_coupon_modal: false,
      change_plan_modal: false,
      showPlanIndexModal: false,
      showOptionIndexModal: false,
      rms_pw_update_modal: false,
    };
  },
  computed: {
    completeActions() {
      return this.completed_actions.map((comp) => {
        comp.created_at = this.time_ymd_format(comp.created_at);
        if (comp.name === "update_plan") {
          comp.description = "プランを選択しましょう！";
        }
        return comp;
      });
    },
    uncompleteActions() {
      return this.uncomplete_actions.map((uncomp) => {
        if (uncomp.name === "update_plan") {
          uncomp.description = this.trial_ends_at + uncomp.description;
        }

        return uncomp;
      });
    },
  },

  methods: {
    setActionsData(is_completed) {
      const actionData = is_completed
        ? this.completed_recommended_actions
        : this.uncompleted_recommended_actions;
      this.recommended_actions = actionData;
    },

    getTagColor(tag) {
      return TAG_COLORS[tag];
    },

    clickUncompletedAction(recommended_action_id) {
      this.approve_auth_success_message = false;
      this.approve_auth_error_message = false;
      switch (recommended_action_id) {
        case 1:
          this.get_coupon_modal = true;
          break;
        case 2:
          this.send_rms_secret_modal = true;
          break;
        case 3:
          this.change_plan_modal = true;
          break;
        case 4:
          this.showOptionIndexModal = true;
          break;
        case 5:
          this.rms_pw_update_modal = true;
          break;
      }
    },

    ...mapMutations({
      SET_USER,
    }),
  },
};
</script>
