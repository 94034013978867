<template>
  <div>
    <v-row justify="space-between" class="mt-0">
      <v-col cols="auto">お支払い金額</v-col>
      <v-col cols="auto" class="text-h6 ml-2 text-right">
        {{ plan.amount }}円
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="py-0 text-caption grey--text text--darken-2 font-weight-light"
      >
        フリープランの場合クレジットカード情報の登録は不要です。
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ["user", "plan"],
};
</script>
