<template>
  <v-container fluid class="pa-6" v-if="user">
    <v-row>
      <v-col class="display-1">
        <v-icon left large color="success">mdi-refresh-auto</v-icon>
        おまかせ機能
      </v-col>
    </v-row>
    <v-row>
      <v-col class="body-2 pt-0">
        おまかせ機能の設定ができます。変更後の設定は次回更新時から反映されます。
      </v-col>
    </v-row>

    <FullAutoOn
      :user="user"
      :loading="loading"
      @updated="updateComplete"
      @update-full-auto="update"
      @update-config="updateConfig"
    />

    <floating-notification-alert
      :open="finished_alert"
      @close="finished_alert = false"
    >
      <v-icon color="success">mdi-checkbox-marked</v-icon>
      <span color="success">設定を保存しました。</span>
    </floating-notification-alert>
  </v-container>
</template>

<script>
import axios from "axios";
import deepmerge from "deepmerge";
import userUtilsMixin from "../../packs/mixins/user_utils";
import FloatingNotificationAlert from "../components/alert/FloatingNotificationAlert.vue";
import FullAutoOn from "./components/full_auto/FullAutoOn.vue";

export default {
  data() {
    return {
      loading: false,
      finished_alert: false,
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  components: {
    FloatingNotificationAlert,
    FullAutoOn,
  },

  mixins: [userUtilsMixin],

  async created() {
    this.fetch_user();
  },

  methods: {
    async update(val) {
      this.loading = true;
      await axios.post("/api/v1/shops/me", val).then(async (res) => {
        this.loading = false;
        this.$emit("updated", res);
      });
    },

    updateComplete(res) {
      this.SET_USER(res.data);
      this.loading = false;
      this.finished_alert = true;
    },

    async updateConfig(val) {
      this.loading = true;

      await axios
        .post("/api/v1/shops/me", {
          shop: { config: deepmerge(this.user.config, val) },
        })
        .then(async (res) => {
          this.updateComplete(res);
        });
    },
  },
};
</script>
