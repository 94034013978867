import App from "../app.vue";
import axios from "./axios";
import router from "router/router";
import store from "../store";
import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'
import vuetify from './vuetify' // path to vuetify export

Vue.component('apexchart', VueApexCharts)
Vue.use(VueApexCharts)

import jQuery from "jquery";
global.jquery = jQuery;
global.$ = jQuery;
window.$ = window.jQuery = require("jquery");

document.body.setAttribute("data-app", true);

document.addEventListener("DOMContentLoaded", () => {
  const app = new Vue({
    router,
    vuetify,
    store,
    axios,
    render: (h) => h(App),
  }).$mount();
  document.body.appendChild(app.$el);
});
