var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-text',{staticClass:"pa-5"},[_c('div',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v("ニュース")]),_vm._v(" "),_c('div',{staticStyle:{"width":"100%"},attrs:{"id":"facebookFrame"}},[_c('iframe',{staticStyle:{"border":"none","overflow":"hidden"},attrs:{"src":'https://www.facebook.com/plugins/page.php' +
          '?href=https%3A%2F%2Fwww.facebook.com%2Fecup.ecdb' +
          `&width=${_vm.window}` +
          '&height=600' +
          '&tabs=timeline' +
          '&show_facepile=false' +
          '&small_header=true',"width":"100%","height":"600","scrolling":"no","frameborder":"0","allowfullscreen":"true","allow":"autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"}})])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }