<template>
  <v-row justify="center">
    <v-col cols="12" class="header text-h6 white--text py-1">
      <v-icon color="white">mdi-circle-medium</v-icon>
      不要商品の削除 もしくは倉庫指定
    </v-col>
    <v-col cols="12" class="body">
      <v-row>
        <v-col>
          EC-UPでの商品数とは、「販売中＋売切れの合計商品数」となります。<br />
          不要商品の削除・倉庫指定により、商品数を減らす事ができます。<br />
          <br />
          商品数が{{
            itemLimit
          }}万商品以下である事が確認できた場合、すぐに更新を再開いたします。
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["itemLimit"],
};
</script>

<style></style>
