<template>
  <modal>
    <v-card class="modal-container">
      <v-card-title class="justify-center">
        フリープランに変更しました
      </v-card-title>

      <v-card-text class="text-center">
        <p>
          EC-UPをお試しいただきありがとうございました。<br />
          トライアル期間が終了したため、フリープランに変更しました。<br />
          引き続きEC-UPをご活用いただけます。
        </p>

        <v-row justify="center">
          <v-col cols="6">
            <v-btn large block color="primary" @click="confirm()">
              閉じる
            </v-btn>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="auto">
            <div class="notes">
              <ul class="caption">
                <li>
                  商品毎に設定したシステム設定は全てリセットされています。
                </li>
                <li>
                  獲得したクーポンは後日有料プランへ変更する際ご利用いただけます。
                </li>
              </ul>

              <v-row justify="end" class="mt-1">
                <v-col cols="auto">
                  <a :href="config('HREF_FAQ')" target="_blank">
                    よくある質問Q&A
                  </a>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </modal>
</template>

<script>
import axios from "axios";
import Modal from "../components/Modal.vue";
import config from "../../const";

export default {
  components: { Modal },

  methods: {
    async confirm() {
      await axios
        .post("/api/v1/shops/me", {
          confirmed_trial_end: 1,
        })
        .then((res) => {
          this.$emit("close", res.data);
        });
    },

    config(value) {
      return config[value];
    },
  },
};
</script>

<style scoped>
.modal-container {
  max-width: 600px;
}

.notes {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}
</style>
