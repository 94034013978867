<template>
  <span
    class="text-h6 white--text py-1 px-7"
    style="background-color: #AC6A00; border-radius: 20px"
    ><slot/></span
  >
</template>

<script>
export default {
  name: "brown-rounded-subtitle",
  mixins: [],
  components: {},
  props: [],
  computed: {},
  watch: {},
  data() {
    return {};
  },
  methods: {},
};
</script>