<template>
  <v-container>
    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>

    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title class="text-h6">
            なりすまし実行
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="9">
                <v-form @submit.prevent="searchShop">
                  <v-text-field
                    v-model="searchTerm"
                    label="ショップコードを入力"
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-form>
              </v-col>
              <v-col cols="3">
                <v-btn @click.prevent="searchShop" color="primary">検索</v-btn>
              </v-col>
            </v-row>
            <div v-if="result_shop">
              <p class="text-h6">ショップ名: {{ result_shop.name }}</p>
              <p class="text-h6">UID: {{ result_shop.uid }}</p>
            </div>
            <v-btn 
              v-if="result_shop"
              @click.prevent="copyToClipboard(result_shop.uid)" 
              color="primary">UIDをコピー
            </v-btn>
            <v-btn
              v-if="result_shop"
              @click.prevent="impersonationExecution"
              color="primary"
              class="mx-auto" 
            >
              実行
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title class="text-h6">
            なりすまし解除
          </v-card-title>
          <v-card-text>
            <v-form @submit.prevent="impersonationCancellation">
              <v-text-field
                v-model="uidToUnimpersonate"
                label="なりすましているアカウントの元のUIDを入力"
                outlined
                dense
                required
              ></v-text-field>
              <v-btn type="submit" color="primary">解除</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      searchTerm: '', // 検索する
      result_shop: null, // 検索結果のShopのUID
      errorMessage: '', // エラーメッセージを保持するデータプロパティ
      uidToUnimpersonate: '', // 解除するUID
    };
  },
  async created() {
    if (this.$store.state.user && !this.$store.state.user.is_admin) {
      this.$router.push({ name: "Root" });
      return;
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    async searchShop() {
      try {
        this.result_shop = null;
        this.errorMessage = '';

        const shopCode = this.searchTerm.trim();

        if (shopCode) {
          const encodedShopCode = encodeURIComponent(shopCode);
          const response = await axios.get(`/api/v1/shops/search_for_impersonation?shop_code=${encodedShopCode}`);
          const data = response.data;

          console.log('API Response:', data);

          if (data.shop) {
            this.result_shop = data.shop;
          } else {
            this.errorMessage = '該当するShopが見つかりませんでした。';
          }
        } else {
          this.errorMessage = '検索shopCodeが空です。';
        }
      } catch (error) {
        this.errorMessage = '検索に失敗しました。エラーメッセージ: ' + error.message;
        console.error(error);
      }
    },
    copyToClipboard(text) {
      const input = document.createElement('textarea');
      input.style.position = 'fixed';
      input.style.opacity = '0';
      input.value = text;
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
      alert('コピーしました: ' + text);
    },
    async impersonationExecution() {
      try {
        const shopId = this.result_shop.id;

        const response = await axios.post('/api/v1/shops/impersonation_execution', {
          id: shopId,
        });

        console.log('Update Response:', response.data);

        if (response.data.message === 'なりすましが成功しました') {
          this.$router.push({ name: "Root" });
        }
      } catch (error) {
        this.errorMessage = 'エラー: ' + error.response.data.error;
        console.error(error);
      }
    },
    async impersonationCancellation() {
      try {
        const uidToUnimpersonate = this.uidToUnimpersonate;
        let errorMessage = '';

        if (uidToUnimpersonate.length !== 28) {
          errorMessage += 'UIDは28文字でなければなりません。';
        } else if (!uidToUnimpersonate) {
          errorMessage += '正しいUIDを入力してください。';
        }
        if (errorMessage) {
          this.errorMessage = errorMessage;
          return;
        }

        const response = await axios.post(`/api/v1/shops/impersonation_cancellation?uid=${uidToUnimpersonate}`);

        console.log('Unimpersonate Response:', response.data);

        if (response.data.message === 'なりすまし解除が成功しました') {
          this.$router.push({ name: "Root" });
        }
      } catch (error) {
        this.errorMessage = 'エラー: ' + (error.response ? error.response.data.error : error.message);
        console.error(error);
      }
    },
  },
};
</script>

<style>
.error-message {
  color: red;
  font-weight: bold;
  margin-bottom: 10px;
}

.result-container {
  display: flex;
  align-items: center;
}

.result-container .text-h6 {
  flex: 1;
  margin: 0;
}

.result-container v-btn {
  margin-left: 10px;
}
</style>