export const YOUTUBE_MOVIES = [
  {
    videoId: 'eMJIBa05oVI',
    text: ''
  },
  {
    videoId: 'yDRQVNhcTpQ',
    text: ''
  },
  {
    videoId: '48h6mAnKaQg',
    text: ''
  },
  {
    videoId: 't0sYdH7WsM4',
    text: ''
  },
  {
    videoId: '_o66Iz6A8jE',
    text: ''
  },
  {
    videoId: 'rbCCVBbR4s0',
    text: ''
  },
  {
    videoId: 'kZow8QhHfeg',
    text: ''
  },
  {
    videoId: 'IQJj76s7Ft0',
    text: ''
  },
  {
    videoId: 'al_ArZTar-E',
    text: ''
  }
]
