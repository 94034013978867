<template>
  <div>
    <v-text-field
      class="mx-auto"
      style="max-width: 500px"
      :value="search"
      append-icon="mdi-magnify"
      label="商品管理番号・商品名で検索"
      single-line
      dense
      hide-details
      outlined
      @click:append="$emit('update:search', $event.target.value)"
      @keydown.enter="$emit('update:search', $event.target.value)"
    ></v-text-field>

    <v-expansion-panels flat accordion>
      <v-expansion-panel class="text-center">
        <v-expansion-panel-header
          class="d-inline-block py-0"
          style="width: auto"
          >絞り込み</v-expansion-panel-header
        >
        <v-expansion-panel-content class="text-left">
          <v-row>
            <v-col cols="10" offset="2">
              <v-row>
                <v-col cols="3" class="font-weight-medium"
                  >販売ステータス</v-col
                >
                <v-col cols="9">
                  <v-radio-group
                    class="block_item my-auto"
                    :value="status"
                    dense
                    hide-details
                    row
                    @change="$emit('update:status', $event)"
                  >
                    <v-radio label="全商品" value="all" class="mx-1"></v-radio>
                    <v-radio
                      label="販売中"
                      value="on_sale"
                      class="mx-1"
                    ></v-radio>
                    <v-radio
                      label="売切れ"
                      value="sold_out"
                      class="mx-1"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="font-weight-medium">稼働状況</v-col>
                <v-col cols="9">
                  <v-radio-group
                    class="block_item my-auto"
                    :value="filter"
                    dense
                    hide-details
                    row
                    @change="$emit('update:filter', $event)"
                  >
                    <v-radio label="全商品" value="all" class="mx-1"></v-radio>
                    <v-radio
                      label="稼働中"
                      value="running"
                      class="mx-1"
                    ></v-radio>
                    <v-radio
                      label="未稼働"
                      value="not_running"
                      class="mx-1"
                      v-if="false"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="font-weight-medium">システム</v-col>
                <v-col cols="9">
                  <system-select
                    :tools="tools"
                    :shops_tools="shops_tools"
                    :value="search_shops_tool_id"
                    :dense="true"
                    style="width: 200px"
                    @tool_selected="
                      $emit('update:search_shops_tool_id', $event)
                    "
                  >
                  </system-select>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="9" offset="3">
                  <v-checkbox
                    :value="onlyChecked"
                    label="チェック中の商品を表示"
                    dense
                    hide-details
                    @change="$emit('update:onlychecked', $event)"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="9" offset="3">
                  <v-checkbox
                    v-model="onlyErrorChecked"
                    label="エラー発生中の商品を表示"
                    dense
                    hide-details
                    @change="$emit('update:onlyerror', $event)"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import SystemSelect from "./SystemSelect.vue";

export default {
  components: {
    SystemSelect,
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  created() {
    this.onlyErrorChecked = this.onlyError;
  },
  data() {
    return {
      onlyErrorChecked: false,
    };
  },
  props: [
    "onlyChecked",
    "onlyError",
    "shops_tools",
    "tools",
    "search_shops_tool_id",
    "search",
    "filter",
    "status",
  ],
  methods: {},
};
</script>

<style scoped>
.block_item {
  display: inline-block;
  vertical-align: middle;
}
</style>
