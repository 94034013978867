import { getAuth, onAuthStateChanged } from "firebase/auth";
import axios from "axios";
import { mapMutations } from "vuex";
import { SET_USER } from "store/mutation-types";

export default {
  methods: {
    async fetch_user() {
      const auth = getAuth();
      onAuthStateChanged(auth, user => {
        if (user) {
          axios.get(`/api/v1/shops/me`).then((res) => {
            this.SET_USER(res.data);
          });
        } else {
          this.SET_USER(null);
        }
      });
    },
    ...mapMutations({
      SET_USER,
    }),
  },
};
