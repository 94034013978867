<template>
  <v-btn x-large color="grey" @click="$emit('clicked')">
    <span class="white--text font-weight-bold">戻る</span>
  </v-btn>
</template>

<script>
export default {
  name: "return-btn",
  mixins: [],
  components: {},
  props: [],
  computed: {},
  watch: {},
  data() {
    return {};
  },
  methods: {},
};
</script>