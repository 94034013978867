<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        :style="{
          width: modal_width || '730px',
        }"
      >
        <v-container class="px-6 py-4 pb-6">
          <v-row no-gutters justify="space-between">
            <v-col cols="11">
              <div
                class="text-h6 font-weight-bold pt-3"
                :class="{ 'text-center pl-12': title_center }"
                v-if="title"
              >
                {{ title }}
              </div>
            </v-col>

            <v-col cols="1" align-self="center">
              <v-icon
                large
                @click="$emit('close_this_modal')"
                class="modal_close_icon"
                v-if="!force"
              >
                mdi-close-box
              </v-icon>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12">
              <slot></slot>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {},
    title_center: { default: false },
    modal_width: {},
    force: { default: false },
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  margin: 0px auto;
  padding: 0px 0px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow: auto;
  height: auto;
  max-height: 88vh;
  vertical-align: middle;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

/*
       * The following styles are auto-applied to elements with
       * transition="modal" when their visibility is toggled
       * by Vue.js.
       *
       * You can easily play with the modal transition by editing
       * these styles.
       */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modal_close_icon {
  display: block;
  margin: 0 0 0 auto;
  font-size: 3rem;
}
</style>
