<template>
  <ecup-modal
    title="確認事項"
    title_center="true"
    modal_width="600px"
    @close_this_modal="$emit('close_this_modal')"
  >
    <div v-if="user.plan['paid?']">
      <div class="pt-5">
        <p>有料オプションのため、料金が発生します。よろしいですか？</p>
      </div>
      <div class="text-center grey lighten-4 px-6 py-4 mx-n6 mb-n6">
        <v-btn
          color="primary"
          class="px-8"
          large
          :loading="loading"
          @click="
            loading = true;
            $emit('on');
          "
        >
          利用する
        </v-btn>
      </div>
    </div>

    <div v-if="option.disabled">
      <div class="pt-5">
        <p>オプションのご利用には有料プランに変更する必要があります。</p>
      </div>
      <div class="text-center grey lighten-4 px-6 py-4 mx-n6 mb-n6">
        <v-btn
          color="primary"
          class="px-8"
          large
          @click="$router.push({ name: 'ChangeToSilverPlan' })"
        >
          有料プランに変更する
        </v-btn>
      </div>
    </div>
  </ecup-modal>
</template>

<script>
import EcupModal from "../../components/EcupModal.vue";

export default {
  props: {
    option: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
    amount: {
      type: Number,
      default: null,
    },
    isAdminShop: {
      type: Boolean,
      default: false,
    },
  },
  components: { EcupModal },
  data() {
    return {
      loading: false,
    };
  },
};
</script>
