<template>
  <v-btn
    x-large
    class="btn-icon pa-5"
    :color="button_color"
    :outlined="btn_outlined_bool"
    @click="$emit('clicked')"
  >
    <v-icon size="70" :color="inner_txt_color" class="ma-2"
      >{{icon}}</v-icon
    >
    <div
      :color="inner_txt_color"
      :class="`mx-2 btn-icon-text text-h5 ${inner_txt_color}--text`"
    >
      {{ text }}
    </div>
  </v-btn>
</template>

<script>
export default {
  name: "activated-btn",
  components: {},
  props: ["text", "activated", "icon"],
  computed: {
    button_color() {
      if (this.activated) {
        return "#92B126";
      } else {
        return "grey";
      }
    },
    inner_txt_color() {
      if (this.activated) {
        return "white";
      } else {
        return "grey";
      }
    },
    btn_outlined_bool() {
      if (this.activated) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.btn-icon {
  padding: 10px !important;
  height: auto !important;
  border: solid 5px;
  border-radius: 10px;
}
.btn-icon >>> .v-btn__content {
  flex-direction: column;
}
.btn-icon-text {
  margin: 5px 0 0;
  font-size: 0.9rem;
}</style>