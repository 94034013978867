<template>
  <v-row justify="center">
    <v-col cols="auto">
      <v-carousel show-arrows-on-hover hide-delimiters height="420">
        <v-carousel-item
          v-for="(item, i) in items"
          :key="i"
          :src="item.src"
        ></v-carousel-item>
      </v-carousel>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          src: "/images/items/procedure/one_item/01.gif",
        },
        {
          src: "/images/items/procedure/one_item/02.png",
        },
        {
          src: "/images/items/procedure/one_item/03.png",
        },
        {
          src: "/images/items/procedure/one_item/04.png",
        },
        {
          src: "/images/items/procedure/one_item/05.png",
        },
        {
          src: "/images/items/procedure/one_item/06.png",
        },
        {
          src: "/images/items/procedure/one_item/07.png",
        },
        {
          src: "/images/items/procedure/one_item/08.png",
        },
        {
          src: "/images/items/procedure/one_item/09.png",
        },
        {
          src: "/images/items/procedure/one_item/10.png",
        },
      ],
    };
  },
};
</script>
<style scoped></style>
