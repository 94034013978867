<template>
  <v-container>
    <LoadingModal :is-open="isLoading" />
  </v-container>
</template>

<script>
import userUtilsMixin from "../packs/mixins/user_utils";
import LoadingModal from "./components/LoadingModal.vue";

export default {
  name: "Root",
  components: {
    LoadingModal
  },
  mixins: [userUtilsMixin],
  data() {
    return {
      isLoading: true
    };
  },
  watch: {
    user: async function(newVal, oldVal) {
      if (newVal) {
        if (this.user.is_agency || this.user.is_ambassador) {
          this.$router.push({ name: "ShopAdmin" });
        } else {
          this.$router.push({ name: "Dashboard" });
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  async created() {
    this.fetch_user();
  },
};
</script>
