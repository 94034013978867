<template>
  <v-container fluid class="pa-0" v-if="user">
    <v-row justify="center">
      <v-col cols="12" class="pb-0">
        <v-row align="end" class="mb-5">
          <v-col cols="4" class="pr-1">
            <span class="display-1" style="white-space: nowrap">
              <v-icon left large color="success">
                mdi-view-dashboard-outline
              </v-icon>
              ダッシュボード
            </span>
          </v-col>
          <v-col cols="8" class="text-right d-flex align-center justify-end">
            <r-login-password-card
              v-if="user.rms_registration_status === 3"
              :user="user"
              @fetchUser="fetch_user()"
            />
            <license-key-card
              :lisence_key_expired_at="user.lisence_key_expired_at"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="7">
        <v-row>
          <v-col cols="12">
            <ecup-effect-card> </ecup-effect-card>
          </v-col>
          <v-col cols="12">
            <recommended-action-card
              :completed_actions="completed_actions"
              :uncomplete_actions="uncomplete_actions"
              :trial_ends_at="trial_ends_at"
              :user="user"
              @setUser="SET_USER($event)"
            >
            </recommended-action-card>
          </v-col>
          <v-col cols="12" v-if="user.use_stripe">
            <coupon-card
              :available_off_50_coupons_count="
                user.my_available_off_50_coupons_count
              "
              :available_off_10_coupons_count="
                user.my_available_off_10_coupons_count
              "
              :use_coupon="user.use_coupon"
              :shop_code="user.shop_code"
              @setUser="SET_USER($event)"
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="5">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <v-row no-gutters class="mb-2">
                  <v-col class="subtitle-1 font-weight-bold">稼働状況</v-col>
                </v-row>
                <v-tabs
                  color="success"
                  hide-arrows
                  flat
                  icons-and-text
                  fixed-tabs
                  hide-slider
                  height="68"
                  class="tabs-status"
                >
                  <v-tab
                    @click="operation_status_tab = 'for-smart-phone'"
                    class="px-0 mx-1"
                    style="border-bottom: solid 4px #92b125"
                  >
                    <div
                      class="success--text font-weight-bold mb-1"
                      style="font-size: 14px"
                    >
                      SP商品説明文
                    </div>
                    <v-icon large color="success" class="mb-1"
                      >mdi-cellphone-text</v-icon
                    >
                  </v-tab>
                  <v-tab
                    @click="operation_status_tab = 'by-sales-method'"
                    class="px-0 mx-1"
                    style="border-bottom: solid 4px #92b125"
                  >
                    <span
                      class="success--text font-weight-bold mb-1"
                      style="font-size: 14px"
                      >PC販売説明文</span
                    >
                    <v-icon
                      large
                      color="success"
                      class="mb-1"
                      style="transform: rotateX(180deg)"
                      >mdi-comment-text-outline</v-icon
                    >
                  </v-tab>
                  <v-tab
                    @click="operation_status_tab = 'for-pc'"
                    class="px-0 mx-1"
                    style="border-bottom: solid 4px #92b125"
                  >
                    <span
                      class="success--text font-weight-bold mb-1"
                      style="font-size: 14px"
                      >PC商品説明文</span
                    >
                    <v-icon
                      large
                      color="success"
                      class="mb-1"
                      style="transform: rotateY(180deg)"
                      >mdi-comment-text-outline</v-icon
                    >
                  </v-tab>
                </v-tabs>
              </v-card-text>
              <OperationStatusForSmartPhone
                v-if="operation_status_tab === 'for-smart-phone'"
              />
              <OperationStatusBySalesMethod
                v-if="operation_status_tab === 'by-sales-method'"
              />
              <OperationStatusForPC v-if="operation_status_tab === 'for-pc'" />
            </v-card>
          </v-col>
          <v-col cols="12">
            <news-card></news-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <slider-card></slider-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import timeUtilsMixin from "../../packs/mixins/time_utils";
import userUtilsMixin from "../../packs/mixins/user_utils";
import axios from "axios";
import { mapMutations } from "vuex";
import { SET_USER } from "store/mutation-types";

import NumberOfItemCards from "./components/NumberOfItemCards.vue";
import RunningSystemsCard from "./components/RunningSystemsCard.vue";
import BytesizeCard from "./components/BytesizeCard.vue";
import NumberOfImagesCard from "./components/NumberOfImagesCard.vue";
import LogCard from "./components/LogCard.vue";
import NewsCard from "./components/NewsCard.vue";
import LicenseKeyCard from "./components/LicenseKeyCard.vue";
import RLoginPasswordCard from "./components/RLoginPasswordCard.vue";
import CouponCard from "./components/CouponCard.vue";
import OperationStatusForSmartPhone from "./components/operation_status/ForSmartPhone.vue";
import OperationStatusBySalesMethod from "./components/operation_status/BySalesMethod.vue";
import OperationStatusForPC from "./components/operation_status/ForPC.vue";
import EcupEffectCard from "./components/EcupEffectCard.vue";
import RecommendedActionCard from "./components/RecommendedActionCard.vue";
import SliderCard from "./components/SliderCard.vue";
import EcupModal from "../components/EcupModal.vue";
import LiffLoginForm from "../components/liff/LiffLoginForm.vue";

export default {
  name: "DashboardPage",
  components: {
    LogCard,
    NewsCard,
    RunningSystemsCard,
    NumberOfItemCards,
    BytesizeCard,
    NumberOfImagesCard,
    LicenseKeyCard,
    RLoginPasswordCard,
    CouponCard,
    OperationStatusForSmartPhone,
    OperationStatusBySalesMethod,
    OperationStatusForPC,
    EcupEffectCard,
    RecommendedActionCard,
    SliderCard,
    EcupModal,
    LiffLoginForm,
  },

  mixins: [timeUtilsMixin, userUtilsMixin],

  async created() {
    this.fetch_user();
  },

  watch: {
    user: async function (newVal, oldVal) {
      if (newVal) {
        if (!this.user.completed_first_update_items) {
          this.$router.push({ name: "InstallationStatus" });
          return;
        }
        this.set_tools();

        this.set_recommended_action(newVal);
      }
    },
  },

  data() {
    return {
      count_by_ecup_status: null,
      total_running: null,
      tools: [],
      shop_total_bytesize_consumption_average: 0,
      shop_total_image_consumption_average: 0,
      completed_actions: [],
      uncomplete_actions: [],
      trial_ends_at: "",
      operation_status_tab: "for-smart-phone",
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    async set_tools() {
      let tool_res = await axios.get(`/api/v1/tools/`);
      this.tools = tool_res.data.tools;
    },
    async set_ecup_status_stats() {
      let count_res = await axios.get(`/api/v1/items/ecup_status_stats`);
      this.number_of_running_items = count_res.data.number_of_running_items;
      this.number_of_not_running_items =
        count_res.data.number_of_not_running_items;
      this.number_of_configuring_items =
        count_res.data.number_of_configuring_items;
      this.number_of_error_items = count_res.data.number_of_error_items;
      this.number_of_items_group_by_tool =
        count_res.data.number_of_items_group_by_tool;
    },
    async set_rakuten_resources_stats() {
      let res = await axios.get(
        `/api/v1/items/comsumption_stats_of_rakten_resources`
      );
      this.bytesyze_of_description_sp_remaining =
        res.data.bytesyze_of_description_sp_remaining;
      this.bytesyze_of_description_sp_used_by_shop =
        res.data.bytesyze_of_description_sp_used_by_shop;
      this.bytesyze_of_description_sp_used =
        res.data.bytesyze_of_description_sp_used;
      this.bytesyze_of_description_sp_used_by_ecup =
        res.data.bytesyze_of_description_sp_used_by_ecup;
      this.images_in_description_sp_used =
        res.data.images_in_description_sp_used;
      this.images_in_description_sp_used_by_ecup =
        res.data.images_in_description_sp_used_by_ecup;
      this.images_in_description_sp_used_by_shop =
        res.data.images_in_description_sp_used_by_shop;
      this.images_in_description_sp_remaining =
        res.data.images_in_description_sp_remaining;
    },
    async set_recommended_action(user) {
      let res = await axios.get(`/api/v1/recommended_actions`);
      this.completed_actions = res.data.completed_actions;
      this.uncomplete_actions = res.data.uncomplete_actions;
      this.trial_ends_at = this.time_ymd_format(user.trial_ends_at);
    },

    updated_at_date() {
      return this.time_now().add(-5, "minutes").format("YYYY/MM/DD HH:mm");
    },

    ...mapMutations({
      SET_USER,
    }),
  },
};
</script>

<style scoped>
p {
  font-size: 2em;
  text-align: center;
}

.dashboard_top_status_icon {
  font-size: 2rem;
}

.v-card {
  height: 100%;
}
</style>
<style lang="sass">
.tabs-status{
  .v-tab{
    opacity: 0.4;
    &.v-tab--active{
      opacity: 1;
    }
  }
}
</style>
