<template>
  <v-row>
    <v-col>
      <Appeal :plans="plans" />
      <Cards
        class="mt-15"
        :plans="plans"
        :selectedOptionCodes="selectedOptionCodes"
        :isPlatinum="isPlatinum"
      />
    </v-col>
  </v-row>
</template>

<script>
import Appeal from "./Plans/Appeal.vue";
import Cards from "./Plans/Cards.vue";

export default {
  name: "Plans",

  components: {
    Appeal,
    Cards,
  },

  props: ["plans", "selectedOptionCodes", "isPlatinum"],
};
</script>
