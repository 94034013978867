<template>
  <plan-change
    :user="user"
    :plan="plan"
    :selectedOptionCodes="selectedOptionCodes"
    :googleFormUrl="googleFormUrl"
    @input="selectedOptionCodes = $event"
    v-if="plan"
  >
    <template #bottom_contents>
      <v-row class="mt-5">
        <v-col>
          <h4 class="text-center">※フリープランに変更する方へ</h4>
          <ul>
            <li v-for="(message, i) in plan.messages" :key="i" class="mt-2">
              <div class="text-body-2">
                {{ message }}
              </div>
            </li>
          </ul>
        </v-col>
      </v-row>
    </template>

    <template #upsell_card>
      <div class="ma-5">
        <v-btn
          x-large
          block
          dark
          color="#7D7D7D"
          height="150px"
          @click="$router.push({ name: 'ChangeToSilverPlan' })"
          v-if="availableSilver"
        >
          <div>
            <span class="text-h5">
              商品毎に最適な<br />
              システムを利用し、<br />
              運用も自動化しましょう！
            </span>
            <br />
            <span class="text-body-2 text-decoration-underline">
              シルバープランを確認する
            </span>
          </div>
        </v-btn>

        <v-btn
          x-large
          block
          dark
          color="#CCA83F"
          height="110px"
          @click="$router.push({ name: 'ChangeToGoldPlan' })"
          v-else-if="availableGold"
        >
          <div>
            <span class="text-h5">
              スマホの売上を<br />
              最大化しましょう！
            </span>
            <br />
            <span class="text-body-2 text-decoration-underline">
              ゴールドプランを確認する
            </span>
          </div>
        </v-btn>
      </div>
    </template>

    <template #plan_confirmation>
      <free-plan-confirmation :user="user" :plan="plan" />
    </template>
  </plan-change>
</template>

<script>
import Axios from "axios";
import deepmerge from "deepmerge";
import userUtilsMixin from "../../packs/mixins/user_utils";
import PlanChange from "./components/PlanChange.vue";
import FreePlanConfirmation from "./components/plan_confirmation/FreePlanConfirmation.vue";
import { freePlan } from "../../data/plans";

export default {
  mixins: [userUtilsMixin],
  components: { PlanChange, FreePlanConfirmation },
  computed: {
    user() {
      return this.$store.state.user;
    },
    availableSilver() {
      return (
        this.user &&
        this.user.plan.code !== "silver" &&
        this.user.items_count < this.maxNumOfItemsForSilver
      );
    },
    availableGold() {
      return (
        this.user &&
        this.user.plan.code !== "gold" &&
        this.user.plan.code !== "gold_trial" &&
        this.user.items_count < this.maxNumOfItemsForGold
      );
    },
    googleFormUrl() {
      if (!this.user) {
        return null;
      }

      switch (this.user.plan.code) {
        case "trial":
          // 4
          return `https://docs.google.com/forms/d/e/1FAIpQLSeFOeveb1j-rVFaYJZGT0kuX0LFsG8KzP3oVYgTdI-aCBdRkA/viewform?usp=pp_url&entry.2060341977=${this.user.shop_code}`;
        default:
          // 3
          return `https://docs.google.com/forms/d/e/1FAIpQLSed8pn8YRzAMy7p0J1yl-idpIQuni7C_g-swrjRk_Pftstuqg/viewform?usp=pp_url&entry.2060341977=${this.user.shop_code}`;
      }
    },
  },
  watch: {
    plansMaster() {
      this.plan = deepmerge(
        freePlan,
        this.plansMaster.find((x) => x.code === freePlan.code)
      );

      this.plan.plans_options.forEach((plans_option) => {
        if (plans_option.is_available && plans_option.is_showing) {
          this.selectedOptionCodes.push(plans_option.option.code);
        }
      });
    },
  },
  data() {
    return {
      plan: null,
      plansMaster: null,
      maxNumOfItemsForSilver: 10000,
      maxNumOfItemsForGold: 20000,
      selectedOptionCodes: [],
    };
  },
  methods: {},
  async created() {
    this.fetch_user();

    Axios.get("/api/v1/plans/").then((res) => {
      this.plansMaster = res.data.plans;
    });
  },
};
</script>
