<template>
  <Main
    :descriptionKey="descriptionKey"
    :fullAutoColumnName="fullAutoColumnName"
    :countImages="countImages"
  />
</template>

<script>
import Main from "./Main.vue";

export default {
  name: "ForSmartPhone",
  components: { Main },
  data() {
    return {
      descriptionKey: "ForSmartPhone",
      fullAutoColumnName: "is_full_auto_on",
      countImages: true,
    };
  },
};
</script>
