<template>
  <v-card min-width="200px">
    <v-card-text class="pa-5">
      <span class="v-align-middle subtitle-1 font-weight-bold">画像数</span>
      <span>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="v-align-middle"
              v-bind="attrs"
              v-on="on"
              color="#B8B8B8"
              >mdi-help-circle</v-icon
            >
          </template>
          <div>スマホ商品説明文の画像数利用状況です。</div>
          <!-- <div>EC-UPツールをつけて、画像数を有効活用しましょう！</div> -->
        </v-tooltip>
      </span>
      <apexchart
        type="donut"
        height="230px"
        :options="chartOptions"
        :series="series"
        ref="images_circle_appexchart"
      ></apexchart>
    </v-card-text>
    <v-card-text style="background-color: #f7f7f7">
      <div class="text-center font-weight-bold">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{
              images_in_description_sp_used.toLocaleString()
            }}</span>
          </template>
          <span>スマホ商品説明文で、消費中の画像数</span>
        </v-tooltip>
        <span>/</span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{
              total_images.toLocaleString()
            }}</span>
          </template>
          <span>スマホ商品説明文で、利用可能な画像数の合計</span>
        </v-tooltip>
        <span>枚</span>
        <span class="ml-2">{{shop_consuming_percentage}}%</span>
      </div>
      <div class="text-center">
          <span>全ショップ平均</span>
        <span class="ml-2">62%</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "NumberOfImagesCard",
  props: [
    "images_in_description_sp_used_by_ecup",
    "images_in_description_sp_used_by_shop",
    "images_in_description_sp_used",
    "images_in_description_sp_remaining",
    "shop_total_image_consumption_average"
  ],
  computed: {
    total_images: function () {
      return (
        this.images_in_description_sp_used +
        this.images_in_description_sp_remaining
      );
    },
    shop_consuming_percentage: function(){
      return Math.round(
        this.images_in_description_sp_used* 100/this.total_images
        )
    }
  },
  watch: {
    images_in_description_sp_used_by_shop: function (newVal, oldVal) {
      let vue_instance = this;
      this.series = [
        Math.round(
          (this.images_in_description_sp_used_by_ecup * 1000) /
            this.total_images
        ) / 10,
        Math.round(
          (this.images_in_description_sp_used_by_shop * 1000) /
            this.total_images
        ) / 10,
        Math.round(
          (this.images_in_description_sp_remaining * 1000) / this.total_images
        ) / 10,
      ];
      this.chartOptions.plotOptions.pie.donut.labels.total.formatter = function (
        val
      ) {
        return (
          Math.round(
            (vue_instance.images_in_description_sp_used_by_ecup * 1000) /
              vue_instance.total_images
          ) /
            10 +
          "%"
        );
      };
      this.$refs.images_circle_appexchart.updateSeries(this.series);
      this.$refs.images_circle_appexchart.updateOptions(this.chartOptions);
    },
  },
  data() {
    return {
      series: [],
      chartOptions: {
        labels: ["EC-UP", "商品情報", "残り画像"],
        dataLabels: {
          enabled: false,
        },
        grid: {
          padding: {
            left: 0,
            right: 0,
          },
          borderColor: "rgba(0,0,0,0.1)",
        },
        plotOptions: {
          pie: {
            donut: {
              size: "82px",
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "18px",
                  color: "#99abb4",
                  offsetY: -10,
                },
                value: {
                  show: true,
                  color: "#99abb4",
                  formatter: function (val) {
                    return val + "%";
                  },
                },
                total: {
                  show: true,
                  label: "EC-UP",
                  color: "#99abb4",
                  formatter: function (val) {
                    return "%";
                  },
                },
              },
            },
          },
        },
        stroke: {
          width: 1,
          colors: "rgba(0,0,0,0.1)",
        },
        legend: {
          show: true,
          position: "bottom",
        },
        colors: ["#92b125", "#2286C1", "#EEEEEE"],
        tooltip: {
          fillSeriesColor: false,
          enabled: false,
        },
      },
    };
  },
};
</script>
