<template>
  <ecup-modal
    modal_width="800px"
    v-if="user"
    @close_this_modal="$emit('close')"
  >
    <v-card flat>
      <v-card-title class="justify-center">
        無料トライアル期間があと{{
          date_from_today(user.trial_ends_at)
        }}日で終了します
      </v-card-title>

      <v-card-text class="text-center">
        <p>
          EC-UPの無料トライアルをご利用いただき、誠にありがとうございます。<br />
          EC-UPは、誰でも簡単に楽天ショップのスマホ対策ができるサービスです。<br />
          御社ショップのスマホ回遊対策・売上アップに貢献できていましたら幸いです。
        </p>

        <p>
          <span class="font-weight-bold">
            トライアル中の設定を全て引き継ぐ場合は、下部「プランを選択する」より、<br />
            {{ selectablePlanName }}を選択してください。<br />
          </span>
          <span class="text-caption" v-if="!isPlatinum">
            ※トライアル期間では、ゴールドプランと同等の機能をご提供させていただいております。
          </span>
        </p>

        <p v-if="isPlatinum">
          <span class="error--text font-weight-bold">
            ※プランを選択されなかった場合、トライアル期間終了後に自動的に退会となります。
          </span>
        </p>
        <p v-else>
          また無料で利用するためのフリープランもございます。<br />
          <span class="error--text">
            フリープランを選択された場合は、これまで各商品に設定した全ての内容が消去され、<br />
            更新も停止されますのでご注意ください。<br />
          </span>

          <span class="error--text font-weight-bold">
            ※トライアル期間終了時に、自動的にフリープランへの移行をさせていただきます。
          </span>
        </p>

        <v-row justify="center">
          <v-col cols="auto">
            <v-img
              style="cursor: zoom-in"
              src="/images/plans/price_list.png"
              @click="priceList = true"
            ></v-img>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="6">
            <v-btn
              large
              block
              color="primary"
              @click="showPlanIndexModal = true"
            >
              プランを選択する
            </v-btn>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="auto">
            <p class="caption">
              ※EC-UPでは、オンライン決済サービス「stripe」を利用しています。<br />
              フリープランの場合、クレジットカードの登録は不要です。
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pr-1" v-if="!isPlatinum">
            <div class="notes">
              <v-row>
                <v-col>
                  <p class="font-weight-bold">フリープランへ変更する方へ</p>
                  <ul>
                    <li>
                      <span class="caption">
                        トライアル終了時点で表示していた内容は全て削除されますのでご注意ください。
                      </span>
                    </li>

                    <li>
                      <span class="caption">
                        ライセンスキーなどの初期設定は消去されません。
                      </span>
                    </li>
                  </ul>
                </v-col>
              </v-row>
              <v-row justify="end">
                <v-col cols="auto">
                  <a :href="config('HREF_FAQ')" target="_blank">
                    よくある質問Q&A
                  </a>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col :cols="isPlatinum ? 6 : 4" class="px-1">
            <div class="notes">
              <v-row>
                <v-col>
                  <p class="font-weight-bold">有料プランへ変更する方へ</p>
                  <ul>
                    <li>
                      <span class="caption">
                        トライアルの設定は全て維持されます。
                      </span>
                    </li>
                    <li>
                      <span class="caption">
                        プラン変更時の日付にて初回の決済が行われ、以降は毎月の同日付けで自動更新（継続課金）されます。
                      </span>
                    </li>
                  </ul>
                </v-col>
              </v-row>
              <v-row justify="end">
                <v-col cols="auto">
                  <a :href="config('HREF_FAQ')" target="_blank">
                    よくある質問Q&A
                  </a>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col :cols="isPlatinum ? 6 : 4" class="pl-1">
            <div class="notes">
              <v-row>
                <v-col>
                  <p class="font-weight-bold">退会をご希望の方へ</p>
                  <ul>
                    <li>
                      <span class="caption">
                        トライアル終了時点で表示していた内容は全て削除されますのでご注意ください。
                      </span>
                    </li>
                    <li>
                      <span class="caption">
                        退会後は180日間再登録することができません。
                      </span>
                    </li>
                    <li>
                      <span class="caption">
                        再度登録した場合でも、現在取得済みのクーポンを再度取得する事はできません。
                      </span>
                    </li>
                  </ul>
                </v-col>
              </v-row>
              <v-row justify="end">
                <v-col cols="auto">
                  <a :href="config('HREF_FAQ')" target="_blank">
                    よくある質問Q&A
                  </a>
                </v-col>
                <v-col cols="auto">
                  <router-link
                    :to="{ name: 'Withdraw' }"
                    @click.native="$emit('close')"
                  >
                    退会する
                  </router-link>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-dialog v-model="priceList" width="auto">
        <v-card>
          <v-card-actions>
            <v-divider></v-divider>
            <v-btn icon fab text x-large @click="priceList = false">
              <v-icon> mdi-close-box </v-icon>
            </v-btn>
          </v-card-actions>
          <v-card-text>
            <v-img src="/images/plans/price_list.png"></v-img>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>

    <plan-index-modal
      v-if="showPlanIndexModal"
      @close_this_modal="showPlanIndexModal = false"
    />
  </ecup-modal>
</template>

<script>
import Modal from "../components/Modal.vue";
import timeUtilsMixin from "../../packs/mixins/time_utils";
import EcupModal from "../components/EcupModal.vue";
import PlanIndexModal from "../plan/PlanIndexModal.vue";
import config from "../../const";

export default {
  components: { Modal, EcupModal, PlanIndexModal },

  mixins: [timeUtilsMixin],

  created() {},

  computed: {
    user() {
      return this.$store.state.user;
    },

    isPlatinum() {
      return this.max_num_of_items_of_free < this.user.items_count;
    },

    selectablePlanName() {
      return this.isPlatinum
        ? "プラチナプラン"
        : "シルバープランもしくはゴールドプランのいずれか";
    },
  },

  methods: {
    config(value) {
      return config[value];
    },
  },

  watch: {},

  data() {
    return {
      showPlanIndexModal: false,
      max_num_of_items_of_free: 20000,
      priceList: false,
    };
  },
};
</script>

<style scoped>
.v-card__title {
  font-size: 1.5em;
  font-weight: 700;
}

.v-card__text {
  margin-top: 12px;
}

.notes {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  background-color: #eceff1;
  padding: 12px;
  text-align: start;
  height: 100%;
}

.price_list {
  height: 400px;
  border: 1px solid lightgray;
  overflow-y: auto;
}
</style>
