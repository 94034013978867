<template>
  <v-container fluid class="pa-6" v-if="user">
    <v-row>
      <v-col class="display-1">
        <v-icon left large color="success"> mdi-palette-outline </v-icon>
        表示・デザイン
      </v-col>
    </v-row>
    <v-row>
      <v-col class="body-2 pt-0">
        表示する商品やデザインの設定ができます。変更後の設定は次回更新時から反映されます。
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <HideProduct
          :config="config"
          :loading="loading"
          @updateConfig="updateConfig()"
        />
      </v-col>
    </v-row>

    <floating-notification-alert
      :open="finished_alert"
      :ms="2000"
      @close="finished_alert = false"
    >
      <v-icon color="success">mdi-checkbox-marked</v-icon>
      <span color="success">設定を保存しました。</span>
    </floating-notification-alert>
  </v-container>
</template>

<script>
import axios from "axios";
import deepmerge from "deepmerge";
import userUtilsMixin from "../../packs/mixins/user_utils";
import FloatingNotificationAlert from "../components/alert/FloatingNotificationAlert.vue";
import HideProduct from "../config/components/display_settings/HideProduct.vue";

export default {
  data() {
    return {
      loading: false,
      finished_alert: false,
      config: {
        ignoring_item_names: [],
        ignoring_item_codes: [],
      },
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  components: { FloatingNotificationAlert, HideProduct },

  mixins: [userUtilsMixin],

  watch: {
    user: {
      handler(val) {
        const config = val?.config;
        // 非表示商品設定
        this.config.ignoring_item_names = config?.ignoring_item_names;
        this.config.ignoring_item_codes = config?.ignoring_item_codes;
      },
    },
  },

  async created() {
    this.fetch_user();
  },

  methods: {
    updateConfig() {
      this.$nextTick(() => {
        // このページにないconfigが消えてしまうためマージする
        const config = deepmerge(this.user.config, this.config);
        // マージにより配列が重複するので
        config.ignoring_item_names = this.config.ignoring_item_names;
        config.ignoring_item_codes = this.config.ignoring_item_codes;

        try {
          this.loading = true;
          axios.post("/api/v1/shops/me", { shop: { config: config } });
        } catch (e) {
          console.error(e);
        } finally {
          this.loading = false;
          this.finished_alert = true;
        }
      });
    },
  },
};
</script>
