<template>
  <v-row>
    <v-col cols="12">
      <v-btn
        block
        text
        color="success"
        @click="$router.push({ name: 'ChangeToFreePlan' })"
      >
        フリープランに変更する
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ChangeToFreeButton",
};
</script>
