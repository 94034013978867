<template>
  <v-row>
    <v-col cols="11" offset="1">
      <v-card-title style="padding-top: 0"> デザイン </v-card-title>
      <v-card-text>
        おまかせ機能のお気に入りで使用するデザインを設定します。
        <v-radio-group v-model="layout_id">
          <v-row>
            <v-col
              cols="3"
              v-for="layout in layouts"
              :key="layout.id"
              class="v-card"
            >
              <v-overlay
                absolute
                opacity="0.25"
                v-if="!isAvailable(layout)"
              ></v-overlay>
              <v-radio
                dense
                :label="layout.name"
                :value="layout.id"
                :disabled="!isAvailable(layout)"
                @click="select()"
              ></v-radio>
              <v-img :src="layout.preview_image_url"></v-img>
            </v-col>
          </v-row>
        </v-radio-group>
      </v-card-text>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  props: ["user", "full_auto_layout_id"],

  data() {
    return {
      layouts: [],
      layout_id: null,
    };
  },

  watch: {
    full_auto_layout_id: {
      immediate: true,
      handler(val) {
        if (val === undefined) {
          this.layout_id = 204;
        } else {
          this.layout_id = this.full_auto_layout_id;
        }
      },
    },
  },

  async created() {
    await axios
      .get("/api/v1/tools/find_by", {
        params: { name_en: "favorite", target: "descriptionForSmartPhone" },
      })
      .then(async (res) => {
        this.layouts = res.data.tool.layouts;
      });
  },

  methods: {
    isAvailablePremium() {
      return this.user["is_available_premium?"];
    },

    isAvailable(layout) {
      return this.isAvailablePremium();
    },

    select() {
      this.$emit("select", this.layout_id);
    },
  },
};
</script>
